import React from "react";

import {
  FormControlLabel,
  Checkbox,
  Collapse,
  Grid,
  Slider,
  Input,
} from "@material-ui/core";
import PropTypes from "prop-types";

const NumericalFilter = ({
  className,
  disabled,
  isFilterOn,
  handleToggleFilter,
  filterValue,
  handleFilterValueSliderChange,
  handleFilterValueInputChange,
  filterMin,
  filterMax,
  classes,
  label,
}) => {
  return (
    <>
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            checked={isFilterOn}
            disabled={disabled}
            onChange={handleToggleFilter}
          />
        }
        classes={{ label: classes }}
        label={label}
      />
      <Collapse in={isFilterOn}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Slider
              value={filterValue}
              disabled={disabled}
              onChange={handleFilterValueSliderChange}
              min={filterMin}
              max={filterMax}
              track="inverted"
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              value={filterValue}
              disabled={disabled}
              margin="dense"
              onChange={handleFilterValueInputChange}
              // onBlur={handleBlur}
              inputProps={{
                step: 100,
                min: filterMin,
                max: filterMax,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

NumericalFilter.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFilterOn: PropTypes.bool.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleFilterValueSliderChange: PropTypes.func.isRequired,
  handleFilterValueInputChange: PropTypes.func.isRequired,
  filterMin: PropTypes.number.isRequired,
  filterMax: PropTypes.number.isRequired,
  //classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default NumericalFilter;
