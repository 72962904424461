// console log if dev
process.env.NODE_ENV === 'development' && console.log(process.env.NODE_ENV + ' node_env')
process.env.REACT_APP_NODE_ENV === 'development' && console.log(process.env.REACT_APP_NODE_ENV + ' react_app_node_env')

export const getMapKeys = (username, d, data) => {
  const isAdmin = d?.AdminUsernames?.includes(username)
  if (data) {
    const mapKeys = Object.keys(data)
    return isAdmin ?
      mapKeys
        .map(i => { return { item: i, id: data[i].mapID } }) :
      mapKeys
        .filter(i => i.includes(process.env.REACT_APP_NODE_ENV === "development" ? '_dev' : '_prod'))
        .filter(i => data[i].usernames.includes(username))
        .map(i => {
          return { item: i, id: data[i].mapID }
        })
  }
}
export const getCurrentID = (username, d, data) => {
  const isAdmin = d?.AdminUsernames?.includes(username)
  if (data) {
    const mapKeys = Object.keys(data)
    const key = isAdmin ? mapKeys.map(i => data[i].mapID) :
      mapKeys
        .filter(i => i.includes(process.env.REACT_APP_NODE_ENV === "development" ? '_dev' : '_prod'))
        .filter(i => data[i].usernames.includes(username))
        .map(i => data[i].mapID)
    return key[0]
  }
}
