import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from 'react-i18next';

import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MessageDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();

  // avoid loading iframe on rightPanel, iframe should be loaded on dialog btn click
  if (open === false) return (<></>);

  return (
    !!content && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-slide-title">{t("Meldingen")}</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              size="small"
              aria-label="simple table"
              style={{ width: "min(100%, fit-content)" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("Melding")}</TableCell>
                  <TableCell align="left">{t("Info")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(content).map((message) => (
                  <TableRow key={message}>
                    <TableCell component="th" scope="row">
                      {message}
                    </TableCell>
                    <TableCell align="left">{content[message]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};

export default MessageDialog;
