import React, { useState, useCallback, useEffect } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import _ from "lodash";

import OverlayWrapper from "../OverlayWrapper";
import PanelLayout from "./PanelLayout";
// import DangerousGeometries from "./DangerousGeometries";
import CustomTabs from "./CustomTabs";
import { UtrechtFiltersTab, UtrechtDataTab } from "./UtrechtFiltersTab";
import { useTranslation } from 'react-i18next';

export const LEFT_PANEL_WIDTH = 300;

const usePanelExpandStyles = makeStyles((theme) => ({
  container: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    border: `1px solid ${theme.palette.divider}`,
    borderTop: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "40px",
    paddingTop: "4px",
    width: `${LEFT_PANEL_WIDTH}px`,
    boxSizing: "border-box",
    backgroundColor: "white",
    position: "relative",
    zIndex: "410",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  expandIcon: {
    marginRight: "4px",
    marginLeft: "4px",
    color: "inherit",
  },
  expandMoreText: {
    color: "inherit",
    fontSize: "0.875rem",
  },
}));

const PanelExpand = ({ map, state, dispatch, expanded, setExpanded }) => {
  const classes = usePanelExpandStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("one");

  const handleTabValueChange = useCallback(
    (_, newValue) => {
      setTabValue(newValue);
      if (newValue === "one") {
        dispatch({
          type: "SET_GEOMETRY_ID_FILTER",
          payload: null,
        });
        dispatch({
          type: "SET_UTRECHT_FILTERS",
          payload: state.utrechtFiltersTabOne,
        });
        dispatch({
          type: "SET_UTRECHT_RISKS",
          payload: state.utrechtActiveRisksTabOne,
        });
        dispatch({
          type: "SET_UTRECHT_INDICATORS",
          payload: state.utrechtActiveIndicatorsTabOne,
        });
      }
      if (newValue === "two") {
        dispatch({
          type: "SET_UTRECHT_SHOW_ALL",
          payload: true,
        });
        dispatch({
          type: "SET_UTRECHT_FILTERS_TAB_ONE",
          payload: state.utrechtFilters,
        });
        dispatch({
          type: "SET_UTRECHT_RISKS_TAB_ONE",
          payload: state.utrechtActiveRisks,
        });
        dispatch({
          type: "SET_UTRECHT_INDICATORS_TAB_ONE",
          payload: state.utrechtActiveIndicators,
        });
      }
    },
    [dispatch, state.utrechtFiltersTabOne, state.utrechtActiveRisksTabOne, state.utrechtActiveIndicatorsTabOne, state.utrechtActiveRisks, state.utrechtActiveIndicators, state.utrechtFilters]
  );

  useEffect(() => {
    if (_.isEmpty(state.style)) {
      setTabValue("one");
    }
  }, [state.style]);

  return (
    <OverlayWrapper style={{ left: "20px", top: "65px", zIndex: 400 }}>
      <div className={classes.container} onClick={() => setExpanded(true)}>
        <ExpandMoreIcon className={classes.expandIcon} />
        <Typography className={classes.expandMoreText}>
          {t("Risicoprofiel, filters")}
        </Typography>
      </div>
      <PanelLayout
        expanded={expanded}
        setExpanded={setExpanded}
        dispatch={dispatch}
      >
        <CustomTabs
          value={tabValue}
          handleChange={handleTabValueChange}
          isUtrecht={state.isUtrecht}
        />
        <TabPanel value={tabValue} index="one">
          <UtrechtFiltersTab
            expanded={expanded}
            setExpanded={setExpanded}
            dispatch={dispatch}
            style={state.style}
            state={state}
            map={map}
          />

        </TabPanel>
        {/* <TabPanel value={tabValue} index="two">
          <DangerousGeometries
            layer="df_wegvak_full"
            map={map}
            dispatch={dispatch}
            state={state}
            style={state.style}
          />
        </TabPanel> */}
        <TabPanel value={tabValue} index="two">
          <UtrechtDataTab
            expanded={expanded}
            setExpanded={setExpanded}
            dispatch={dispatch}
            style={state.style}
            state={state}
            map={map}
          />
        </TabPanel>
      </PanelLayout>
    </OverlayWrapper>
  );
};

PanelExpand.propTypes = {
  map: PropTypes.object,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};

export default PanelExpand;
