import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#05668D",
    },
    secondary: {
      light: "#02C39A",
      main: "#00A896",
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12.25,
  },
});

export default theme;
