import React, { useState, useEffect, useMemo } from "react";

import { makeStyles, Typography, Collapse } from "@material-ui/core"; //Divider
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { useCurrentMap, useAuth } from "../../../hooks";
import OverlayWrapper from "./OverlayWrapper";
import { LEFT_PANEL_WIDTH } from "./LeftPanel/LeftPanel";
import {
  riskColors,
  riskRange
} from "../useMapViewerLogic/useMapInitialization";

const useStyles = makeStyles((theme) => ({
  overlay: {
    bottom: "30px",
    left: (props) =>
      props.expanded ? `${32 + LEFT_PANEL_WIDTH + 29}px` : "26px",
    transition: theme.transitions.create(["right"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "10px",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  downIcon: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform"),
    "&$expanded": {
      transform: "rotate(180deg)",
    },
  },
  section: {
    padding: "0px 10px",
    marginTop: "20px",
    "&:first-child": {
      marginTop: "6px",
    },
    "&:last-child": {
      paddingBottom: "10px",
    },
  },
  risicoList: {
    marginTop: "6px",
    marginBottom: "10px",
    paddingLeft: "20px",
  },
  risicoItem: {},
  expanded: {},
}));

const Legend = ({ expanded, state }) => {
  const classes = useStyles({ expanded });
  const [showLegend, setShowLegend] = useState(true);
  const { t } = useTranslation()
  const { user } = useAuth();
  const { currentMap } = useCurrentMap();
  const [riskRangeAdditionalTRaster, setriskRangeAdditionalRaster] = useState([]);
  const [riskColorsAdditionalTRaster, setriskColorsAdditionalRaster] = useState([]);

  // additional layers (raster) range / color from Ellipsis
  let activeLayers = state.activeLayers.filter(function (x) { return x !== "risico_totaal"; })

  //styles additional layers to riskColors / Range Additional
  const st = useMemo(() => {
    if (currentMap?.geometryLayers) {
      // console.log(currentMap.geometryLayers);
      return currentMap.geometryLayers.map((_, i) => ({
        name: currentMap.geometryLayers[i].name,
        rules: currentMap.geometryLayers[i].styles.length >= 1 ? currentMap.geometryLayers[i].styles[0].parameters?.rules : null
      }));
    }
  }, [currentMap?.geometryLayers]);
  const stf = st.filter((el) => {
    return activeLayers.some((f) => {
      return f === el.name;
    });
  });
  let riskColorsAdditional = []
  let riskRangeAdditional = []
  for (const key in stf) {
    let p = stf[key].rules
    let res = []
    let resa = []
    for (const key in p) {
      res.push(p[key].color)
      resa.push(p[key].value)
    }
    riskColorsAdditional.push(res)
    riskRangeAdditional.push(resa)
  }

  //Raster styles API call
  useEffect(() => {
    if (state.activeLayersRaster.length > 0) {
      //get pathIdsRaster of selected additional raster layers
      let pathIdsRaster = []
      for (var i = 0; i < currentMap?.coupledLayers?.length; i++) {
        if (state.activeLayersRaster.includes(currentMap?.coupledLayers[i].layer)) {
          pathIdsRaster[i] = { 'layerName': currentMap?.coupledLayers[i].layer, 'pathId': currentMap?.coupledLayers[i].mapId }
        }
      }
      pathIdsRaster = pathIdsRaster.flat()
      // console.log(pathIdsRaster)
      setriskRangeAdditionalRaster([]) //reset state
      setriskColorsAdditionalRaster([]) //reset state
      state.activeLayersRaster.forEach(input => {
        let g = pathIdsRaster.filter(obj => {
          return obj.layerName === input; //state.activeLayersRaster[l]
        });
        g = g[0]
        fetch('https://api.ellipsis-drive.com/v2/path/' + g.pathId, {
          method: "GET",
          headers: { "Authorization": `Bearer ` + user.token }
        }).then(res => res.json()).then(json => {
          setriskRangeAdditionalRaster(oldArray => [...oldArray, { [json.raster?.layers[0].name]: json.raster?.layers[0].parameters.transitionPoints.map(a => a.value) }]);
          setriskColorsAdditionalRaster(oldArr => [...oldArr, { [json.raster?.layers[0].name]: json.raster?.layers[0].parameters.transitionPoints.map(a => a.color) }]);
        });
      })
    }
  }, [currentMap, user, state.activeLayersRaster]);
  // async api response can be unordered (for raster useeffect api call state.activeLayersRaster.forEach), should be in same order as state.activeLayersRaster (alphabetically)
  const flattenObject = (obj) => {
    const flattened = {}
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        Object.assign(flattened, flattenObject(value))
      } else {
        flattened[key] = value
      }
    })
    return flattened
  }
  let range_flattened = flattenObject(riskRangeAdditionalTRaster)
  let range_ordered = Object.keys(range_flattened).sort().reduce(
    (obj, key) => {
      obj[key] = range_flattened[key];
      return obj;
    },
    {}
  );
  let riskRangeAdditionalRaster = Object.keys(range_ordered).map((el) => {
    return Object.values(range_ordered[el])
  })
  range_flattened = flattenObject(riskColorsAdditionalTRaster)
  range_ordered = Object.keys(range_flattened).sort().reduce(
    (obj, key) => {
      obj[key] = range_flattened[key];
      return obj;
    },
    {}
  );
  let riskColorsAdditionalRaster = Object.keys(range_ordered).map((el) => {
    return Object.values(range_ordered[el])
  })

  // console.log(riskRangeAdditional)
  // console.log(riskColorsAdditional)
  // console.log(riskColorsAdditionalRaster)
  // console.log(riskRangeAdditionalRaster)
  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399, 'maxHeight': '50vh', 'overflowY': 'auto' }}>
      <div className={classes.container}>
        <div
          className={classes.titleContainer}
          onClick={() => setShowLegend((prev) => !prev)}
        >
          <Typography className={classes.title}>{t("Legenda")}</Typography>
          <KeyboardArrowDownIcon
            className={clsx(classes.downIcon, !showLegend && classes.expanded)}
          />
        </div>
        <Collapse in={showLegend} className='seleniumLegend'>
          {(state.utrechtShowAll === true) ? (
            <section className={classes.section}>
              {riskRange.map((range, i) => (
                <LegendColorLine
                  key={range}
                  color={riskColors[i]}
                  property=""
                  operator="="
                  value={
                    riskRange.length === 5 ?
                      [t('Legend Zeer laag'), t('Legend Laag'), t('Legend Gemiddeld'), t('Legend Hoog'), t('Legend Zeer hoog')][i] :
                      i === 0 ? `0 - ${range}` : `${riskRange[i - 1]} - ${range}`
                  }
                  title={i === 0 ? t("Risicoscore") : ''}
                />
              ))}
              <LegendColorLine
                color={"#bfbfbf"}
                property=""
                operator="="
                value={t("Geen score")}
                title=''
              />
            </section>
          ) : <></>}
          {(state.activeLayers.length !== 0) && (riskRangeAdditional.length === activeLayers.length) && (riskColorsAdditional.length === activeLayers.length) ? (
            <section className={classes.section}>
              {/* <Divider className={classes.divider} /> */}
              {riskRangeAdditional.map((subarray, L) => (
                subarray.map((range, i) => (
                  <LegendColorLineAdditional
                    key={range}
                    color={riskColorsAdditional[L][i]}
                    property=""
                    operator="="
                    value={
                      range.toLowerCase()
                    }
                    title={i === 0 ?
                      activeLayers.sort()[L].toLowerCase().startsWith('data_') ? state.activeLayers.sort()[L].slice(5) : activeLayers.sort()[L]
                      : ''}
                  />
                ))
              ))}
              <div style={{ 'paddingTop': '0.5rem', 'paddingBottom': '0rem' }}></div>
            </section>
          ) : <></>}
          {(state.activeLayersRaster.length !== 0) && (riskRangeAdditionalRaster.length === state.activeLayersRaster.length) && (riskColorsAdditionalRaster.length === state.activeLayersRaster.length) ? (
            <section className={classes.section}>
              {/* <Divider className={classes.divider} /> */}
              {riskRangeAdditionalRaster.map((subarray, L) => (
                subarray.map((range, i) => (
                  <LegendColorLineAdditional
                    key={range}
                    color={riskColorsAdditionalRaster[L][i]}
                    property=""
                    operator="="
                    value={
                      subarray.length === 5 ?
                        [t('Legend Zeer laag'), t('Legend Laag'), t('Legend Gemiddeld'), t('Legend Hoog'), t('Legend Zeer hoog')][i] :
                        range.toLowerCase()
                    }
                    title={i === 0 ?
                      state.activeLayersRaster.sort()[L].toLowerCase().startsWith('data_') ? state.activeLayersRaster.sort()[L].slice(5) : state.activeLayersRaster.sort()[L]
                      : ''}
                  />
                ))
              ))}
              <div style={{ 'paddingTop': '0.5rem', 'paddingBottom': '0rem' }}></div>
            </section>
          ) : <></>}
          <section className={classes.section}>
            {state.utrechtFilters.find(
              (filter) => filter.key === "intensiteit_float"
            )?.value > 0 && (
                <LegendTextLine
                  text={t("Verkeersintensiteit")}
                  operator=" >="
                  value={
                    state.utrechtFilters.find(
                      (filter) => filter.key === "intensiteit_float"
                    )?.value
                  }
                />
              )}
            {state.utrechtFilters.find(
              (filter) => filter.key === "intensiteit_fiets_float"
            )?.value > 0 && (
                <LegendTextLine
                  text={t("Fietsintensiteit")}
                  operator=" >="
                  value={
                    state.utrechtFilters.find(
                      (filter) => filter.key === "intensiteit_fiets_float"
                    )?.value
                  }
                />
              )}
            {state.utrechtFilters.find(
              (filter) => filter.key === "V_OpenOV_busint_etm"
            )?.value > 0 && (
                <LegendTextLine
                  text={t("Busintensiteit")}
                  operator=" >="
                  value={
                    state.utrechtFilters.find(
                      (filter) => filter.key === "V_OpenOV_busint_etm"
                    )?.value
                  }
                />
              )}
            {state.utrechtFilters.find(
              (filter) => filter.key === "aantal_ongevallen_alle_jaren_float"
            )?.value > 0 && (
                <LegendTextLine
                  text={t("Ongevallen alle jaren")}
                  operator=" >="
                  value={
                    state.utrechtFilters.find(
                      (filter) => filter.key === "aantal_ongevallen_alle_jaren_float"
                    )?.value
                  }
                />
              )}
            {state.utrechtFilters.find(
              (filter) => filter.key === "wegtype"
            ) && (
                <LegendTextLine
                  text={t("Wegtype")}
                  value={
                    state.utrechtFilters.find(
                      (filter) => filter.key === "wegtype"
                    )?.value
                  }
                />
              )}
            {state.utrechtFilters.find(
              (filter) => filter.key === "wegbeheerder"
            ) && (
                <LegendTextLine
                  text={t("Wegbeheerder")}
                  value={
                    state.utrechtFilters.find(
                      (filter) => filter.key === "wegbeheerder"
                    )?.value
                  }
                />
              )}
            {state.utrechtFilters.find(
              (filter) => filter.key === "fietsnet"
            ) && (
                <LegendTextLine
                  text={t("fietsnet")}
                  // value={
                  //   state.utrechtFilters.find(
                  //     (filter) => filter.key === "fietsnet"
                  //   )?.value
                  // }
                  value='ja'
                />
              )}
          </section>
        </Collapse>
      </div>
    </OverlayWrapper>
  );
};

Legend.propTypes = {
  expanded: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    isUtrecht: PropTypes.bool.isRequired,
    utrechtActiveRisks: PropTypes.arrayOf(PropTypes.string).isRequired,
    utrechtFilters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        operator: PropTypes.string,
      })
    ).isRequired,
    activeLayers: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

const useLegendColorLineStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontSize: "0.9rem",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  colorCircle: {
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    marginRight: "6px",
  },
}));

const LegendColorLine = ({ color, property, operator, value, title }) => {
  const classes = useLegendColorLineStyles();

  return (
    <>
      {title && (
        <div className={classes.container} style={{ 'paddingTop': '1rem', 'paddingBottom': '0.5rem' }}>
          {title}
        </div>
      )}
      <div className={classes.container}>
        {color && (
          <span
            className={classes.colorCircle}
            style={{ backgroundColor: color }}
          />
        )}
        {_.lowerCase(property)} {operator} {value}
      </div>
    </>
  );
};
LegendColorLine.propTypes = {
  color: PropTypes.string,
  property: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

const LegendColorLineAdditional = ({ color, property, operator, value, title }) => {
  const classes = useLegendColorLineStyles();

  return (
    <>
      {title && (
        <div className={classes.container} style={{ 'paddingTop': '1rem', 'paddingBottom': '0.5rem' }}>
          {title}
        </div>
      )}
      <div className={classes.container}>
        {color && (
          <span
            className={classes.colorCircle}
            style={{ backgroundColor: color }}
          />
        )}
        {_.lowerCase(property)} {operator} {value}
      </div>
    </>
  );
};
LegendColorLineAdditional.propTypes = {
  color: PropTypes.string,
  property: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

const useLegendTextLineStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontSize: "0.9rem",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  text: {
    marginRight: "10px",
  },
  value: {
    fontWeight: "600",
  },
}));

const LegendTextLine = ({ text, value, operator = ":" }) => {
  const classes = useLegendTextLineStyles();

  return (
    <div className={classes.container}>
      <span className={classes.text}>
        {text}
        {/* {" "} */}
        {operator}
      </span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

LegendTextLine.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  operator: PropTypes.string,
};

export default Legend;
