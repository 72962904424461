import React, { useState } from "react";
// import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import SearchBar from "./SearchBar";
import LeftPanel from "./LeftPanel";
import Account from "./Account";
import Help from "./Help";
import UtrechtLegend from "./UtrechtLegend";
import UtrechtMetadataDrawer from "./UtrechtMetadataDrawer";
import ResetButton from "./ResetButton";
import GeometryNotice from "./GeometryNotice";
import Top10Notice from "./Top10Notice";
import SampleNotice from "./SampleNotice";
import Logos from "./Logos";
import Dashboard from "./Dashboard";
import DocumentationLink from "./DocumentationLink";
import LanguageSelector from "./LanguageSelector";

import { useCurrentMap } from "../../../hooks";

const MapOverlay = ({ map, state, dispatch }) => {
  const [leftPanelExpanded, setLeftPanelExpanded] = useState(false);
  const { currentMap } = useCurrentMap();

  //refresh once to avoid currentmap errors
  // useEffect(() => {
  //   const reloadCount = sessionStorage.getItem('reloadCount');
  //   if (reloadCount < 2) {
  //     sessionStorage.setItem('reloadCount', String(reloadCount + 1));
  //     setTimeout(() => {
  //       window.location.reload(true);
  //     }, 500);
  //   } else {
  //     sessionStorage.removeItem('reloadCount');
  //   }
  // }, []);

  return (
    <>
      {!currentMap && sessionStorage.clear() && localStorage.clear() && window.location.reload(true) /* force refresh if no currentMap */}
      <SearchBar map={map} dispatch={dispatch} />
      <LeftPanel
        map={map}
        state={state}
        dispatch={dispatch}
        expanded={leftPanelExpanded}
        setExpanded={setLeftPanelExpanded}
      />
      <ResetButton map={map} dispatch={dispatch} />
      <SampleNotice
        SampleNoticeShow={state.utrechtSampleNotice}
        SampleNoticeZoomShow={state.utrechtSampleNoticeZoom}
        FetchingGeometriesDone={state.fetchingGeometriesDone}
        dispatch={dispatch}
      />
      <Top10Notice
        top10Show={state.utrechtTop10}
      />
      <GeometryNotice
        geometriesToShow={state.geometriesToShow}
        loadingGeometries={state.fetchingGeometries}
        utrechtShowAll={state.utrechtShowAll}
      />

      <UtrechtLegend expanded={leftPanelExpanded} state={state} />
      <Dashboard expanded={leftPanelExpanded} state={state} />
      <Account state={state} dispatch={dispatch} />
      <Help state={state} />
      <LanguageSelector />
      <DocumentationLink state={state} />
      <UtrechtMetadataDrawer state={state} dispatch={dispatch} />

      <Logos />
    </>
  );
};

MapOverlay.propTypes = {
  map: PropTypes.object,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default MapOverlay;
