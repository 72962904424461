import React from "react";

import { makeStyles, Button } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import PropTypes from "prop-types";

import OverlayWrapper from "./OverlayWrapper";
import { useCurrentMap } from "../../../hooks";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  resetButton: {
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
    width: "100px",
    boxSizing: "border-box",
  },
  overlay: {
    left: "350px",
    top: "20px",
    [theme.breakpoints.down("xs")]: {
      left: "20px",
      top: "120px",
    },
  },
}));

const ResetButton = ({ map, dispatch }) => {
  const classes = useStyles();
  const { currentMap } = useCurrentMap();
  const {t} = useTranslation()
  const handleReset = () => {
    dispatch({ type: "RESET" });
    map.fitBounds([
      [currentMap.extent.xMin * 0.995, currentMap.extent.yMin * 0.995],
      [currentMap.extent.xMax * 1.005, currentMap.extent.yMax * 1.005],
    ]);
  };

  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399 }}>
      <Button
        variant="contained"
        onClick={handleReset}
        className={classes.resetButton}
        startIcon={<CachedIcon />}
      >
        {t("Reset")}
      </Button>
    </OverlayWrapper>
  );
};

ResetButton.propTypes = {
  map: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default ResetButton;
