const colors = [
  "#b71c1c",
  "#f44336",
  "#880e4f",
  "#e91e63",
  "#4a148c",
  "#9c27b0",
  "#311b92",
  "#673ab7",
  "#1a237e",
  "#3f51b5",
  "#0d47a1",
  "#2196f3",
  "#01579b",
  "#03a9f4",
  "#006064",
  "#00bcd4",
  "#004d40",
  "#009688",
  "#194D33",
  "#4caf50",
  "#33691e",
  "#8bc34a",
  "#827717",
  "#cddc39",
  "#f57f17",
  "#ffeb3b",
  "#ff6f00",
  "#ffc107",
  "#e65100",
  "#ff9800",
  "#bf360c",
  "#ff5722",
  "#3e2723",
  "#795548",
];

let graphColors = [
  "#e6194B",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#42d4f4",
  "#f032e6",
  "#bfef45",
  "#fabed4",
  "#469990",
  "#dcbeff",
  "#9A6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#a9a9a9",
  "#ffffff",
  "#000000",
];

const ACTION_MENU_ACTION = {
  view: 1,
  details: 2,
  manage: 3,
};

const MAIN_MODES = {
  dashboard: 1,
  mapManagement: 2,
  orders: 3,
  balance: 4,
  developer: 5,
  viewer: 6,
  overview: 7,
  contact: 8,
  placeOrder: 9,
  faq: 10,
  registered: 11,
  tour: 12,
  search: 13,
};

let appUtility = {
  mainModes: MAIN_MODES,

  colors: colors,
  graphColors: graphColors,

  mainModeRoutes: {
    [MAIN_MODES.dashboard]: { route: "/" },
    [MAIN_MODES.mapManagement]: { route: "/settings" },
    [MAIN_MODES.orders]: { route: "/orders" },
    [MAIN_MODES.balance]: { route: "/billing" },
    [MAIN_MODES.developer]: { route: "/developer", path: "/developer" },
    [MAIN_MODES.viewer]: { route: "/view" },
    [MAIN_MODES.overview]: { route: "/overview" },
    [MAIN_MODES.contact]: { route: "/contact" },
    [MAIN_MODES.placeOrder]: { route: "/newProject" },
    [MAIN_MODES.faq]: { route: "/faq" },
    [MAIN_MODES.search]: { route: "/explore" },
    [MAIN_MODES.registered]: { route: "/notification" },
    [MAIN_MODES.tour]: { route: "/tour" },
  },

  subModeKeys: {
    balance: "balanceMode",
    orders: "ordersMode",
    overview: "overviewMode",
  },

  balanceModes: {
    ledger: 1,
    deposit: 2,
    withdrawal: 3,
  },

  orderStep: {
    selectAction: 1,
    selectArea: 2,
    editArea: 3,
    createOrder: 4,
    confirmOrder: 5,
    finished: 6,
  },

  searchMode: {
    byName: 1,
    byAtlas: 2,
  },

  searchProjectStatus: {
    any: 1,
    activeOnly: 2,
    finishedOnly: 3,
    pausedOnly: 4,
    warningOnly: 5,
  },

  actionMenuAction: ACTION_MENU_ACTION,

  adminUsername: "admin",

  accessLevels: {
    viewMap: 100,
    aggregatedData: 200,
    viewGeoMessages: 300,
    addGeoMessages: 400,
    addGeoMessageImage: 410,
    addPrivateGeoMessage: 420,
    addPolygons: 500,
    submitRasterData: 515,
    addPolygonsRestricted: 525,
    viewPrivateElements: 550,
    deleteGeomessages: 600,
    alterOrDeletePolygons: 700,
    forms: 750,
    managePolygonLayers: 800,
    userManagement: 900,
    owner: 1000,

    mapPublicLevelOne: 300, // viewGeoMessages
    mapPublicLevelTwo: 525, // addPolygons

    min: 0,
    max: 1000,
  },

  orderLocalStorageKey: "order",

  demoAreaIds: [
    "e082adb0-43bb-49f6-9990-af08e0ad19a8", // gran_chaco
    "09f5e90d-f011-437c-b789-3cedfbca80bb", // semarang
    "5d541467-51c6-44e5-b105-95cc336c1594", // Kangerlussuaq
  ],

  demoDataSourceType: {
    lowResSat: "LowResSat",
    highResSat: "HighResSat",
    droneImagery: "DroneImagery",
    insarRadar: "InSARRadar",
  },

  getDemoDataSourceType(datasource) {
    if (datasource === "sentinel2RGBIR" || datasource === "sentinel2") {
      return this.demoDataSourceType.lowResSat;
    }

    if (
      datasource === "pleiades" ||
      datasource === "pleiadesTasking" ||
      datasource === "skySat" ||
      datasource === "skySatExample"
    ) {
      return this.demoDataSourceType.highResSat;
    }

    if (datasource === "droneRGB") {
      return this.demoDataSourceType.droneImagery;
    }

    if (datasource === "insarAndDisplacement") {
      return this.demoDataSourceType.insarRadar;
    }
  },

  getDemoMaps(maps, type) {
    let retmaps = [];
    maps.forEach((map) => {
      if (map.atlases.includes("DemoMaps")) {
        if (this.getDemoDataSourceType(map.dataSource.name) === type) {
          retmaps.push(map);
        }
      }
    });
    return retmaps;
  },

  propSort: (a, b, prop = "name") => {
    let aValue = a[prop];
    let bValue = b[prop];

    if (typeof aValue === "string") {
      aValue = aValue.toLowerCase();
      bValue = bValue.toLowerCase();
    }

    if (aValue > bValue) {
      return 1;
    } else if (aValue < bValue) {
      return -1;
    } else {
      return 0;
    }
  },

  emptyProjectMap: (project) => {
    return {
      name: project.name,
      isEmptyProjectMap: true,
    };
  },

  getAvatarColor: (name, length = 2) => {
    if (!name) {
      return null;
    } else {
      let max = 26 * 2;
      let offset = colors.length / max;
      let sum = 0;

      for (let i = 0; i < name.length && i < length; i++) {
        sum += name.charCodeAt(i) - 97;
      }
      let index = Math.round((sum > max ? max : sum) / offset);

      return colors[index];
    }
  },
};

export default appUtility;
