import React from "react";

import { makeStyles } from "@material-ui/core";
import { useCurrentMap } from "../../../../hooks";
import OverlayWrapper from "../OverlayWrapper";
import DokDataLogo from "./logo-dok.png";
import ArcadisLogo from "./arcadis-logo-white.svg";

const useStyles = makeStyles((theme) => ({
  overlay: {
    bottom: "32px",
    right: "50px",
    transition: theme.transitions.create(["right"], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("xs")]: {
      bottom: "10px",
      right: "34px",
    },
  },
  container: {
    display: "flex",
  },
  dokDataLogo: {
    height: "40px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
    },
  },
  arcadisLogo: {
    height: "40px",
    width: "40px",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "30px",
    },
  },
}));

const Account = () => {
  const classes = useStyles();
  // const [currentMap] = useLocalStorage("currentMap");
  const { currentMap } = useCurrentMap();

  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 199 }}>
      <div className={classes.container}>

        <a
          href="https://www.arcadis.com/nl-nl"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={ArcadisLogo}
            alt="arcadis logo"
            className={classes.arcadisLogo}
            style={currentMap?.properties.General[0].Logo_include_Arcadis === "False" ? { 'display': 'none' } : { 'display': 'block' }}
          />
        </a>

        <a href="https://www.dokdata.nl" target="_blank" rel="noreferrer">
          <img
            src={DokDataLogo}
            alt="dok data logo"
            className={classes.dokDataLogo}
          />
        </a>
      </div>
    </OverlayWrapper>
  );
};


export default Account;
