import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  IconButton,
  makeStyles,
  InputBase,
  Button,
  Paper,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import _ from "lodash";

import {
  useInputState,
  useDebounce,
  useWindowDimensions,
  useCurrentMap,
  useAuth,
} from "../../../../hooks";
import OverlayWrapper from "../OverlayWrapper";
import AutoCompleteMenu from "./AutoCompleteMenu";
import customSearchEntries from "./regios";
import { getGeometriesByName } from "../../useMapViewerLogic/apiCalls";
import { useTranslation } from 'react-i18next';

import L from "leaflet";
import mapboxgl from "mapbox-gl";
import geoViewport from "@mapbox/geo-viewport";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    width: 300,
    position: "relative",
    zIndex: "600",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  resetButton: {
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
    width: "100px",
    boxSizing: "border-box",
  },
  buttonOverlay: {
    left: "350px",
    top: "68px",
    [theme.breakpoints.down("xs")]: {
      left: "140px",
      top: "120px",
    },
  },
}));

// API search function
function searchCharacters(search, centerCoords = null) {
  return axios
    // .get(
    //   `https://geodata.nationaalgeoregister.nl/locatieserver/v3/suggest?q=${search}${centerCoords ? `&lat=${centerCoords.lat}&lon=${centerCoords.lon}` : ""
    //     }&fl=*&fq=type:(provincie OR gemeente OR woonplaats OR weg OR buurt OR wijk)`.replaceAll(
    //       " ",
    //       "%20"
    //     )
    // )
    .get(
      `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=${search}${centerCoords ? `&lat=${centerCoords.lat}&lon=${centerCoords.lon}` : ""
        }&fl=*&fq=type:(provincie OR gemeente OR woonplaats OR weg OR buurt OR wijk)`.replaceAll(
          " ",
          "%20"
        )
    )
    .then((r) => r.data.response.docs)
    .catch((error) => {
      console.error(error);
      return [];
    });
}

const options = {
  includeScore: true,
  threshold: 0.2,
  // Search in `author` and in `tags` array
  keys: ["regio"],
};

const layers = ["df_wegvak_full", "df_kruising_full"];

const fuse = new Fuse(customSearchEntries.content, options);

const SearchBar = ({ map, dispatch }) => {
  const { t } = useTranslation()
  const { currentMap } = useCurrentMap();
  const { user } = useAuth();

  const centroid = useMemo(
    () => ({
      lat: (currentMap.extent.yMin + currentMap.extent.yMax) / 2,
      lon: (currentMap.extent.xMin + currentMap.extent.xMax) / 2,
    }),
    [currentMap.extent]
  );
  // search term
  const [search, handleSearchChange, setSearch] = useInputState("");
  // API search results
  const [results, setResults] = useState([]);
  // Searching status (whether there is pending API request)
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 100);

  const classes = useStyles({ showAutocomplete });

  const { height, width } = useWindowDimensions();

  const [searchHistory, setSearchHistory] = useState([]);

  const fetchGeometryByName = useCallback(
    async (name) => {
      try {
        const nameSearchOne = await getGeometriesByName({
          mapId: currentMap.id,
          token: user.token,
          layer: layers[0],
          name,
        });
        const nameSearchTwo = await getGeometriesByName({
          mapId: currentMap.id,
          token: user.token,
          layer: layers[1],
          name,
        });
        if (
          !_.isEmpty(nameSearchOne.result) &&
          nameSearchOne.result.features.length > 0
        ) {
          setResults((prev) => [
            {
              geometrie_ll:
                nameSearchOne.result.features[0].geometry.coordinates.join(" "),
              weergavenaam:
                nameSearchOne.result.features[0].properties.straatnaam,
            },
            ...prev,
          ]);
        } else if (
          !_.isEmpty(nameSearchTwo.result) &&
          nameSearchTwo.result.features.length > 0
        ) {
          setResults((prev) => [
            {
              geometrie_ll:
                nameSearchTwo.result.features[0].geometry.coordinates.join(" "),
              weergavenaam:
                nameSearchTwo.result.features[0].properties.straatnaam,
            },
            ...prev,
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentMap.id, user.token]
  );

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        let customSearch = [];
        if (!!currentMap.properties.CustomAutoComplete) {
          customSearch = fuse.search(debouncedSearchTerm).map((item) => ({
            geometrie_ll: item.item.geometry,
            weergavenaam: item.item.regio,
          }));
        }

        searchCharacters(debouncedSearchTerm, centroid).then((results) => {
          setResults([...customSearch, ...results]);
          fetchGeometryByName(debouncedSearchTerm);
        });
      } else {
        setResults([]);
      }
    },
    [
      centroid,
      currentMap.id,
      currentMap.properties.CustomAutoComplete,
      debouncedSearchTerm,
      fetchGeometryByName,
      user.token,
    ] // Only call effect if debounced search term changes
  );

  const goToGeometry = (geometry, name) => {
    var fit = L.polygon(getCoords(geometry)).getBounds();
    var southWest = new mapboxgl.LngLat(
      fit["_southWest"]["lng"],
      fit["_southWest"]["lat"]
    );
    var northEast = new mapboxgl.LngLat(
      fit["_northEast"]["lng"],
      fit["_northEast"]["lat"]
    );

    // for some reason this center seems more accurate than the one I get from
    // geoViewport, so I'm computing both
    var center = new mapboxgl.LngLatBounds(southWest, northEast).getCenter();
    var { zoom } = geoViewport.viewport(
      [
        fit["_southWest"]["lng"],
        fit["_southWest"]["lat"],
        fit["_northEast"]["lng"],
        fit["_northEast"]["lat"],
      ],
      [width, height]
    );
    console.log(zoom)
    map.flyTo({ center: center, zoom: Math.min(zoom, 17) });
    setSearch(name);
    setShowAutocomplete(false);
    setSearchHistory((prevHist) => [{ name, geometry }, ...prevHist]);
  };

  function getCoords(string) {
    var coords = [];
    string
      .match(/[\d.]+/g)
      .map(Number)
      .forEach(function (a, i) {
        if (i % 2) {
          coords[coords.length - 1].lat = a;
        } else {
          coords.push({ lng: a });
        }
      });
    return coords;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (results.length > 0) {
      try {
        goToGeometry(results[0].geometrie_ll, results[0].weergavenaam);
      } catch (err) {
        console.log("no location found, err: ", err);
      }
    } else {
      searchCharacters(search, centroid).then((results) => {
        fetchGeometryByName(search);
        try {
          goToGeometry(results[0].geometrie_ll, results[0].weergavenaam);
        } catch (err) {
          console.log("no location found, err: ", err);
        }
      });
    }
  };

  const handleReset = () => {
    setSearchHistory([]);
    setSearch("");
    dispatch({ type: "RESET" });
    map.fitBounds([
      [currentMap.extent.xMin, currentMap.extent.yMin],
      [currentMap.extent.xMax, currentMap.extent.yMax],
    ]);
  };

  return (
    <>
      <OverlayWrapper style={{ left: "20px", top: "20px" }}>
        <Paper
          component="form"
          elevation={3}
          className={classes.root}
          onSubmit={handleSubmit}
        >
          <InputBase
            className={classes.input}
            placeholder={t("Zoeken")}
            type="text"
            inputProps={{ "aria-label": "search google maps" }}
            value={search}
            onChange={(e) => {
              handleSearchChange(e);
              setShowAutocomplete(true);
            }}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            disabled={!search}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <AutoCompleteMenu
          items={results}
          goToGeometry={goToGeometry}
          showAutocomplete={showAutocomplete}
        />
      </OverlayWrapper>
      <OverlayWrapper className={classes.buttonOverlay} style={{ zIndex: 399 }}>
        <Button
          variant="contained"
          onClick={() => {
            if (searchHistory.length === 1) {
              handleReset();
            } else {
              const prev = searchHistory[1];
              setSearchHistory((prevHist) => prevHist.slice(2));
              goToGeometry(prev.geometry, prev.name);
            }
          }}
          disabled={searchHistory.length < 1}
          className={classes.resetButton}
          startIcon={<ArrowBackIcon />}
        >
          {t("Terug")}
        </Button>
      </OverlayWrapper>
    </>
  );
};

SearchBar.propTypes = {
  map: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default SearchBar;
