import { useEffect } from "react";

import _ from "lodash";

import ApiManager from "../../../ApiManager";

const UTRECHT_GEOMETRY_NUMBER = 2000; //note: change counter (hammer) in useLoadGeometries.js too (default was 2000 geom_number = 10 counter)

export const useFetchCurrentMap = (
  getCurrentMap,
  user,
  dispatch,
  mapName = "ongevallen"
) => {
  useEffect(() => {

    const fetchShape = async () => {
      try {
        const res = await ApiManager.post(
          "/account/shapes",
          {
            fuzzy: true,
            // atlasId of the atlas containing every shape
            atlasId: "cee4745d-5bd1-42a7-a223-3592a1101d4e",
          },
          { token: user.token }
        );

        // console.log(res);

        if (res.result?.length > 1) {
          dispatch({ type: "SET_MULTIPLE_PROJECTS", payload: res.result });
        }
        if (res.result?.length > 0) {
          getCurrentMap({
            userToken: user.token,
            shape: res.result[res.result.length - 1],
          });
        }
      } catch (err) {
        console.log(err);
        return err;
      }

      await getCurrentMap({ userToken: user.token });
    };
    fetchShape();
  }, [getCurrentMap, mapName, user.token, dispatch]);
};

export const getGeometries = async ({
  mapId,
  token,
  bounds,
  layer = "df_wegvak_full",
  layerId,
  returnType = "geometry",
  propertyFiltersWegvak = [],
  propertyFiltersSplits = [],
  utrechtFilters,
  propertyRank = null,
  style = null,
  geometryIdFilter = null,
  pageSize = null,
  pageStart = null,
  isUtrecht,
  properties
}) => {
  let propertyFilter = undefined;

  if (isUtrecht && utrechtFilters?.length > 0) {
    propertyFilter = utrechtFilters.sort((a, b) =>
      a.operator === "=" ? -1 : b.operator === "=" ? 1 : 1
    );
  } else {
    propertyFilter =
      layer === "df_wegvak_full"
        ? propertyFiltersWegvak
        : propertyFiltersSplits;
  }
  const geometries = await ApiManager.post(
    "/geometry/bounds",
    {
      mapId,
      layerId,
      layer,
      bounds,
      returnType,
      deleted: false,
      propertyFilter,
      propertyRank,
      styleId:
        style === null ? null : !_.isEmpty(style) && style.styleIds[layer],
      geometryIds: geometryIdFilter,
      pageSize: isUtrecht ? UTRECHT_GEOMETRY_NUMBER : undefined,
      pageStart,
      properties,

    },
    { token }
  );
  return geometries;
};

export const getGeometriesById = async ({
  mapId,
  token,
  layer,
  geometryIds,
  returnType = "geometry",
  propertyRank = null,
}) => {
  const geometries = await ApiManager.post(
    "/geometry/get",
    {
      mapId,
      geometryIds,
      layer,
      returnType,
      deleted: false,
      propertyRank,
    },
    { token }
  );
  return geometries;
};

export const getMessagesById = async ({
  mapId,
  layerId,
  token,
  geometryIds,
}) => {
  const geometries = await ApiManager.post(
    "/message/get",
    {
      mapId,
      layerId,
      geometryIds,
    },
    { token }
  );
  return geometries;
};

export const editMessageById = async ({
  mapId,
  layerId,
  token,
  geometryId,
  oldMessageId,
  newMessage,
}) => {
  await ApiManager.post(
    "/message/add",
    {
      mapId,
      layerId,
      geometryId,
      text: JSON.stringify(newMessage),
    },
    { token }
  );
  if (oldMessageId) {
    await ApiManager.post(
      "/message/delete",
      {
        mapId,
        layerId,
        messageId: oldMessageId,
      },
      { token }
    );
  }

  return true;
};

export const getGeometryMetadata = async (
  mapId,
  token,
  geometryIds,
  layerId,
  returnType = "metadata"
) => {
  const geometry = await ApiManager.post(
    "/geometry/ids",
    {
      mapId,
      layerId,
      geometryIds,
      returnType,
      allowDeleted: false,
    },
    { token }
  );
  return geometry;
};

const ranges = {
  risk_score: { min: 0, max: 5, start: 5 },
  aantal_ongevallen_alle_jaren: { min: 0, max: 68, start: 15 },
  aantal_subjectieve_meldingen: { min: 0, max: 60, start: 30 },
};

/**
 * @param {string} type - the property to search. Either 'risk_score', 'aantal_ongevallen_alle_jaren', or 'aantal_subjectieve_meldingen'
 * @param {integer} N - the number of geometries to retrieve
 * @returns {array} an array of geometry ids
 */
export const fetchTopNGeometries = async ({
  mapId,
  token,
  bounds,
  type,
  layer,
  N = 10,
  pageSize = 200,
  propertyFiltersWegvak = [],
  propertyFiltersSplits = [],
}) => {
  let geometryIds = [];
  let { min, max, start } = ranges[type];
  let res;

  for (let i = 0; i < 10; i++) {
    // console.log(
    //   `Iteration ${i} with start ${start}, min ${min} and max ${max}`
    // );
    res = await getGeometries({
      mapId,
      token,
      bounds,
      layer,
      returnType: "id",
      pageSize,
      propertyFiltersWegvak: [
        ...propertyFiltersWegvak,
        { key: type, value: start, operator: ">=" },
      ],
      propertyFiltersSplits: [
        ...propertyFiltersSplits,
        { key: type, value: start, operator: ">=" },
      ],
      propertyRank: { key: type, direction: "desc" },
    });
    // console.log("res is ", res);

    // if I have the topN in the result for sure
    if (res.result.length < pageSize && res.result.length >= N) {
      geometryIds = res.result;
      // console.log("found, breaking from the loop");
      break;
    }
    // if I don't have enough results, it means I need to lower the bounds of the search
    if (res.result.length < pageSize && res.result.length < N) {
      max = start;
      if (start !== min) {
        start = Math.round((start + min) / 2) - 1;
      } else {
        start = Math.round((start + min) / 2);
      }
      console.log("start: ", start);
      console.log("max: ", max);
    }
    // I have too much results and I'm not sure if I have the topN
    else {
      min = start;
      start = Math.round((max + start) / 2);
    }
  }

  // I found too much geometries but didn't manage to cut them down to `N`
  // I still return something
  if (geometryIds.length === 0 && res.result.length > 0) {
    geometryIds = res.result.slice(0, N);
  }

  if (geometryIds.length === 0) return [];

  const geometries = await getGeometriesById({
    mapId,
    token,
    layer,
    geometryIds: geometryIds.slice(0, N),
    returnType: "all",
    propertyRank: { key: type, direction: "desc" },
  });

  return {
    features: geometries.result.features,
    geometryIds: geometryIds.slice(0, N),
  };
};

export const getGeometriesByName = async ({
  mapId,
  token,
  layer,
  name,
  returnType = "all",
}) => {
  const geometries = await ApiManager.post(
    "/geometry/get",
    {
      mapId,
      layer,
      returnType,
      pageSize: 1,
      propertyFilters: [
        {
          key: "straatnaam",
          value: name,
          operator: "contains",
        },
      ],
      deleted: false,
    },
    { token }
  );
  return geometries;
};
