import { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import _ from "lodash";

import { addAdditionalLayer } from "./mapLayers"; //addCircleLayer, addLineLayer, 
import { useCurrentMap } from "../../../hooks";
import { computeRiskScore } from "../MapOverlay/utils";
import { useUrlLocationSearch, getCoordsFromUrl, getIdFromUrl } from "../utils";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require(`worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker`).default;

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWprbm9sIiwiYSI6ImNrMHR0MnNobTA3a3UzY3BkNWl5YzNzeDQifQ.hPHVPMjYF4LN7XEcWxIeRw";

export const riskColors = [
  "#3CC33C",
  "#DCE321",
  "#E0901F",
  "#D1332E",
  "#000000",
];
export const riskRange = [0.2, 0.4, 0.6, 0.8, 1];
const ERROR_COLOR = "#bfbfbf";

const riskToColor = (risk) => {
  if (isNaN(risk) || risk < 0 || risk > 1) return ERROR_COLOR;
  if (risk < riskRange[0]) return riskColors[0];
  if (risk < riskRange[1]) return riskColors[1];
  if (risk < riskRange[2]) return riskColors[2];
  if (risk < riskRange[3]) return riskColors[3];
  return riskColors[4];
};

const useMapInitialization = (state, dispatch) => {
  const mapContainerRef = useRef(null);
  const [mapboxMap, setMapboxMap] = useState(null);
  const [bounds, setBounds] = useState([[], []]);
  const url = useUrlLocationSearch();

  const { currentMap } = useCurrentMap();

  const additionalLayers = useMemo(
    () =>
      currentMap?.geometryLayers.flatMap((layer) =>
        layer.name !== "df_kruising_full" && layer.name !== "df_wegvak_full"
          ? [layer]
          : []
      ),
    [currentMap]
  );

  // used to always have the up to date active risks in the data event listener
  const prevActiveRisks = useRef(state.utrechtActiveRisks);
  const prevActiveIndicators = useRef(state.utrechtActiveIndicators);
  var prevActiveTop10 = useRef(state.utrechtTop10);

  // top10 reset
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     if (state.fetchingGeometriesDone === true && prevActiveTop10.current === true) {
  //       // first to false (reset new map data)
  //       dispatch({
  //         type: "SET_UTRECHT_TOP10",
  //         payload: false,
  //       })
  //       dispatch({
  //         type: "SET_UTRECHT_TOP10",
  //         payload: true,
  //       })
  //     }
  //   }, 500);
  //   return () => clearTimeout(timeoutId);
  // }, [state.fetchingGeometriesDone, dispatch, prevActiveTop10]);
  // on risico selection change update the colors
  useEffect(() => {
    if (
      currentMap &&
      (!_.isEqual(state.utrechtActiveRisks, prevActiveRisks.current)) | (!_.isEqual(state.utrechtActiveIndicators, prevActiveIndicators.current)) | (!_.isEqual(state.utrechtTop10, prevActiveTop10.current))
    ) {
      prevActiveRisks.current = state.utrechtActiveRisks;
      prevActiveIndicators.current = state.utrechtActiveIndicators;
      prevActiveTop10.current = state.utrechtTop10;

      state.activeLayers.forEach((layer) => {

        //console.log(mapboxMap.getSource(layer)?._data.features)
        // top 10
        // TODO bug top 10 blijft staan (Top10Notice.js) na select en dan weer deselect ergens --> testen en fixen
        if (state.utrechtTop10 === true) {
          var top10 = [];
          mapboxMap.getSource(layer)?._data.features.forEach((feature) => {
            const risk = computeRiskScore(
              feature.properties,
              currentMap.properties.Whitebox,
              prevActiveRisks.current,
              prevActiveIndicators.current
            );
            // TODO push only if feature geom in bbox current view
            //console.log(feature.geometry.coordinates)
            //console.log(mapboxMap.getBounds().toArray())
            top10.push({ 'id': feature.properties.id, 'scoreTotal': risk.scoreTotal })
            //top10[feature.properties.id] = risk.scoreTotal;
          });
          //remove duplicates and select sorted top 10
          top10 = top10.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id //&& t.name === value.name
            ))
          )
          top10 = top10.sort(function (a, b) { return b.scoreTotal - a.scoreTotal; }).slice(0, 10)
          //console.log(top10)
        }

        mapboxMap.getSource(layer)?._data.features.forEach((feature) => {
          const risk = computeRiskScore(
            feature.properties,
            currentMap.properties.Whitebox,
            prevActiveRisks.current,
            prevActiveIndicators.current
          );
          // console.log(risk)
          // console.log(feature);
          // console.log(risk.scoreTotal);
          // console.log(riskToColor(risk.scoreTotal));
          if (state.utrechtTop10 === true) {
            if (!top10.some(e => e.id === feature.properties.id)) {
              // not in top 10
              //risk["scoreTotal"] = -1
              mapboxMap.setFeatureState(
                { source: layer, id: feature.properties.id },
                { dataColor: "blue", dataTop10: 'nee' } //color 'blue' 4 chars gets excluded in mapLayers.js (weird case thingy boolean not working, based on code Maciej)
              );
            } else {
              // in top 10
              mapboxMap.setFeatureState(
                { source: layer, id: feature.properties.id },
                { dataColor: riskToColor(risk.scoreTotal), dataTop10: 'ja' }
              );
            }
          } else {
            mapboxMap.setFeatureState(
              { source: layer, id: feature.properties.id },
              { dataColor: riskToColor(risk.scoreTotal), dataTop10: 'nee' }
            );
          }
        });
      });
    }
  }, [currentMap, mapboxMap, state.activeLayers, state.utrechtActiveRisks, state.utrechtActiveIndicators, state.utrechtTop10, dispatch]);

  // highlight selected geometry
  const highlightedFeature = useRef(null);
  useEffect(() => {
    // set highlight state
    if (
      mapboxMap &&
      state.metadataPanel.open &&
      !!state.metadataPanel.metadata.id
    ) {
      const features = mapboxMap.queryRenderedFeatures();

      if (features && features?.length > 0) {
        const feature = features.find(
          (feature) => feature?.id === state.metadataPanel.metadata.id
        );
        if (
          !!highlightedFeature.current &&
          highlightedFeature.current?.id !== feature?.id
        ) {
          // if there is already an highlighted feature
          // unset highlight state
          mapboxMap.setFeatureState(
            {
              source: highlightedFeature.current?.source,
              id: highlightedFeature.current?.id,
            },
            { highlight: false }
          );
        }
        highlightedFeature.current = feature;
        mapboxMap.setFeatureState(
          { source: feature?.source, id: feature?.id },
          { highlight: true }
        );

      }
    } else if (
      mapboxMap &&
      !state.metadataPanel.open &&
      !!highlightedFeature.current
    ) {
      // unset highlight state
      mapboxMap.setFeatureState(
        {
          source: highlightedFeature.current?.source,
          id: highlightedFeature.current?.id,
        },
        { highlight: false }
      );
      highlightedFeature.current = null;
    }

  }, [state.metadataPanel, mapboxMap]);

  //styles additional layers radius / width
  const styles = useMemo(() => {
    if (currentMap?.geometryLayers) {
      return currentMap.geometryLayers.map((_, i) => ({
        name: currentMap.geometryLayers[i].name,
        radius: currentMap.geometryLayers[i].styles.length >= 1 ? currentMap.geometryLayers[i].styles[0].parameters?.radius?.parameters?.value : 6,
        width: currentMap.geometryLayers[i].styles.length >= 1 ? currentMap.geometryLayers[i].styles[0].parameters?.width : 2,
      }));
    }
  }, [currentMap?.geometryLayers]);

  // Sample notice if below max geoms loaded / zoom level is above
  // useEffect(() => {
  //   if (
  //     mapboxMap
  //   ) {
  //     const MAX_GEOMETRIES = 9000; // also in useLoadGeometries.js
  //     const MAX_GEOMETRIES_ADDITIONAL_LAYER = 3999; // also in useLoadGeometries.js
  //     const UTRECHT_GEOMETRY_NUMBER = 2000; // also in apiCalls.js
  //     state.activeLayers.forEach((l, i) => {
  //       mapboxMap.on("sourcedata", (e) => {
  //         // sample notice if below max_geoms(_additional)
  //         if (mapboxMap.getSource(l) && mapboxMap.isSourceLoaded(l)) {
  //           let d = mapboxMap.getSource(l)._data;
  //           if (l === 'risico_totaal') {
  //             if (d?.features?.length <= MAX_GEOMETRIES + 1) {
  //               console.log('sample ' + l + ' ' + String(d?.features?.length))
  //             } else {
  //               console.log('all loaded ' + l + ' ' + String(d?.features?.length))
  //             }
  //           } else {
  //             if (d?.features?.length <= MAX_GEOMETRIES_ADDITIONAL_LAYER + 1) {
  //               console.log('sample ' + l + ' ' + String(d?.features?.length))
  //             } else {
  //               console.log('all loaded ' + l + ' ' + String(d?.features?.length))
  //             }
  //           }
  //         }
  //       });
  //     })
  //   }
  // }, [mapboxMap, currentMap, state.activeLayers]);

  // Initialize map when component mounts
  useEffect(() => {
    const urlCentroid = url && getCoordsFromUrl(url);
    const center = urlCentroid ?? [5.291266, 52.132633]; //Location from url or hard coded (Rotterdam: 4.27687958, 51.93101774)

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/light-v10?optimize=true",
      center,
      zoom: urlCentroid ? 17 : 10,
    });
    if (currentMap?.extent && !urlCentroid) {
      map.fitBounds([
        // [currentMap.extent.xMin * 0.995, currentMap.extent.yMin * 0.995],
        // [currentMap.extent.xMax * 1.005, currentMap.extent.yMax * 1.005],
        [currentMap.extent.xMin, currentMap.extent.yMin],
        [currentMap.extent.xMax, currentMap.extent.yMax],
      ]);
    }

    setMapboxMap(map);

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.on("load", () => {
      // map.addSource("df_wegvak_full", {
      //   type: "geojson",
      //   data: {
      //     type: "FeatureCollection",
      //     features: [],
      //   },
      //   promoteId: "id",
      // });
      // map.addSource("df_kruising_full", {
      //   type: "geojson",
      //   data: {
      //     type: "FeatureCollection",
      //     features: [],
      //   },
      //   promoteId: "id",
      // });
      additionalLayers?.forEach((layer) =>
        map.addSource(layer.name, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
          promoteId: "id",
        })
      );

      var hoveredStateId = null;

      // addLineLayer(
      //   map,
      //   dispatch,
      //   "df_wegvak_full",
      //   hoveredStateId,
      //   state.popup,
      //   "intersectionsStyling"
      // );
      // addCircleLayer(
      //   map,
      //   dispatch,
      //   "df_kruising_full",
      //   hoveredStateId,
      //   state.popup,
      //   "roadStyling"
      // );

      additionalLayers?.forEach((layer) => {
        addAdditionalLayer(
          map,
          dispatch,
          layer.name,
          hoveredStateId,
          state.popup,
          state.isUtrecht && layer.name === "risico_totaal",
          'vector',
          styles.filter(function (x) { return x.name === layer.name })
        );
      });

      dispatch({ type: "MAP_LOADED" });

      setBounds(map.getBounds().toArray());
    });

    map.on("moveend", () => {
      setBounds(map.getBounds().toArray());
      // if (state.utrechtTop10 === true) {
      // dispatch({
      //   type: "SET_UTRECHT_TOP10",
      //   payload: false,
      // })
      // }
      // document.getElementById("checkboxTop10").click();
    });

    map.on("webglcontextlost", function () {
      dispatch({ type: "MAP_UNLOADED" });
    });

    map.on("sourcedata", (e) => {
      const sourceId = "risico_totaal";
      const urlId = getIdFromUrl(url);
      if (map.getSource(sourceId) && map.isSourceLoaded(sourceId)) {
        const data = map.getSource(sourceId)._data;
        if (data?.type === "FeatureCollection" && data?.features?.length > 0) {
          data.features.forEach((feature) => {
            try {
              const risk = computeRiskScore(
                feature.properties,
                currentMap.properties.Whitebox,
                prevActiveRisks.current,
                prevActiveIndicators.current
              );
              if (feature.properties.id === urlId) {
                map.setFeatureState(
                  { source: sourceId, id: feature.properties.id },
                  { highlight: true },
                );
              } else {
                map.setFeatureState(
                  { source: sourceId, id: feature.properties.id },
                  { dataColor: riskToColor(risk.scoreTotal), dataTop10: 'nee' },
                );
              }
            } catch (error) {
              console.log("error happened: ", error);
              console.log(feature);
            }
            // console.log(feature);
            // console.log(risk.scoreTotal);
            // console.log(riskToColor(risk.scoreTotal));
          });
        }
        // zoom notice
        if (map.getZoom() <= 10) {
          dispatch({ type: "SET_UTRECHT_SAMPLENOTICE_ZOOM", payload: 70 });
        } else if (map.getZoom() > 10 && map.getZoom() <= 12) {
          dispatch({ type: "SET_UTRECHT_SAMPLENOTICE_ZOOM", payload: 50 });
        } else {
          dispatch({ type: "SET_UTRECHT_SAMPLENOTICE_ZOOM", payload: -1 });
        }
      }
    });

    // Clean up on unmount
    return () => map.remove();
  }, [currentMap]); // eslint-disable-line react-hooks/exhaustive-deps

  return [mapContainerRef, mapboxMap, bounds];
};

export default useMapInitialization;
