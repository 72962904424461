import React, { createRef } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OverlayWrapper from "./OverlayWrapper";
import { drawerWidth } from "./GeometryMetadataDrawer";
import { useLocalStorage } from "../../../hooks";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  overlay: {
    backgroundColor: 'white',
    top: "32px",
    right: (props) => (props.shifted ? `${160 + drawerWidth}px` : "160px"),
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    height: "32px",
    width: "32px",
  },
  root: {
    margin: '2px 6px',
    '&::before': {
      border: 'none'
    }
  },

}));

const LanguageSelector = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useLocalStorage('nl')
  const getCurrentLng = () => language || i18n.language || 'nl';
  const listLanguage = createRef();
  const langSwitch = [
    { code: 'nl', title: 'NL' },
    { code: 'en', title: 'EN' },
  ];
  const handleChange = event => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399 }}>
      <Tooltip placement="bottom" title="Vertaling / translation">
        <IconButton
          color="primary"
          className={classes.iconButton}
        >
          <Select
            className={classes.root}
            id='select-language'
            ref={listLanguage}
            defaultValue={getCurrentLng}
            onChange={handleChange}
          >
            {langSwitch.map((lang, index) => {
              return (
                <MenuItem className={classes.item} key={index} value={lang.code}>
                  {lang.title}
                </MenuItem>
              );
            })}
          </Select>
        </IconButton>
      </Tooltip>
    </OverlayWrapper>
  );
};


export default LanguageSelector;
