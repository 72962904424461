import { useState, useEffect } from "react";

import { useInputState, useAuth } from "../../hooks";
import { useHistory } from "react-router-dom";

const useLoginLogic = () => {
  //refresh once to avoid (ellipsis) auth errors
  useEffect(() => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      localStorage.clear();
      window.location.reload(false);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);

  const auth = useAuth();
  const history = useHistory();

  const [username, handleUsernameChange] = useInputState("");
  const [password, handlePasswordChange] = useInputState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    // TODO: error handling
    setLoading(true);
    e.preventDefault();
    const res = await auth.signin(username, password, () =>
      history.replace("/map")
    );

    if (res && res.status !== 200) {
      setError(res.message);
    }
    setLoading(false);
  };

  return [
    username,
    handleUsernameChange,
    password,
    handlePasswordChange,
    handleSubmit,
    loading,
    error,
  ];
};

export default useLoginLogic;
