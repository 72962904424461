import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

export default function DisclaimerDialog({ open, handleClose, disclaimerText }) {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t("Disclaimer")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span dangerouslySetInnerHTML={{ __html: disclaimerText }}></span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          autoFocus
          style={{ margin: "auto", minWidth: "150px", marginBottom: "10px" }}
        >
          {t("Agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DisclaimerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
