import { initialReducerState } from "./index";

function filterArray([...array], value) {
  return array.includes(value)
    ? array.filter((item) => item !== value)
    : array.concat(value);
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "MAP_LOADED":
      return { ...state, mapLoaded: true };
    case "MAP_UNLOADED":
      return {
        ...state,
        mapLoaded: false,
        metadataPanel: initialReducerState.metadataPanel,
      };
    case "SET_MULTIPLE_PROJECTS":
      return {
        ...state,
        multipleProjects: payload,
      };
    case "TOGGLE_LAYER_FROM_ACTIVE":
      return {
        ...state,
        activeLayers: filterArray(state.activeLayers, payload),
      };
    case "SET_ACTIVE_LAYERS":
      return {
        ...state,
        activeLayers: payload,
      };
    case "SET_ACTIVE_LAYERS_RASTER":
      return {
        ...state,
        activeLayersRaster: payload,
      };
    case "SET_ACTIVE_LAYERS_EXTERNAL":
      return {
        ...state,
        activeLayersExternal: payload,
      };
    case "SET_MAP_DATA":
      return {
        ...state,
        isAdmin: payload.isAdmin,
        selectedMapKey: payload.selectedMapKey,
        mapKeys: payload.mapKeys,
      };
    case "CLEAR_MAP_DATA":
      return {
        ...state,
        isAdmin: initialReducerState.isAdmin,
        selectedMapKey: initialReducerState.selectedMapKey,
        mapKeys: initialReducerState.mapKeys,
      };
    case "SET_ADMIN":
      return {
        ...state,
        isAdmin: payload
      };
    case "SET_SELECTED_MAP_KEY":
      return {
        ...state,
        selectedMapKey: payload
      };
    case "SET_MAP_KEYS":
      return {
        ...state,
        mapKeys: payload
      };
    case "GEOMETRIES_FETCH":
      return { ...state, fetchingGeometries: true, fetchingGeometriesDone: false };
    case "GEOMETRIES_FETCH_SUCCESS":
      return {
        ...state,
        errorFetchingGeometries: null,
        fetchingGeometries: false,
        fetchingGeometriesDone: true,
      };
    case "GEOMETRIES_FETCH_FAILED":
      return {
        ...state,
        fetchingGeometries: false,
        fetchingGeometriesDone: false,
        errorFetchingGeometries: payload,
      };
    case "OPEN_METADATA_PANEL":
      return {
        ...state,
        metadataPanel: {
          open: true,
          properties: payload,
          metadata:
            payload.id === state.metadataPanel.properties.id
              ? state.metadataPanel.metadata
              : {},
        },
      };
    case "SET_METADATA_PANEL":
      return {
        ...state,
        metadataPanel: {
          ...state.metadataPanel,
          metadata: payload,
        },
      };
    case "CLOSE_METADATA_PANEL":
      return {
        ...state,
        metadataPanel: initialReducerState.metadataPanel,
      };
    case "OPEN_POPUP":
      return {
        ...state,
        popupData: payload,
      };
    case "CLOSE_POPUP":
      return {
        ...state,
        popupData: {},
      };

    case "CHANGE_FILTER_ALL":
      return {
        ...state,
        geometryIdFilter: null,
        propertyFiltersWegvak: [
          ...state.propertyFiltersWegvak.filter(
            (filter) => filter.key !== payload.key
          ),
          payload,
        ],
        propertyFiltersSplits: [
          ...state.propertyFiltersSplits.filter(
            (filter) => filter.key !== payload.key
          ),
          payload,
        ],
      };
    case "CHANGE_FILTER_WEGEN":
      return {
        ...state,
        geometryIdFilter: null,
        propertyFiltersWegvak: [
          ...state.propertyFiltersWegvak.filter(
            (filter) => filter.key !== payload.key
          ),
          payload,
        ],
      };
    case "CHANGE_FILTER_SPLITS":
      return {
        ...state,
        geometryIdFilter: null,
        propertyFiltersSplits: [
          ...state.propertyFiltersSplits.filter(
            (filter) => filter.key !== payload.key
          ),
          payload,
        ],
      };
    case "DELETE_FILTER":
      return {
        ...state,
        propertyFiltersWegvak: state.propertyFiltersWegvak.filter(
          (filter) => filter.key !== payload
        ),
        propertyFiltersSplits: state.propertyFiltersSplits.filter(
          (filter) => filter.key !== payload
        ),
      };
    case "SET_GEOMETRY_ID_FILTER":
      return {
        ...state,
        geometryIdFilter: payload,
      };
    case "CHANGE_STYLE":
      return {
        ...state,
        style: payload,
      };
    case "SET_GEOMETRY_TO_SHOW":
      return {
        ...state,
        geometriesToShow: payload,
      };
    case "SET_UTRECHT":
      return {
        ...state,
        isUtrecht: payload.isUtrecht,
        activeLayers: payload.activeLayers,
        utrechtActiveRisks: payload.utrechtActiveRisks,
      };
    case "SET_UTRECHT_SAMPLENOTICE":
      return {
        ...state,
        utrechtSampleNotice: payload,
      };
    case "SET_UTRECHT_SAMPLENOTICE_ZOOM":
      return {
        ...state,
        utrechtSampleNoticeZoom: payload,
      };
    case "SET_UTRECHT_RISKS":
      return {
        ...state,
        utrechtActiveRisks: payload,
      };
    case "SET_UTRECHT_RISKS_TAB_ONE":
      return {
        ...state,
        utrechtActiveRisksTabOne: payload,
      };
    case "SET_UTRECHT_INDICATORS":
      return {
        ...state,
        utrechtActiveIndicators: payload,
      };
    case "SET_UTRECHT_INDICATORS_TAB_ONE":
      return {
        ...state,
        utrechtActiveIndicatorsTabOne: payload,
      };
    case "SET_UTRECHT_FILTERS":
      return {
        ...state,
        utrechtFilters: payload,
      };
    case "SET_UTRECHT_FILTERS_TAB_ONE":
      return {
        ...state,
        utrechtFiltersTabOne: payload,
      };
    case "SET_UTRECHT_TOP10":
      return {
        ...state,
        utrechtTop10: payload,
      };
    case "SET_UTRECHT_SHOW_ALL":
      return {
        ...state,
        utrechtShowAll: payload,
      };
    case "RESET":
      return {
        ...state,
        metadataPanel: { open: false, properties: {}, metadata: {} },
        popupData: {},
        propertyFiltersWegvak: [],
        propertyFiltersSplits: [],
        geometryIdFilter: null,
        style: {},
      };
    default:
      return state;
  }
};

export default reducer;
