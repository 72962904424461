import React, { useState } from 'react';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from 'react-i18next';

import PropTypes from "prop-types";

import { useWindowDimensions } from "../../../../hooks";
import Iframe from 'react-iframe';
import ClipLoader from "react-spinners/ClipLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AccidentsDialog = ({ open, handleClose, locationKey, straat, plaats }) => {
  const { t } = useTranslation();
  /* eslint-disable no-unused-vars */
  const { height, width } = useWindowDimensions();
  /* eslint-enable no-unused-vars */
  const iframeSrc = "https://ongevallen-dot-ongevalrisico.appspot.com/ongevallen/?key=" + locationKey
  const [loading, setLoading] = useState(true);
  const override = `
  position: absolute;
  z-index: 1000;
  top: 1rem;
  right: 1rem;
`;

  return (
    !!locationKey && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
      // fullScreen="true"
      >
        <DialogTitle id="alert-dialog-slide-title">{t("Verkeersongevallen ") + "(" + straat + " " + plaats + ", " + locationKey + ")"}</DialogTitle>
        <DialogContent>
          <ClipLoader color="#000000" loading={loading} css={override} size={35} />
          <Iframe
            src={iframeSrc}
            title={t("Verkeersongevallen")}
            height={Math.round(height * 0.8, 0)}
            width="100%"
            scrolling="yes"
            frameBorder={0}
            sandbox="" //insecure: "allow-scripts allow-same-origin"
            onLoad={() => setLoading(false)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

AccidentsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  locationKey: PropTypes.string.isRequired,
  straat: PropTypes.string.isRequired,
  plaats: PropTypes.string.isRequired,
};

export default AccidentsDialog;
