import { useEffect } from "react";
import { getGeometries } from "./apiCalls";

const hardcoddedScoreColumns = [
  "wegtype",
  "I_01_verplaatsingen",
  "I_01_onveilige_schoolomgeving",
  "I_02_verplaatsingen",
  "I_03_bromfietsbezit",
  "I_03_bezorgrestaurants",
  "I_03_scholieren",
  "I_04_inwoners",
  "I_04_fietsroutes",
  "I_05_snelheids_overtredingen",
  "I_07_breedte",
  "I_08_inrichting",
  "I_09_gebruik",
  "I_09_kruispunten",
  "I_09_wegvakken",
  "I_10_fietsvoorzieningen",
  "I_10_oversteekbaarheid",
  "I_11_kruispunten",
  "I_11_wegvakken",
  "I_11_gebruik",
  "I_12_onveilige_bermen"
]

const getStyleIdFromLayers = (layerName, geometryLayers) => {
  let styleId = null;
  geometryLayers.forEach((layer) => {
    if (layer.name === layerName && layer.styles.length > 0)
      styleId = layer.styles[0].id;
  });
  return { styleIds: { [layerName]: styleId } };
};

const MAX_GEOMETRIES = 9000;
const MAX_GEOMETRIES_ADDITIONAL_LAYER = 3999;
const HAMMER = 10;

const useLoadGeometries = ({
  bounds,
  state,
  currentMap,
  dispatch,
  map,
  user,
}) => {
  // Load new geometries on map move
  useEffect(() => {
    let isSubscribed = true;

    const scoreColumns = currentMap?.properties?.Whitebox?.ScoreColumns ? currentMap?.properties?.Whitebox?.ScoreColumns.replace("[", "").replace("]", "").split(',') : hardcoddedScoreColumns;

    const fetchGeometries = async (
      map,
      activeLayers,
      dispatch,
      mapId,
      token,
      bounds,
      propertyFiltersWegvak = [],
      propertyFiltersSplits = [],
      utrechtFilters = [],
      style,
      geometryIdFilter = null,
      isUtrecht
    ) => {
      let counter = 0;
      try {
        const specialLayers = ["df_wegvak_full", "df_kruising_full"];
        const utrechtSpecialLayers = ["risico_totaal"];
        dispatch({ type: "GEOMETRIES_FETCH" });
        dispatch({ type: "SET_GEOMETRY_TO_SHOW", payload: false });
        const allData = activeLayers.reduce((acc, curr) => {
          acc[curr] = {
            data: {
              type: "FeatureCollection",
              features: [],
            },
            fetching: true,
            pageStart: null,
          };
          return acc;
        }, {});

        do {
          if (isSubscribed) {
            dispatch({ type: "GEOMETRIES_FETCH" });
            let sources = await Promise.all(
              activeLayers.map(async (layer) => {

                return allData[layer]["fetching"]
                  ? await getGeometries({
                    mapId: currentMap.id,
                    token,
                    layer,
                    layerId: currentMap.geometryLayers.find(
                      (geometryLayer) => geometryLayer.name === layer
                    )?.id,
                    bounds,
                    returnType: isUtrecht
                      ? utrechtSpecialLayers.includes(layer)
                        ? "all"
                        : "geometry"
                      : "geometry",
                    propertyFiltersWegvak: specialLayers.includes(layer)
                      ? propertyFiltersWegvak
                      : null,
                    propertyFiltersSplits: specialLayers.includes(layer)
                      ? propertyFiltersSplits
                      : null,
                    utrechtFilters: utrechtSpecialLayers.includes(layer)
                      ? utrechtFilters
                      : undefined,
                    style: specialLayers.includes(layer)
                      ? style
                      : getStyleIdFromLayers(
                        layer,
                        currentMap.geometryLayers
                      ),
                    geometryIdFilter: specialLayers.includes(layer)
                      ? geometryIdFilter
                      : null,
                    isUtrecht,
                    pageStart: allData[layer]["pageStart"],
                    properties: utrechtSpecialLayers.includes(layer) ? scoreColumns : null
                  })
                  : { result: { features: [] } };
              })
            );
            if (isSubscribed) {
              activeLayers.forEach((layer, i) => {
                allData[layer]["data"]["features"] = [
                  ...allData[layer]["data"]["features"],
                  ...sources[i].result.features,
                ];
                allData[layer]["fetching"] =
                  allData[layer]["fetching"] &&
                  allData[layer]["data"]["features"]?.length <
                  (utrechtSpecialLayers.includes(layer)
                    ? MAX_GEOMETRIES
                    : MAX_GEOMETRIES_ADDITIONAL_LAYER);
                allData[layer]["pageStart"] = sources[i].nextPageStart;
                // const UTRECHT_GEOMETRY_NUMBER = 2000; // also in apiCalls.js
                // nog een ronde als kleiner dan max_geoms en haalt per ronde 2000 locaties op (meestal)
                // sample: als de laatste call (sources[i].result.features.length) kleiner dan 2000 is dan geen sampling, anders wel
                // of sample: als source[i].nextPageStart een object is dan sample, als null is dan geen sample / alles ingeladen
                // console.log(sources[i])
                if (sources[i].nextPageStart === null) {
                  // console.log(layer + ' geen sample, alle locaties worden weergegeven')
                  //allData[layer]["data"]["sample"] = false;
                  dispatch({ type: "SET_UTRECHT_SAMPLENOTICE", payload: false });
                } else {
                  // console.log(layer + ' sample, niet alle locaties worden weergegeven')
                  //allData[layer]["data"]["sample"] = true;
                  dispatch({ type: "SET_UTRECHT_SAMPLENOTICE", payload: true });
                }

                //remove locations not within bounds
                // //console.log(allData[layer].data.features.length)
                // let bounds = map.getBounds()
                // // let remove_ind = []
                // allData[layer].data?.features?.forEach(function (feature, ind) {
                //   //als er een geom punt binnen bounds valt dan bewaren
                //   let keep = false
                //   feature.geometry?.coordinates.forEach(function (item, index) {
                //     if (keep === false) {
                //       let keep_x = ((item[0] >= bounds._sw.lng) || (item[0] <= bounds._ne.lng)) ? true : false
                //       let keep_y = ((item[1] >= bounds._sw.lat) || (item[1] <= bounds._ne.lat)) ? true : false
                //       keep = ((keep_x === true) && (keep_y === true)) ? true : false
                //     }
                //   });
                //   if (keep === false) {
                //     allData[layer].data.features.splice(ind, 1)
                //     // remove_ind.push(ind)
                //   }
                // });
                // console.log(allData[layer].data.features.length)

                map.getSource(layer).setData(allData[layer].data);
              });
              if (
                sources.every((source) => source.result?.features?.length === 0)
              ) {
                // no geometry to show
                dispatch({ type: "SET_GEOMETRY_TO_SHOW", payload: false });
              } else {
                dispatch({ type: "SET_GEOMETRY_TO_SHOW", payload: true });
              }
            }
          }
          ++counter;
        } while (
          isSubscribed &&
          Object.values(allData)
            .map((data) => data.fetching)
            .some(Boolean)
          && counter < HAMMER
        );

        if (isSubscribed) {
          dispatch({
            type: "GEOMETRIES_FETCH_SUCCESS",
          });
        }
      } catch (error) {
        console.log("error: ", error);
        dispatch({
          type: "GEOMETRIES_FETCH_FAILED",
          payload: error,
          error: true,
        });
      }
    };

    if (bounds[0].length > 0 && state.mapLoaded && !!currentMap) {
      fetchGeometries(
        map,
        state.activeLayers,
        dispatch,
        currentMap.id,
        user.token,
        {
          xMin: bounds[0][0],
          yMin: bounds[0][1],
          xMax: bounds[1][0],
          yMax: bounds[1][1],
        },
        state.propertyFiltersWegvak,
        state.propertyFiltersSplits,
        state.utrechtFilters,
        state.style,
        state.geometryIdFilter,
        state.isUtrecht
      );
    }

    return () => (isSubscribed = false);
  }, [
    bounds,
    state.mapLoaded,
    state.propertyFiltersSplits,
    state.propertyFiltersWegvak,
    state.style,
    state.geometryIdFilter,
    state.activeLayers,
    currentMap,
    user.token,
    map,
    dispatch,
    state.isUtrecht,
    state.utrechtFilters,
  ]);
};

export default useLoadGeometries;
