import React, { useEffect, useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useUrlLocationSearch, getIdFromUrl } from "./utils";
//import { useLocation } from "react-router-dom";

const LoadingDiv = () => {
  const [showElement, setShowElement] = useState(true);
  const [progress, setProgress] = useState(0);
  const url = useUrlLocationSearch();

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 7000);
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [])

  return (
    <div>
      {(showElement && getIdFromUrl(url) == null && ((process.env.NODE_ENV === 'production') | (process.env.REACT_APP_NODE_ENV === 'production'))) ? <div id="loadingDiv">
        <div style={{ paddingBottom: '5px', paddingRight: '5px' }}>Loading</div>
        <div style={{ width: '60%' }}>
          <LinearProgress variant="determinate" value={progress} />
        </div>
        {/* <div style={{ width: '10%', float: 'left' }}>&nbsp;</div> */}
      </div> : <></>
      }
    </div >
  )
}

export default LoadingDiv