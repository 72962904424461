import React from "react";

import {
  makeStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "300px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    position: "relative",
    zIndex: "550",
    marginTop: "-5px",
  },
  listItem: {
    whiteSpace: "nowrap",
  },
  listItemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const AutoCompleteMenu = ({ items, goToGeometry, showAutocomplete }) => {
  const classes = useStyles();
  return (
    <Paper
      className={classes.root}
      style={{
        display: items.length > 0 && showAutocomplete ? "block" : "none",
      }}
    >
      <List>
        {items.slice(0, 4).map((item, i) => (
          <ListItem
            className={classes.listItem}
            button
            key={`${item}-${i}`}
            onClick={() => {
              goToGeometry(item.geometrie_ll, item.weergavenaam);
            }}
          >
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={item.weergavenaam}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

AutoCompleteMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      geometrie_ll: PropTypes.string.isRequired,
      weergavenaam: PropTypes.string.isRequired,
    })
  ).isRequired,
  goToGeometry: PropTypes.func.isRequired,
  showAutocomplete: PropTypes.bool.isRequired,
};

export default AutoCompleteMenu;
