import { useEffect, useReducer, useCallback, useMemo } from "react";

import _ from "lodash";

import { useDebounce } from "../../../../hooks";
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "turnFilterOn":
      return {
        filterValue: payload.initialFilterValues,
        isFilterOn: {
          ...payload.initialFiltersOn,
          [payload.name]: payload.value,
        },
      };
    case "valueChange":
      return {
        ...state,
        filterValue: { ...state.filterValue, [payload.name]: payload.value },
      };
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

const useFilterLogic = (state, dispatch, filters) => {
  const initialFiltersOn = useMemo(
    () => Object.fromEntries(filters.map((filter) => [filter.name, false])),
    [filters]
  );

  const initialFilterValues = useMemo(
    () =>
      Object.fromEntries(
        filters.map((filter) => [
          filter.name,
          state.utrechtFilters.find((filter) => filter.key === filter.name)
            ?.value ?? filter.maxValue,
        ])
      ),
    [filters, state.utrechtFilters]
  );

  const [{ isFilterOn, filterValue }, localDispatch] = useReducer(reducer, {
    isFilterOn: initialFiltersOn,
    filterValue: initialFilterValues,
  });

  const debouncedFilterValue = useDebounce(filterValue, 500);

  const handleToggleFilter = useCallback(
    (name, checked) => {
      if (checked) {
        const newFilter = {
          key: name,
          value: filters.find((filter) => filter.name === name)?.maxValue,
          operator: ">=",
        };

        // add filter
        dispatch({
          type: "SET_UTRECHT_FILTERS",
          payload: [
            ...state.utrechtFilters.filter(
              (filter) =>
                !filters.map((filter) => filter.name).includes(filter.key)
            ),
            newFilter,
          ],
        });
      } else {
        // remove filter
        dispatch({
          type: "SET_UTRECHT_FILTERS",
          payload: state.utrechtFilters.filter((filter) => filter.key !== name),
        });
      }

      localDispatch({
        type: "turnFilterOn",
        payload: {
          value: checked,
          name,
          initialFiltersOn,
          initialFilterValues,
        },
      });
    },
    [
      dispatch,
      filters,
      initialFilterValues,
      initialFiltersOn,
      state.utrechtFilters,
    ]
  );

  // handle change in intensiteit filter
  useEffect(() => {
    const filterKey = Object.keys(isFilterOn).find((key) => isFilterOn[key]);
    const newFilter = {
      key: filterKey,
      value: debouncedFilterValue[filterKey],
      operator: ">=",
    };
    // if the filter isn't already there & equal
    if (
      filterKey &&
      !_.isEqual(
        state.utrechtFilters.find((filter) => filter.key === filterKey),
        newFilter
      )
    ) {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: state.utrechtFilters.map((filter) =>
          filter.key === filterKey ? newFilter : filter
        ),
      });
    }
  }, [debouncedFilterValue, dispatch, isFilterOn, state.utrechtFilters]);

  const handleFilterSliderChange = useCallback((filterName, newValue) => {
    localDispatch({
      type: "valueChange",
      payload: { name: filterName, value: newValue },
    });
  }, []);

  const handleFilterInputChange = useCallback((filterName, newValue) => {
    localDispatch({
      type: "valueChange",
      payload: {
        name: filterName,
        value: newValue === "" ? 0 : Number(newValue),
      },
    });
  }, []);

  return [
    isFilterOn,
    filterValue,
    handleToggleFilter,
    handleFilterSliderChange,
    handleFilterInputChange,
  ];
};

export default useFilterLogic;
