import React, { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import { useAuth, useCurrentMap } from "../../../../hooks";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  messageContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "1.3rem",
  },
  legend: {
    marginTop: "15px",
    fontSize: "1.15rem",
  },
}));

const FormDialog = ({ open, handleClose, isUtrecht }) => {
  const classes = useStyles();
  const { user } = useAuth();
  // const [currentMap] = useLocalStorage("currentMap");
  const { currentMap } = useCurrentMap();
  const { t } = useTranslation();

  const [email, setEmail] = useState(user.email);
  const [content, setContent] = useState("");
  const [status, setStatus] = useState("form");

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleContentChange = (e) => setContent(e.target.value);

  const emailTo = currentMap?.properties.General[0].ContactEmail;

  const onSubmit = useCallback(async () => {
    console.log(`submitting to ${emailTo} with email ${email} and content ${content}`);

    setStatus("loading");

    const url =
      "https://us-central1-roadsafety-dd8e9.cloudfunctions.net/sendEmail";

    const data = {
      username: user.username,
      email,
      content,
      isUtrecht,
      emailTo,
    };

    try {
      const res = await axios.post(url, data);
      if (!!res) {
        setStatus("success");
      }
    } catch (error) {
      setStatus("error");
    }
  }, [content, email, user.username, isUtrecht, emailTo]);

  const getContentByStatus = useCallback((status) => {
    switch (status) {
      case "loading":
        return <DialogContentLoading />;
      case "success":
        return <DialogContentSuccess />;
      case "error":
        return <DialogContentError />;
      default:
        return null;
    }
  }, []);

  const handleAllClose = () => {
    setEmail(user.email);
    setContent("");
    setStatus("form");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleAllClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("Neem contact op")}</DialogTitle>
      <div style={{ opacity: status === "form" ? 1 : 0 }}>
        <DialogContentForm
          handleEmailChange={handleEmailChange}
          content={content}
          handleContentChange={handleContentChange}
          handleAllClose={handleAllClose}
          onSubmit={onSubmit}
        />
      </div>
      {status !== "form" && (
        <div className={classes.messageContainer}>
          {getContentByStatus(status)}
        </div>
      )}
      <DialogActions>
        <Button onClick={handleAllClose} color="primary">
          {status === "form" || status === "loading" ? t("Annuleren") : t("Close")}
        </Button>
        {status === "form" && (
          <Button onClick={onSubmit} color="primary">
            {t("Verzenden")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isUtrecht: PropTypes.bool.isRequired,
};

const DialogContentForm = ({
  handleEmailChange,
  content,
  handleContentChange,
}) => {
  const classes = useStyles();
  // const [currentMap] = useLocalStorage("currentMap");
  const { currentMap } = useCurrentMap();
  const { t } = useTranslation();
  return (
    <DialogContent>
      <DialogContentText>
        {t("Neem gerust contact op")}
      </DialogContentText>
      <form className={classes.formContainer}>
        <TextField
          autoFocus
          margin="dense"
          label={t("Email")}
          type="email"
          value={currentMap?.properties.General[0].ContactEmail}
          onChange={handleEmailChange}
        />
        <TextField
          autoFocus
          margin="dense"
          label={t("Bericht")}
          type="text"
          multiline
          rows={6}
          fullWidth
          value={content}
          onChange={handleContentChange}
        />
      </form>
    </DialogContent>
  );
};

DialogContentForm.propTypes = {
  email: PropTypes.string,
  handleEmailChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  handleContentChange: PropTypes.func.isRequired,
};

const DialogContentLoading = () => {
  return <CircularProgress />;
};

const DialogContentSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        {t("Bericht succes")}
      </Typography>
      <Typography className={classes.legend}>
        {t("Bericht dank")}
      </Typography>
    </>
  );
};

const DialogContentError = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Typography className={classes.title}>
        {t("Bericht fail")}
      </Typography>
      <Typography className={classes.legend}>Please try again later</Typography>
    </>
  );
};

export default FormDialog;
