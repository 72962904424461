import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

import { riskColors } from "../../useMapViewerLogic/useMapInitialization";
import isNumber from "lodash.isnumber";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 14,
    borderRadius: 7,
    width: "260px",
    display: "flex",
    position: "relative",
  },
  colorBox: {
    width: "20%",
    height: "100%",
    "&$first": {
      borderTopLeftRadius: "7px",
      borderBottomLeftRadius: "7px",
    },
    "&$last": {
      borderTopRightRadius: "7px",
      borderBottomRightRadius: "7px",
    },
  },
  arrow: {
    position: "absolute",
    top: "-12px",
    width: "0",
    height: "0",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "20px solid Gainsboro",
  },
  innerArrow: {
    position: "relative",
    top: "-4px",
    left: "-5px",
    width: "0",
    height: "0",
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: "14px solid black",
  },
  valueLabel: {
    position: "absolute",
    top: "-34px",
    transform: "translateX(-25%)",
    fontWeight: "600",
  },
  first: {},
  last: {},
}));

const RiskMeter = ({ value, ...otherProps }) => {
  const classes = useStyles({ value });
  return (
    <div className={classes.root} {...otherProps}>
      <div className={classes.arrow} style={{ left: `${value * 100 - 3}%` }}>
        <span className={classes.innerArrow} />
      </div>
      <Typography
        className={classes.valueLabel}
        style={{ left: `calc(${value * 100 - 3}%)` }}
      >
        {isNaN(Number(value)) || !isNumber(Number(value)) ? "" : value}
      </Typography>
      {riskColors.map((color, i) => (
        <div
          className={clsx(
            i === 0 && classes.first,
            i === riskColors.length - 1 && classes.last,
            classes.colorBox
          )}
          style={{ backgroundColor: color }}
          key={color}
        />
      ))}
    </div>
  );
};

RiskMeter.propTypes = {
  value: PropTypes.number.isRequired,
};

export default RiskMeter;
