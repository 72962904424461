import React from "react";

import { makeStyles, IconButton } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import PropTypes from "prop-types";

import OverlayWrapper from "./OverlayWrapper";
import { drawerWidth } from "./GeometryMetadataDrawer";
import { useAuth, useCurrentMap } from "../../../hooks";
import useInstanceData from "../../../hooks/useInstanceData";

import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  overlay: {
    top: "32px",
    right: (props) => (props.shifted ? `${240 + drawerWidth}px` : "240px"),
    transition: theme.transitions.create(["right"], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("xs")]: {
      right: "30px !important",
      top: "198px",
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    height: "32px",
    width: "32px",
  },
}));

const DocumentationLink = ({ state }) => {
  const { user } = useAuth();
  // const [currentMap] = useLocalStorage("currentMap");
  const { currentMap } = useCurrentMap();
  const classes = useStyles({
    shifted: state.metadataPanel.open,
  });
  const { t } = useTranslation();

  const instances = useInstanceData()?.Instances;
  const clientname = instances && Object.keys(instances)
    .filter(i => instances[i].mapID === currentMap?.id)
    .reduce((obj, key) => {
      //obj[key] = instances[key];
      return instances[key];
    }, {}).clientname;

  //console.log(currentMap?.properties.General[0].DocsURL + `&token=${user.token}`)
  let rand = Math.floor(Math.random() * 100000) + 1;
  const handleClick = () => {
    window.open(
      currentMap?.properties.General[0].DocsURL + `&client=` + clientname + `&rand=` + rand + `&token=` + user.token
      ,
      "_blank"
    );
  };

  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399 }}>
      <Tooltip placement="bottom" title={t("Documentatie")}>
        <IconButton
          color="primary"
          className={classes.iconButton}
          onClick={handleClick}
          style={{ display: currentMap?.properties.General[0]?.DocsURL.length >= 1 ? 'block' : 'none' }}
        >
          <DescriptionIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </OverlayWrapper>
  )
};

DocumentationLink.propTypes = {
  state: PropTypes.shape({
    metadataPanel: PropTypes.shape({
      open: PropTypes.bool.isRequired,
    }),
    isUtrecht: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DocumentationLink;
