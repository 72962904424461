import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    background: (props) =>
      `linear-gradient(90deg, hsla(141, 81%, 59%, 1) calc(0px + ${100 - props.value
      }px), hsla(41, 88%, 61%, 1) calc(45px + ${100 - props.value
      }px), hsla(358, 82%, 61%, 1) calc(100px + ${100 - props.value}px))`,
    filter:
      'progid: DXImageTransform.Microsoft.gradient( startColorstr="#42EB7D", endColorstr="#F3BC44", GradientType=1 )',
  },
}));

const CustomProgressBar = forwardRef(({ value, ...otherProps }, ref) => {
  const classes = useStyles({ value });
  return (
    <LinearProgress
      classes={{
        bar: classes.bar,
        root: classes.root,
        colorPrimary: classes.colorPrimary,
      }}
      ref={ref}
      value={value}
      {...otherProps}
    />
  );
});

CustomProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CustomProgressBar;
