module.exports = {
    "login": "Login",
    "submit": "Submit",
    "Username": "Username",
    "Password": "Password",
    "Cancel": "Cancel",
    "Details": "Details",
    "Beschrijving": "Beschrijving",
    "Bron": "Bron",
    "Waarde": "Waarde",
    "comments": "comments",
    "Open dashboard": "Open dashboard",
    "Add comment": "Add comment",
    "Wegvak ID": "Wegvak ID",
    "Kruispunt ID": "Kruispunt ID",
    "Plaats": "Plaats",
    "Verkeersintensiteit": "Verkeersintensiteit",
    "mvt/etmaal": "mvt/etmaal",
    "Aantal takken": "Aantal takken",
    "Detailweergave": "Detailweergave",
    "Type": "Type",
    "Agree": "Agree",
    "Disclaimer": "Disclaimer",
    "Melding": "Melding",
    "Afloop": "Afloop",
    "Meldingen": "Meldingen",
    "Info": "Info",
    "Snelheid": "Snelheid",
    "Tijdstippen": "Tijdstippen",
    "Aantal bestel vracht": "Aantal bestel vracht",
    "Aantal fiets": "Aantal fiets",
    "Verzenden": "Verzenden",
    "Risicoprofiel": "Risicoprofiel",
    "Wegtype": "Type",
    "No_filters": "Geen",
    "None": "Alles verbergen",
    "Select project": "Select project",
    "Zoeken": "Zoeken",
    "Terug": "Terug",
    "Filters": "Filters",
    "Aantal motor": "Aantal motor",
    "Risicoscore": "Risicoprofiel",
    "Aantal object": "Aantal object",
    "Aantal overig": "Aantal overig",
    "Aantal personenauto": "Aantal personenauto",
    "Aantal zonsopkomst": "Aantal zonsopkomst",
    "Aantal meldingen": "Aantal meldingen",
    "Bekijk meldingen": "Bekijk meldingen",
    "Bekijk grafiek": "Bekijk grafiek",
    "SHAP help": "Bekijk een grafiek met verklarende indicatoren van de voorspelling op deze locatie",
    "V85": "Snelheid",
    "V85 werkelijk gereden snelheid": "Werkelijk gereden snelheden",
    "V85 help": "Werkelijk gereden snelheden",
    "Bekijk V85": "Bekijk snelheidsdata",
    "V85 additional help": "Bekijk grafieken met informatie over werkelijk gereden snelheden op deze locatie",
    "Bekijk ongevallen": "Bekijk aanvullende ongevaldata",
    "Ongevallen help": "Ongevallen uit BRON over de afgelopen 5 jaar. Alleen ongevallen waarvan de exacte locatie bekend is. BRON ongevallen kunnen afwijken van de STAR ongevallen registratie door verschillen in verwerking.",
    "Ongevallen additional help": "Bekijk grafieken met aanvullende informatie over ongevallen op deze locatie",
    "Verklarende indicatoren (SHAP values)": "Verklarende indicatoren (locatie ",
    "Top 25 factoren": "Top 25 factoren",
    "Risico-indicatoren": "Risico-indicatoren",
    "Aantal avondspits": "Aantal avondspits",
    "Subjectieve meldingen": "Subjectieve meldingen",
    "Meldingen help": "Meldingen van mensen over verkeersonveilige locaties",
    "Aantal brom snorfiets": "Aantal brom snorfiets",
    "Aantal zonsondergang": "Aantal zonsondergang",
    "Aantal ochtendspits": "Aantal ochtendspits",
    "Aantal&apos;s nacht": "Aantal&apos;s nacht",
    "Legenda": "Legenda",
    "Reset": "Reset",
    "Type wegen": "Wegtype",
    "Type kruispunten": "Kruispunttype",
    "Wegbeheerder": "Wegbeheerder",
    "Aantal overdag": "Aantal overdag",
    "Aantal uitsluitend materiele schade": "Aantal uitsluitend materiele schade",
    "Aantal verkeersgewonden": "Aantal slachtofferongevallen",
    "Snelheidsregime": "Snelheidsregime",
    "Top 10 gevaarlijke wegvakken": "Top 10 gevaarlijke wegvakken",
    "Search current area": "Search current area",
    "Aantal verkeersdoden": "Aantal dodelijke ongevallen",
    "Top 10 gevaarlijke kruispunten": "Top 10 gevaarlijke kruispunten",
    "No geometry found": "No geometry found",
    "Ongewenst rijgedrag": "Ongewenst rijgedrag",
    "Overscrhijdingen maximumsnelheid": "Overscrhijdingen maximumsnelheid",
    "Harde rembewegingen": "Harde rembewegingen",
    "Eigenschappen": "Eigenschappen",
    "Loading geometries": "Loading geometries",
    "Kaartweergave top 10 locaties": "Top 10 hoogste risico locaties",
    "Sample notice": "Let op: niet alle locaties zijn geladen, zoom verder in",
    "Sample notice zoom sample 70": "Let op: alleen 70 km/h locaties en hoger en niet alle locaties zijn geladen, zoom verder in",
    "Sample notice zoom no sample 70": "Let op: alleen 70 km/h locaties en hoger, zoom verder in",
    "Sample notice zoom sample 50": "Let op: niet alle wegtypes worden weergegeven en niet alle locaties zijn geladen, zoom verder in",
    "Sample notice zoom no sample 50": "Let op: niet alle wegtypes worden weergegeven, zoom verder in",
    "Ongevallen": "Ongevallen",
    "Verkeersongevallen": "Verkeersongevallen",
    "Additional layer": "Additional layer",
    "Geen wegvakken gevonden, zoom verder in of verander je filters": "Geen wegvakken gevonden, zoom verder in of verander je filters",
    "Ongevallen 2014-2020": "Ongevallen afgelopen 5 jaar",
    "Aantal ongevallen 2014-2020": "Aantal ongevallen afgelopen 5 jaar",
    "Objectieve ongevallen": "Objectieve ongevallen",
    "Harde bochtbewegingen": "Harde bochtbewegingen",
    "Harde optrekbewegingen": "Harde optrekbewegingen",
    "Floating car data": "Floating car data",
    "Documentatie": "Documentatie",
    "Contact": "Contact",
    "Neem contact op": "Neem contact op",
    "Neem gerust contact op": "Heeft u een vraag of suggestie? Neem gerust contact op.",
    "Email": "E-mailadres",
    "Bericht": "Bericht",
    "Bericht succes": "Uw bericht is succesvol verzonden.",
    "Bericht dank": "Dank voor uw feedback.",
    "Bericht fail": "Error, bericht niet verzonden.",
    "Annuleren": "Annuleren",
    "Close": "Close",
    "Risico_uitleg": "Selecteer één of meer risico's en bekijk de scores op de kaart.",
    "Data_uitleg": "Geef datalagen weer op de kaart. Deze data zijn de onderliggende basis voor de opgestelde risicoprofielen.",
    "Filter_uitleg": "Door een beperking aan de backend kunnen alleen filtercombinaties gemaakt worden tussen het filter 'Type' en één andere filter. Hier wordt aan gewerkt.",
    "Additioneel_uitleg": "Geef additionele lagen naast de risicoprofielen weer op de kaart.",
    "Additionele lagen": "Additionele lagen",
    "Datalagen": "Data",
    "Show all": "Risico's weergeven",
    "Hide all": "Risico's verbergen",
    "Alle": "Alle",
    "Risico's": "Risico's",
    "Verberg": "Verberg",
    "Busintensiteit": "Busintensiteit",
    "Verkeersintensiteit (mvt/etmaal)": "Verkeersintensiteit (mvt/etmaal)",
    "bebording": "bebording",
    "bebouwing": "bebouwing",
    "bedrijven": "bedrijven",
    "Lagen, filters, top10": "Lagen, filters, top10",
    "Risicoprofiel, filters": "Risicoprofiel, filters",
    "begroeiing": "begroeiing",
    "bochtigheid": "bochtigheid",
    "fiets": "fiets",
    "horeca": "horeca",
    "kruispunttype": "kruispunttype",
    "laden lossen": "laden lossen",
    "omgevingsobjecten": "omgevingsobjecten",
    "openbaar vervoer": "openbaar vervoer",
    "oversteek": "oversteek",
    "parkeren": "parkeren",
    "school": "school",
    "snelheid": "snelheid",
    "verkeersintensiteit": "verkeersintensiteit",
    "verlichting": "verlichting",
    "voetpad": "voetpad",
    "wegvak richting": "wegvak richting",
    "winkel": "winkel",
    "Laag": "Laag",
    "Type wegvak": "Type wegvak",
    "There was an error with the project properties": "There was an error with the project properties",
    "Comment": "Comment",
    "Ongevallen alle jaren": "Ongevallen afgelopen 5 jaar",
    "Fietsintensiteit (fietsers/etmaal)": "Fietsintensiteit (fietsers/etmaal)",
    "Deselect all": "Alles deselecteren",
    "Select all": "Alles selecteren",
    "Vertaling": "Vertaling",
    "Gebruiker": "Gebruiker",
    "Fietsnet": "Regionaal fietsnetwerk",
    "Additioneel": "Additioneel",
    "top10_help": "Top 10 locaties van de huidige kaartweergave met hoogste risicoscores weergeven. Let op: verbergt het linkerpaneel om de hele kaart weer te geven.",
    "Geen score": "geen score",
    "Legend Zeer laag": "zeer laag",
    "Legend Laag": "laag",
    "Legend Gemiddeld": "gemiddeld",
    "Legend Hoog": "hoog",
    "Legend Zeer hoog": "zeer hoog"
}