import React from "react";
import { ListItem, FormControl, NativeSelect } from "@material-ui/core";
import PropTypes from "prop-types";
import useLocalStorage from "../../../hooks/useLocalStorage";
export default function AccountMapIDSelect({ mapKeys, dispatch }) {
  const currentMapState = JSON.parse(localStorage.getItem("currentMap"));
  const [currentMap, setCurrentMap] = useLocalStorage("currentMap");

  const onSelect = (e) => {
    dispatch({ type: "SET_SELECTED_MAP_KEY", payload: e.target.value });
    setCurrentMap(() => {
      return { ...currentMapState, id: e.target.value };
    });
    setTimeout(() => {
      // localStorage.clear();
      // sessionStorage.clear();
      window.location.reload(false);
    }, 100);
  };
  return (
    <ListItem>
      <FormControl fullWidth>
        <NativeSelect
          defaultValue={currentMap?.id}
          inputProps={{
            name: "age",
            id: "uncontrolled-native",
          }}
          onChange={onSelect}
        >
          {mapKeys.map((mapKey) => (
            <option key={mapKey.item + "-" + mapKey.id} value={mapKey.id}>
              {mapKey.item}: {mapKey.id}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </ListItem>
  );
}
AccountMapIDSelect.propTypes = {
  mapKeys: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
};
