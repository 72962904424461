import React, { useState, useCallback } from "react";

import {
  makeStyles,
  Avatar,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider
} from "@material-ui/core";
import PropTypes from "prop-types";

import OverlayWrapper from "./OverlayWrapper";
import { drawerWidth } from "./GeometryMetadataDrawer";
import AccountMapIDSelect from "./AccountMapIDSelect";
import { useAuth } from "../../../hooks";
import { getMapKeys } from '../../../hooks/utils';
import useInstanceData from "../../../hooks/useInstanceData";
import AppUtility from "../../../AppUtility";

import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  overlay: {
    top: "32px",
    right: (props) => (props.shifted ? `${32 + drawerWidth}px` : "32px"),
    transition: theme.transitions.create(["right"], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("xs")]: {
      right: "20px",
      top: "120px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const Account = ({ state, dispatch }) => {
  const classes = useStyles({
    shifted: state.metadataPanel.open,
  });

  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const handleClick = useCallback(
    () => setShowAccountMenu((prev) => !prev),
    []
  );
  const handleCloseAccountMenu = useCallback(
    () => setShowAccountMenu(false),
    []
  );

  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399 }}>
      <ClickAwayListener onClickAway={handleCloseAccountMenu}>
        <div className={classes.container}>
          <AccountAvatar onClick={handleClick} />
          <AccountMenu
            show={showAccountMenu}
            state={state}
            dispatch={dispatch}
          />
        </div>
      </ClickAwayListener>
    </OverlayWrapper>
  );
};

Account.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func
};

const useAccountAvatarStyles = makeStyles((theme) => ({
  avatar: {
    // borderRadius: "50%",
    height: "32px",
    width: "32px",
    textTransform: "uppercase",
    fontSize: "0.908rem",
    cursor: (props) => (props.clickable ? "pointer" : "default"),
  },
}));

const AccountAvatar = ({ onClick, clickable = true }) => {
  const classes = useAccountAvatarStyles({ clickable });
  const { user } = useAuth();
  const { username, picture } = user;
  const { t } = useTranslation();
  return (
    <Tooltip placement="bottom" title={t("Gebruiker")}>
      <Avatar
        className={classes.avatar}
        alt={username}
        src={!!picture ? `data:image/jpeg;base64,${picture}` : null}
        style={{ backgroundColor: AppUtility.getAvatarColor(username) }}
        onClick={onClick}
      >
        {username.substr(0, 2)}
      </Avatar>
    </Tooltip>
  );
};

AccountAvatar.propTypes = {
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
};

const useAccountMenuStyles = makeStyles((theme) => ({
  container: {
    width: "300px",
    position: "relative",
    top: "10px",
    right: "0px",
  },
  list: {
    paddingTop: 0,
  },
  listItemAvatarContainer: {
    paddingTop: "4px",
  },
  listItemAvatar: {
    minWidth: "46px",
  },
}));

const AccountMenu = ({
  show,
  state,
  dispatch
}) => {
  const classes = useAccountMenuStyles();
  const { user, signout } = useAuth();

  // get mapKeys from instanceData instead of state.mapKeys which is not filled yet and gives empty dropdown select mapid for admin users
  const data = useInstanceData();
  let mapKeys = getMapKeys(user.username, data, data.Instances)

  return (
    <Paper
      style={{ visibility: show ? "visible" : "hidden" }}
      className={classes.container}
    >
      <List component="nav" aria-label="log out" className={classes.list}>
        <ListItem className={classes.listItemAvatarContainer}>
          <ListItemAvatar className={classes.listItemAvatar}>
            <AccountAvatar clickable={false} />
          </ListItemAvatar>
          <ListItemText primary={user.username} secondary={user.email} />
        </ListItem>
        {(user.isAdmin && mapKeys !== undefined) && <AccountMapIDSelect mapKeys={mapKeys} dispatch={dispatch} />}

        <Divider />

        <ListItem button onClick={signout}>
          <ListItemText primary="Uitloggen" />
        </ListItem>
      </List>
    </Paper>
  );
};

AccountMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Account;
