import React from "react";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const SampleNotice = ({ SampleNoticeShow, SampleNoticeZoomShow, FetchingGeometriesDone, dispatch }) => {
  const { t } = useTranslation();
  const handleClose = (e) => {
    dispatch({ type: "SET_UTRECHT_SAMPLENOTICE", payload: false });
    dispatch({ type: "SET_UTRECHT_SAMPLENOTICE_ZOOM", payload: -1 });
  };
  let showNotice = (SampleNoticeZoomShow !== -1 || SampleNoticeShow === true) ? true : false
  let message = ''
  if (FetchingGeometriesDone === true) {
    // console.log("SampleNoticeShow " + SampleNoticeShow.toString())
    // console.log("SampleNoticeZoomShow " + SampleNoticeZoomShow.toString())
    if (showNotice === true) {
      if (SampleNoticeZoomShow !== -1 && SampleNoticeShow === true) {
        if (SampleNoticeZoomShow === 70) {
          message = t("Sample notice zoom sample 70") //zoom sample
        } else {
          message = t("Sample notice zoom sample 50") //zoom sample
        }
      }
      if (SampleNoticeZoomShow !== -1 && SampleNoticeShow === false) {
        if (SampleNoticeZoomShow === 70) {
          message = t("Sample notice zoom no sample 70") //zoom no sample
        } else {
          message = t("Sample notice zoom no sample 50") //zoom no sample
        }
      }
      if (SampleNoticeZoomShow === -1 && SampleNoticeShow === true) {
        message = t("Sample notice") //sample
      }
    }
  }

  return (
    <>
      {FetchingGeometriesDone === true ? (
        <Snackbar open={showNotice} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

SampleNotice.propTypes = {
  SampleNoticeShow: PropTypes.bool.isRequired,
  SampleNoticeZoomShow: PropTypes.number.isRequired,
};

export default SampleNotice;
