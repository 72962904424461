import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    minWidth: "300px",
  },
}));

const AddCommentDialog = ({
  open,
  rowKey,
  comment,
  handleClose,
  handleSubmitEditComment,
}) => {
  const classes = useStyles();

  const [commentValue, setCommentValue] = useState(comment ?? "");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleCommentValueChange = (e) => setCommentValue(e.target.value);
  const handleSubmit = async () => {
    setLoading(true);
    await handleSubmitEditComment(rowKey, commentValue);
    setLoading(false);
    handleClose();
  };

  useEffect(() => {
    setCommentValue(comment);
  }, [comment]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setLoading(false);
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {t("Add comment")}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          multiline={true}
          rows={5}
          id="name"
          label={t("Comment")}
          type="text"
          fullWidth
          value={commentValue}
          onChange={handleCommentValueChange}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              onClick={() => {
                setLoading(false);
                handleClose();
              }}
              color="primary"
            >
              {t("Cancel")}
            </Button>
            <Button color="primary" onClick={handleSubmit}>
              {t("Add comment")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

AddCommentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rowKey: PropTypes.string,
  comment: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSubmitEditComment: PropTypes.func.isRequired,
};

export default AddCommentDialog;
