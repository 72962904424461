module.exports = {
    "login": "Login",
    "submit": "Submit",
    "Username": "Username",
    "Password": "Password",
    "Cancel": "Cancel",
    "Details": "Details",
    "Beschrijving": "Description",
    "Bron": "Source",
    "Waarde": "Value",
    "comments": "comments",
    "Open dashboard": "Open dashboard",
    "Add comment": "Add comment",
    "Wegvak ID": "Road section ID",
    "Kruispunt ID": "Junction ID",
    "Plaats": "Residence",
    "Verkeersintensiteit": "Traffic intensity",
    "mvt/etmaal": "vehicles/24hrs",
    "Aantal takken": "Number of branches",
    "Detailweergave": "Detail view",
    "Type": "Type",
    "Agree": "Agree",
    "Disclaimer": "Disclaimer",
    "Melding": "Notification",
    "Afloop": "Outcome",
    "Meldingen": "Complaints",
    "Info": "Info",
    "Snelheid": "Speed",
    "Tijdstippen": "Point of times",
    "Aantal bestel vracht": "Number of freight",
    "Aantal fiets": "Number of bicycles",
    "Verzenden": "Send",
    "Risicoprofiel": "Risk profile",
    "Wegtype": "Type",
    "No_filters": "None",
    "None": "Hide all",
    "Select project": "Select project",
    "Zoeken": "Search",
    "Terug": "Back",
    "Filters": "Filters",
    "Aantal motor": "Number of motors",
    "Risicoscore": "Risk profile",
    "Aantal object": "Number of objects",
    "Aantal overig": "Number of additional",
    "Aantal personenauto": "Number of passenger cars",
    "Aantal zonsopkomst": "Number during sunrise",
    "Aantal meldingen": "Number of complaints",
    "Bekijk meldingen": "View complaints",
    "Bekijk grafiek": "View graph",
    "SHAP help": "View a graph with indicators that explain the prediction on this location",
    "V85": "Speed",
    "V85 werkelijk gereden snelheid": "Actual driven speeds",
    "V85 help": "Actual driven speeds information",
    "Bekijk V85": "View speeding data",
    "V85 additional help": "View graphs with actual driven speeds information on this location",
    "Bekijk ongevallen": "View extra accident data",
    "Ongevallen help": "Traffic accidents from source BRON over the past five years. Only accidents of which the exact location is known. BRON accidents can differ from accidents registered in STAR due to differences in data processing.",
    "Ongevallen additional help": "View graphs with extra traffic accident information on this location",
    "Verklarende indicatoren (SHAP values)": "Explainable indicators (location ",
    "Top 25 factoren": "Top 25 factors",
    "Risico-indicatoren": "Risk indicators",
    "Aantal avondspits": "Number during evening peak hours",
    "Subjectieve meldingen": "Subjective complaints",
    "Meldingen help": "Complaints of people about unsafe traffic locations",
    "Aantal brom snorfiets": "Number of mopes",
    "Aantal zonsondergang": "Number during sunset",
    "Aantal ochtendspits": "Number during morning peak hours",
    "Aantal&apos;s nacht": "Number at night",
    "Legenda": "Legend",
    "Reset": "Reset",
    "Type wegen": "Road type",
    "Type kruispunten": "Junction type",
    "Wegbeheerder": "Road maintainer",
    "Aantal overdag": "Number during the day",
    "Aantal uitsluitend materiele schade": "Number solely material damage",
    "Aantal verkeersgewonden": "Number of road injuries",
    "Snelheidsregime": "Speed regime",
    "Top 10 gevaarlijke wegvakken": "Top 10 dangerous roads",
    "Search current area": "Search current area",
    "Aantal verkeersdoden": "Number of road deaths",
    "Top 10 gevaarlijke kruispunten": "Top 10 dangerous junctions",
    "No geometry found": "No geometry found",
    "Ongewenst rijgedrag": "Unwanted driving behavior",
    "Overscrhijdingen maximumsnelheid": "Exceeding maximum speed",
    "Harde rembewegingen": "Hard braking movements",
    "Eigenschappen": "Characteristics",
    "Loading geometries": "Loading geometries",
    "Kaartweergave top 10 locaties": "Top 10 highest risk locations",
    "Sample notice": "Note: not all locations are shown, please zoom further",
    "Sample notice zoom sample 70": "Note: only 70 km/h locations and higher and not all locations are loaded, please zoom further",
    "Sample notice zoom no sample 70": "Note: only 70 km/h locations and higher, please zoom further",
    "Sample notice zoom sample 50": "Note: not all road types are shown and not all locations are loaded, please zoom further",
    "Sample notice zoom no sample 50": "Note: not all road types are shown, please zoom further",
    "Ongevallen": "Accidents",
    "Verkeersongevallen": "Traffic accidents",
    "Additional layer": "Additional layer",
    "Geen wegvakken gevonden, zoom verder in of verander je filters": "No roads founds, please zoom in further or change filters",
    "Ongevallen 2014-2020": "Traffic accidents past 5 years",
    "Aantal ongevallen 2014-2020": "Number of traffic accidents past 5 years",
    "Objectieve ongevallen": "Objective traffic accidents",
    "Harde bochtbewegingen": "Hard curving movements",
    "Harde optrekbewegingen": "Hard accelaration movements",
    "Floating car data": "Floating car data",
    "Documentatie": "Documentation",
    "Contact": "Contact",
    "Neem contact op": "Contact",
    "Neem gerust contact op": "Do not hesitate to contact us in case of questions or suggestions.",
    "Email": "Email adress",
    "Bericht": "Message",
    "Bericht succes": "Your message was successfully sent.",
    "Bericht dank": "Thank you for your feedback.",
    "Bericht fail": "There was an error while sending your message.",
    "Annuleren": "Cancel",
    "Close": "Close",
    "Risico_uitleg": "Select one or more risks and interpret the risk scores on the map.",
    "Data_uitleg": "Show data layers on the map. These data layers are the underlying basis for the defined risk profiles.",
    "Filter_uitleg": "Due to a backend limitation filter combinations are only possible between the filter 'Type' and one other filter. We will solve this issue soon.",
    "Additioneel_uitleg": "Plot additional layers in addition to the risk profile locations on the map.",
    "Additionele lagen": "Additional layers",
    "Datalagen": "Data",
    "Show all": "Show risks",
    "Hide all": "Hide risks",
    "Alle": "All",
    "Risico's": "Risks",
    "Verberg": "Hide",
    "Busintensiteit": "Bus intensity",
    "Verkeersintensiteit (mvt/etmaal)": "Traffic intensity (vehicles/24hrs)",
    "bebording": "signage",
    "bebouwing": "buildings",
    "bedrijven": "businesses",
    "Lagen, filters, top10": "Layers, filters, top 10",
    "Risicoprofiel, filters": "Risk profile, filters",
    "begroeiing": "vegetation",
    "bochtigheid": "curvature",
    "fiets": "bicycle",
    "horeca": "catering industry",
    "kruispunttype": "junction type",
    "laden lossen": "loading unloading",
    "omgevingsobjecten": "surrounding objects",
    "openbaar vervoer": "public transport",
    "oversteek": "crossing",
    "parkeren": "parking",
    "school": "school",
    "snelheid": "speed",
    "verkeersintensiteit": "traffic intensity",
    "verlichting": "illumination",
    "voetpad": "foot path",
    "wegvak richting": "road direction",
    "winkel": "shop",
    "Laag": "Low",
    "Type wegvak": "Road type",
    "There was an error with the project properties": "There was an error with the project properties",
    "Comment": "Comment",
    "Ongevallen alle jaren": "Traffic accidents past 5 years",
    "Fietsintensiteit (fietsers/etmaal)": "Bicycle intensity (bicycles/24hrs)",
    "Deselect all": "Deselect all",
    "Select all": "Select all",
    "Vertaling": "Translation",
    "Gebruiker": "User",
    "Fietsnet": "Regional bicycle network",
    "Additioneel": "Additional",
    "top10_help": "Show top 10 locations of current map view with highest risk scores. Note: hides the left panel to show the full map.",
    "Geen score": "no score",
    "Legend Zeer laag": "very low",
    "Legend Laag": "low",
    "Legend Gemiddeld": "average",
    "Legend Hoog": "high",
    "Legend Zeer hoog": "very high"
}