import React, { useEffect, useState } from "react";
import { useCurrentMap } from "../../../hooks";
import useInstanceData from "../../../hooks/useInstanceData";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Proj4 from "proj4";

Proj4.defs(
  "EPSG:28992",
  "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs"
);

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "min(600px, 70vh)",
    minHeight: "min(600px, 70vh)",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  streetView: {
    width: "100%",
    height: "100%",
  },
}));

const CycloMedia = ({ coords = [], open, isUtrecht }) => {
  const classes = useStyles();

  const { currentMap } = useCurrentMap();
  const instances = useInstanceData()?.Instances;

  const [isInit, setIsInit] = useState(false);
  const [cycloApiUser, setCycloApiUser] = useState('');

  useEffect(() => {
    instances &&
      Object.keys(instances).forEach(function (prop) {
        // `prop` is the property name
        // `data[prop]` is the property value
        let c = instances[prop]?.mapID === currentMap?.id && prop.replace("vm_", "").replace("_prod", "").replace("_dev", "");
        c.length >= 1 && setCycloApiUser(c);
      });
  }, [instances, currentMap]);
  //console.log(cycloApiUser.toUpperCase() + "_");

  useEffect(() => {
    function initApi() {
      window.StreetSmartApi.init({
        targetElement: document.getElementById("streetsmartApi"),
        username:
          process.env[
          `REACT_APP_${isUtrecht ? cycloApiUser.toUpperCase() + "_" : ""}CYCLOMEDIA_USERNAME`
          ],
        password:
          process.env[`REACT_APP_${isUtrecht ? cycloApiUser.toUpperCase() + "_" : ""}CYCLOMEDIA_PW`],
        apiKey:
          process.env[
          `REACT_APP_${isUtrecht ? cycloApiUser.toUpperCase() + "_" : ""}CYCLOMEDIA_API_KEY`
          ],
        srs: "EPSG:28992",
        locale: "nl",
        configurationUrl: "https://atlas.cyclomedia.com/configuration",
        addressSettings: {
          locale: "nl",
          database: "CMDatabase",
        },
      }).then(
        () => {
          setIsInit(true);
        },
        function (err) {
          // console.log("Api: init: failed. Error: ", err);
        }
      );
    }
    if (!window.StreetSmartApi.getApiReadyState() && open) {
      initApi();
    }
  }, [coords, isUtrecht, open, cycloApiUser]);

  useEffect(() => {
    const openStreetSmart = () => {
      var viewerType = window.StreetSmartApi.ViewerType.PANORAMA;
      console.log(Proj4("EPSG:4326", "EPSG:28992", [coords[1], coords[0]]).join(","))
      window.StreetSmartApi.open(
        Proj4("EPSG:4326", "EPSG:28992", [coords[1], coords[0]]).join(","),
        {
          viewerType: viewerType,
          replace: true,
          srs: "EPSG:28992",
          panoramaViewer: {
            recordingsVisible: true, //heeft geen effect, de recordings blijven visible
            timeTravelVisible: true, //heeft geen effect, timetravel blijft visible
          },
        }
      )
        .then(function (result) {
          // console.log("Created component through API:", result);
          if (result) {
            for (let i = 0; i < result.length; i++) {
              if (
                result[i].getType() ===
                window.StreetSmartApi.ViewerType.PANORAMA
              )
                window.panoramaViewer = result[i];
            }
          }
        })
        .catch(function (reason) {
          // console.log("Failed to create component(s) through API: " + reason);
        });
    };
    if ((isInit || window.StreetSmartApi.getApiReadyState()) && open) {
      openStreetSmart();
    }
  }, [coords, isInit, open]);

  return (
    <div className={classes.container}>
      <div id="streetsmartApi" className={classes.streetView} />
    </div>
  );
};

CycloMedia.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  isUtrecht: PropTypes.bool.isRequired,
};

export default CycloMedia;
