import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import en from './locales/en';
import nl from './locales/nl';

i18n
  .use(initReactI18next) 
  .init({
      resources: {
      en: {
        translation: en
      },
      nl: {
        translation: nl
      },
    },
    lng: "nl", // if you're using a language detector, do not define the lng option
    fallbackLng: "nl",
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
