import React, { useState, useCallback } from "react";

import { makeStyles, IconButton } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PropTypes from "prop-types";

import OverlayWrapper from "../OverlayWrapper";
import { drawerWidth } from "../GeometryMetadataDrawer";
import HelpFormDialog from "./HelpFormDialog";

import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  overlay: {
    top: "32px",
    right: (props) => (props.shifted ? `${100 + drawerWidth}px` : "100px"),
    transition: theme.transitions.create(["right"], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("xs")]: {
      right: "30px !important",
      top: "158px",
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    height: "32px",
    width: "32px",
  },
}));

const Help = ({ state }) => {
  const classes = useStyles({
    shifted: state.metadataPanel.open,
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const onClick = () => {
    setDialogOpen(true);
  };

  const handleClose = useCallback(() => setDialogOpen(false), []);
  const { t } = useTranslation();

  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399 }}>
      <Tooltip placement="bottom" title={t("Neem contact op")}>
        <IconButton
          color="primary"
          className={classes.iconButton}
          onClick={onClick}
        >
          <MailIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <HelpFormDialog
        open={dialogOpen}
        handleClose={handleClose}
        isUtrecht={state.isUtrecht}
      />
    </OverlayWrapper>
  );
};

Help.propTypes = {
  state: PropTypes.shape({
    metadataPanel: PropTypes.shape({
      open: PropTypes.bool.isRequired,
    }),
    isUtrecht: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Help;
