import { useEffect, useLayoutEffect, useReducer, useRef, useMemo } from "react";

import mapboxgl from "mapbox-gl";
import { useAuth, useCurrentMap } from "../../../hooks";
import useInstanceData from "../../../hooks/useInstanceData";
import { useFetchCurrentMap, getGeometryMetadata } from "./apiCalls";
import useMapInitialization from "./useMapInitialization";
import reducer from "./reducer";
import useLoadGeometries from "./useLoadGeometries";

import {
  makeBoundsABitBigger,
  useUrlLocationSearch,
  getIdFromUrl,
  getCoordsFromUrl,
  getMapPrefix,
} from "../utils";
import { computeRiskScore } from "../MapOverlay/utils";

export const initialReducerState = {
  // activeLayers: ["df_wegvak_full", "df_kruising_full"],
  activeLayers: ["risico_totaal"],
  activeLayersRaster: [],
  activeLayersExternal: [],
  fetchingGeometries: false,
  fetchingGeometriesDone: false,
  errorFetchingGeometries: null,
  geometriesToShow: false,
  mapLoaded: false,
  multipleProjects: false,
  metadataPanel: { open: false, properties: {}, metadata: {} },
  popupData: {},
  propertyFiltersWegvak: [],
  propertyFiltersSplits: [],
  geometryIdFilter: null,
  style: {},
  popup: new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  }),
  isUtrecht: true,
  utrechtSampleNotice: false,
  utrechtSampleNoticeZoom: -1,
  utrechtActiveRisks: [],
  utrechtActiveRisksTabOne: [],
  utrechtActiveIndicators: [],
  utrechtActiveIndicatorsTabOne: [],
  utrechtTop10: false,
  utrechtShowAll: true,
  utrechtFilters: [],
  utrechtFiltersTabOne: [],
  mapKeys: null,
  selectedMapKey: null,
  isAdmin: false,
};

const useMapViewerLogic = () => {
  const { user } = useAuth();
  const { currentMap, getCurrentMap } = useCurrentMap();
  const [state, dispatch] = useReducer(reducer, initialReducerState);
  const firstLoad = useRef(true);
  const url = useUrlLocationSearch();

  const [mapContainerRef, map, bounds] = useMapInitialization(state, dispatch);

  const instances = useInstanceData()?.Instances;

  // get or update the map
  useFetchCurrentMap(getCurrentMap, user, dispatch);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      if (user.mapKeys && currentMap?.id) {
        dispatch({
          type: "SET_MAP_DATA",
          payload: {
            isAdmin: user.isAdmin,
            mapKeys: user.mapKeys,
            selectedMapKey: currentMap.id,
            mapPrefix: getMapPrefix(currentMap.id, instances),
          },
        });
      }
    }
  }, [user.isAdmin, user.mapKeys, currentMap?.id, instances]);

  // get coordinates and id from url and open metadata panel
  useEffect(() => {
    if (url && getIdFromUrl(url) && getCoordsFromUrl(url)) {
      const fetchGeometryMetadata = async () => {
        const geometry = await getGeometryMetadata(
          currentMap.id,
          user.token,
          [getIdFromUrl(url)],
          currentMap.geometryLayers.find(
            (layer) => layer.name === "risico_totaal"
          )?.id
        );

        if (geometry.size > 0) {
          dispatch({
            type: "OPEN_METADATA_PANEL",
            payload: {
              ...geometry.result[0],
              layerName: "risico_totaal",
              coords: [getCoordsFromUrl(url)],
            },
          });

          dispatch({ type: "SET_METADATA_PANEL", payload: geometry.result[0] });
        }
      };
      fetchGeometryMetadata();
    }
  }, [currentMap, url, user.token]);

  // simulate being in Utrecht
  useLayoutEffect(() => {
    if (currentMap && state.isUtrecht) {
      dispatch({
        type: "SET_UTRECHT",
        payload: {
          isUtrecht: true,
          activeLayers: ["risico_totaal"],
          utrechtActiveRisks: currentMap.properties.Whitebox.RiskProfile.map(
            (risk) => risk.Risico_naam
          ),
        },
      });
    }
  }, [currentMap, state.isUtrecht]);

  // get geometry  for tooltip on hover
  useEffect(() => {
    const fetchGeometryMetadata = async () => {
      const specialLayers = ["df_wegvak_full", "df_kruising_full"];
      const utrechtSpecialLayers = ["risico_totaal"];

      if (state.popupData.layerName) {
        const geometry = await getGeometryMetadata(
          currentMap.id,
          user.token,
          [state.popupData.id],
          currentMap.geometryLayers.find(
            (layer) => layer.name === state.popupData.layerName
          )?.id
        );
        if (geometry.result.length > 0) {
          if (
            specialLayers.includes(state.popupData.layerName) ||
            (state.isUtrecht && utrechtSpecialLayers.includes(state.popupData.layerName))
          ) {
            let popupInner;
            if (state.isUtrecht) {
              const riskTotalObject = computeRiskScore(
                geometry.result[0],
                currentMap.properties.Whitebox,
                state.utrechtActiveRisks,
                state.utrechtActiveIndicators
              );
              popupInner = `
                <span>${geometry.result[0].straatnaam}</span>
                <span>${geometry.result[0].wegtype}</span>
                <span>Aanwezige risico's: </span>
                ${riskTotalObject?.risks?.length > 0 &&
                  riskTotalObject?.risks[0]?.name
                  ? `<span> - ${riskTotalObject.risks[0]?.name}</span>`
                  : ""
                }
                ${riskTotalObject?.risks?.length > 1 &&
                  riskTotalObject?.risks[1]?.name
                  ? `<span> - ${riskTotalObject.risks[1]?.name}</span>`
                  : ""
                }
                `;
            } else {
              popupInner = `
              <span>${geometry.result[0].straatnaam}</span>
              <span>Risicoscore: ${geometry.result[0]["risk_score"]}</span>
              <span>Ongevallen: ${geometry.result[0]["aantal_ongevallen_alle_jaren"]}</span>
              <span>Meldingen: ${geometry.result[0]["aantal_subjectieve_meldingen"]}</span>
              `;
            }
            state.popup.setHTML(popupInner);
          } else {
            state.popup.setHTML(geometry.result[0].tooltip);
          }
        } else {
          state.popup.setHTML('');
        }
      }
    };
    if (state.popupData.id && !firstLoad.current && !!currentMap) {
      fetchGeometryMetadata();
    }
  }, [
    state.popupData.id,
    currentMap,
    user.token,
    state.popup,
    state.popupData.layerName,
    state.isUtrecht,
    state.utrechtActiveRisks,
    state.utrechtActiveIndicators,
  ]);

  // get geometry metadata for side panel on click
  useEffect(() => {
    const fetchGeometryMetadata = async () => {
      if (state.metadataPanel.properties.layerName) {
        const geometry = await getGeometryMetadata(
          currentMap.id,
          user.token,
          [state.metadataPanel.properties.id],
          currentMap.geometryLayers.find(
            (layer) => layer.name === state.metadataPanel.properties.layerName
          )?.id
        );
        dispatch({ type: "SET_METADATA_PANEL", payload: geometry.result[0] });
      }
    };
    if (
      state.metadataPanel.properties.id &&
      !firstLoad.current &&
      !!currentMap
    ) {
      fetchGeometryMetadata();
    }
  }, [
    currentMap,
    state.metadataPanel.properties.id,
    state.metadataPanel.properties.layerName,
    user.token,
  ]);
  // console.log(bounds);
  // console.log(makeBoundsABitBigger(bounds));
  const bitBiggerBounds = useMemo(() => makeBoundsABitBigger(bounds), [bounds]);

  useLoadGeometries({
    bounds: bitBiggerBounds,
    state,
    currentMap,
    dispatch,
    map,
    user,
  });

  return [mapContainerRef, map, state, dispatch, currentMap];
};

export default useMapViewerLogic;
