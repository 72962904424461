import React, { forwardRef, useState, useCallback, useMemo } from "react";

import MaterialTable from "material-table";
import PropTypes from "prop-types";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Comment from "@material-ui/icons/Comment";
import { useTranslation } from 'react-i18next';
import _ from "lodash";

import { useAuth, useCurrentMap } from "../../../hooks";
import AddCommentDialog from "./AddCommentDialog";
import { editMessageById } from "../useMapViewerLogic/apiCalls";

const tableIcons = {
  /* eslint-disable react/display-name */
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Comment: forwardRef((props, ref) => <Comment {...props} ref={ref} />),
};

const PropertiesTable = ({ metadata, message, layerId, getMessages }) => {
  const { currentMap } = useCurrentMap();
  const { user } = useAuth();
  const labels = currentMap?.properties.Whitebox.DetailTab;
  const {t} = useTranslation()
  const comments = useMemo(
    () => (message?.text ? JSON.parse(message?.text)?.comments ?? {} : {}),
    [message?.text]
  );

  const [dialogOpen, setDialogOpen] = useState({
    open: false,
    key: null,
    comment: null,
  });

  const handleDialogClose = () => {
    setDialogOpen((prev) => ({ ...prev, open: false }));
  };

  const handleSubmitEditComment = useCallback(
    async (key, comment) => {
      let newMessage;
      const newComments = { ...comments, [key]: comment };
      if (message && !_.isEmpty(JSON.parse(message.text))) {
        newMessage = {
          ...JSON.parse(message.text),
          comments: newComments,
        };
      } else {
        newMessage = { comments: newComments };
      }

      console.log("submitting with newMessage:", newMessage);

      const res = await editMessageById({
        mapId: currentMap.id,
        layerId,
        token: user.token,
        geometryId: metadata.id,
        oldMessageId: message?.id,
        newMessage,
      });

      if (res) {
        getMessages(currentMap.id, layerId, metadata.id);
      }
    },
    [
      comments,
      currentMap.id,
      getMessages,
      layerId,
      message,
      metadata.id,
      user.token,
    ]
  );

  const keyLabelMapping = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(metadata).flatMap((key) => {
          const label = labels.find(
            (item) => item.Variabele_naam?.toLowerCase() === key.toLowerCase()
          )?.Beschrijving;
          return label ? [[key, label]] : [];
        })
      ),
    [labels, metadata]
  );

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: t("Beschrijving"), field: "label" },
          { title: t("Bron"), field: "bron" },
          { title: t("Waarde"), field: "value" },
          { title: t("comments"), field: "comment" },
        ]}
        data={Object.keys(metadata).flatMap((key) =>
          keyLabelMapping[key]
            ? [
                {
                  label: keyLabelMapping[key],
                  bron:
                    labels.find((item) => item.Variabele_naam === key)?.Bron ??
                    "",
                  value: metadata[key],
                  comment: comments[key] ?? "",
                },
              ]
            : []
        )}
        icons={tableIcons}
        actions={[
          {
            icon: Comment,
            tooltip: "Add comment",
            onClick: (event, rowData) => {
              setDialogOpen({
                open: true,
                rowKey: Object.keys(keyLabelMapping).find(
                  (key) => keyLabelMapping[key] === rowData.label
                ),
                comment: rowData.comment,
              });
            },
          },
        ]}
        title="Data"
        options={{
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          actionsColumnIndex: -1,
        }}
        localization={{ toolbar: { searchPlaceholder: t("Zoeken") } }}
      />
      <AddCommentDialog
        {...dialogOpen}
        handleClose={handleDialogClose}
        handleSubmitEditComment={handleSubmitEditComment}
      />
    </div>
  );
};

PropertiesTable.propTypes = {
  metadata: PropTypes.object.isRequired,
  message: PropTypes.object,
  layerId: PropTypes.string,
  getMessages: PropTypes.func.isRequired,
};

export default PropertiesTable;
