import React, { useContext, createContext } from "react";

import PropTypes from "prop-types";

import ApiManager from "../ApiManager";
import useLocalStorage from "./useLocalStorage";
import useInstanceData from "../hooks/useInstanceData";
import { getMapKeys } from './utils'

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useLocalStorage("user", null);
  const [currentMap, setCurrentMap] = useLocalStorage("currentMap");

  const data = useInstanceData();

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (username, password, cb) => {
    try {
      const { token } = await ApiManager.post("/account/login", {
        username,
        password,
      });
      const userInfo = await ApiManager.get("/account/info", null, { token });
      const userProfile = await ApiManager.post(
        "/settings/account/getProfile",
        { username: userInfo.username },
        { token }
      );

      const isAdmin = data.AdminUsernames.includes(userInfo.username);
      const mapKeys = getMapKeys(userInfo.username, data, data.Instances);
      setUser({ ...userInfo, ...userProfile, token, isAdmin, mapKeys });
      cb();
      return user;

    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const signout = () => {
    console.log('signing out', currentMap?.name)
    setUser(false);
    setCurrentMap(null)
  };

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
}
