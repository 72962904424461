import React from "react";

import { makeStyles, Paper, Typography, TextField } from "@material-ui/core";
import PropTypes from "prop-types";

import dokDataLogo from "../../img/logo-dok-darkgrey.png";
import ellipsisLogo from "../../img/ellipsis-logo.png";
import useLoginLogic from "./useLoginLogic";
import { LoadingButton } from "../core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      paddingTop: "5%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10%",
    },
  },
  container: {
    height: "500px",
    width: "900px",
    display: "grid",
    gridGap: "50px",
    gridTemplateColumns: "1fr 400px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "calc((100% - 500px) / 2)",
      marginRight: "calc((100% - 500px) / 2)",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "10%",
      marginLeft: "10%",
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [
    username,
    handleUsernameChange,
    password,
    handlePasswordChange,
    handleSubmit,
    loading,
    error,
  ] = useLoginLogic();

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={0}>
        <LogoContainer />
        <LoginFormContainer
          username={username}
          handleUsernameChange={handleUsernameChange}
          password={password}
          handlePasswordChange={handlePasswordChange}
          handleSubmit={handleSubmit}
          loading={loading}
          error={error}
        />
      </Paper>
    </div>
  );
};

const useLogoContainerStyles = makeStyles((theme) => ({
  logoContainer: {
    backgroundColor: "#353a42",
    display: "grid",
    gridTemplateRows: "1fr auto",
    alignItems: "center",
    borderRadius: "8px",
  },
  dokDataLogo: {
    width: "calc(100% - 60px)",
    marginLeft: "30px",
    marginRight: "30px",
  },
  ellipsisContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  ellipsisLogo: {
    height: "28px",
    marginRight: "14px",
  },
  poweredText: {
    color: "rgba(255,255,255,0.6)",
  },
}));

const LogoContainer = () => {
  const classes = useLogoContainerStyles();
  return (
    <div className={classes.logoContainer}>
      <img
        alt="dokData-logo"
        src={dokDataLogo}
        className={classes.dokDataLogo}
      />
      <div className={classes.ellipsisContainer}>
        <img
          alt="ellipsis-drive-logo"
          src={ellipsisLogo}
          className={classes.ellipsisLogo}
        />
        <Typography className={classes.poweredText}>
          powered by Ellipsis Drive
        </Typography>
      </div>
    </div>
  );
};

const useLoginFormContainerStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "300px",
    padding: "20px",
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

const LoginFormContainer = ({
  username,
  handleUsernameChange,
  password,
  handlePasswordChange,
  handleSubmit,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  const classes = useLoginFormContainerStyles();
  return (
    <div className={classes.container}>
      <form className={classes.formContainer} onSubmit={handleSubmit}>
        <Typography variant="h4">{t('login')}</Typography>
        <TextField
          label={t('Username')}
          value={username}
          onChange={handleUsernameChange}
          variant="outlined"
          type="text"
        />
        <TextField
          label={t('Password')}
          value={password}
          onChange={handlePasswordChange}
          variant="outlined"
          type="password"
        />
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
        >
          {t('submit')}
        </LoadingButton>
        <Typography><>{t("Contact")}: <a href="mailto:info@dokdata.nl">info@dokdata.nl</a></></Typography>
        {error ? (
          <Typography className={classes.errorText}>{error}</Typography>
        ) : null}
      </form>
    </div>
  );
};

LoginFormContainer.propTypes = {
  username: PropTypes.string.isRequired,
  handleUsernameChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default Login;
