import React, { useMemo, useEffect, useState } from "react";

import {
  FormControlLabel,
  makeStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Accordion,
  AccordionSummary,
  // AccordionDetails,
  Checkbox,
  Switch,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Help } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import { addAdditionalLayer } from "../../useMapViewerLogic/mapLayers"; //addCircleLayer, addLineLayer, 

import { useCurrentMap, useAuth } from "../../../../hooks";
import NumericalFilter from "./NumericalFilter";
import useFilterLogic from "./useFilterLogic";

/*additional layers
Three options for layers:
- Normal additional layer added to ellipsis mapid (additionalLayers: currentMap layers)
- Additional raster layers (additionalLayersRaster: currentMap coupled layers)
- WFS external layers --> (additionelLayersExternal: currentMap external layers)

Distinction additional (leftpanel tab1) and data (leftpanel tab2):
- WFS is always data
- All other data layer names start with 'data_'
*/

const useFiltersTabStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "100%",
    overflow: "scroll",
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
    boxSizing: "border-box",
  },
  filtersTitle: {
    fontWeight: "800",
    marginBottom: "10px",
  },
  filtersSection: {
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: "20px",
    marginBottom: "10px",
    "&:last-child": {
      paddingBottom: "150px",
    },
  },
  filtersSectionTitle: {
    width: '100%',
    fontWeight: "800",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  checkboxGroup: {
    paddingBottom: "20px",
  },
  formControl: {
    width: "100%",
    marginTop: "20px",
  },
  checkbox: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  checkbox_i: {
    paddingLeft: "20px",
    paddingRight: "0px",
    paddingBottom: "20px",
  },
  tooltip: {
    fontSize: "0.85rem",
  },
  risicoHelp: {
    padding: 0,
    marginLeft: "5px",
  },
  risicoLabel: {
    minWidth: "180px",
    display: "flex",
    flexDirection: "center",
    alignItems: "right",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  accordionRoot: {
    padding: "0px",
  },
}));


/* ************************* TAB 0 ************************* */
const UtrechtFiltersTab = ({ style: currentStyle, dispatch, state, map }) => {
  const classes = useFiltersTabStyles();
  const { t } = useTranslation();
  const { currentMap } = useCurrentMap();
  const { user } = useAuth();

  const filtersInit = [
    {
      name: "intensiteit_float",
      label: t("Verkeersintensiteit (mvt/etmaal)"),
      filterType: "slider",
      minValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterTrafficIntensity?.intensiteit
          ?.Laag,
      maxValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterTrafficIntensity?.intensiteit
          ?.Hoog,
    },
    {
      name: "intensiteit_fiets_float",
      label: t("Fietsintensiteit (fietsers/etmaal)"),
      filterType: "slider",
      minValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterBikeIntensity
          ?.intensiteit_fiets_float.Laag,
      maxValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterBikeIntensity
          ?.intensiteit_fiets_float.Hoog,
    },
    {
      name: "V_OpenOV_busint_etm",
      label: t("Busintensiteit"),
      filterType: "slider",
      minValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterBusIntensity
          ?.V_OpenOV_busint_etm?.Laag,
      maxValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterBusIntensity
          ?.V_OpenOV_busint_etm?.Hoog,
    },
    {
      name: "aantal_ongevallen_alle_jaren_float",
      label: t("Ongevallen alle jaren"),
      filterType: "slider",
      minValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterAccidents
          ?.aantal_ongevallen_alle_jaren_float.Laag,
      maxValue:
        currentMap &&
        currentMap.properties.General[0]?.FilterAccidents
          ?.aantal_ongevallen_alle_jaren_float.Hoog,
    },
  ];
  const filters = currentMap.properties.General[0]?.FilterFietsnet ?
    filtersInit.concat(
      {
        name: "fietsnet",
        label: t("Fietsnet"),
        filterType: "checkbox",
        minValue:
          currentMap &&
          currentMap.properties.General[0]?.FilterFietsnet
            ?.fietsnet.Nee,
        maxValue:
          currentMap &&
          currentMap.properties.General[0]?.FilterFietsnet
            ?.fietsnet.Ja,
      },
    ) : filtersInit
  delete window.fitersInit;

  useFilterLogic(state, dispatch, filters);

  const [
    isFilterOn,
    filterValue,
    handleToggleFilter,
    handleFilterSliderChange,
    handleFilterInputChange,
  ] = useFilterLogic(state, dispatch, filters);

  const { geometryLayers } = currentMap;

  let additionalLayers = useMemo(
    () =>
      geometryLayers.flatMap((layer) =>
        (layer.name !== "risico_totaal") && (!layer.name.toLowerCase().startsWith('data_')) ? [layer] : []
      ),
    [geometryLayers]
  );
  additionalLayers = additionalLayers.sort((a, b) => a.name.localeCompare(b.name))

  let additionalLayersRaster = useMemo(
    () =>
      currentMap?.coupledLayers !== undefined &&
      currentMap?.coupledLayers?.flatMap((layer) =>
        !layer.layer.toLowerCase().startsWith('data_') ? [layer] : []
      ),
    [currentMap]
  );
  additionalLayersRaster = currentMap?.coupledLayers !== undefined && additionalLayersRaster.sort((a, b) => a.layer.localeCompare(b.layer))
  //raster description API call
  const [additionalLayersRasterDesc, setAdditionalLayersRasterDesc] = useState([]);
  useEffect(() => {
    if (additionalLayersRaster.map(a => a.layer).length > 0) {
      let tmpArr = additionalLayersRaster.map(a => a.layer)
      //get pathIdsRaster of selected additional raster layers
      let pathIdsRaster = []
      for (var i = 0; i < currentMap?.coupledLayers?.length; i++) {
        if (tmpArr.includes(currentMap?.coupledLayers[i].layer)) {
          pathIdsRaster[i] = { 'layerName': currentMap?.coupledLayers[i].layer, 'pathId': currentMap?.coupledLayers[i].mapId }
        }
      }
      pathIdsRaster = pathIdsRaster.flat()
      // console.log(pathIdsRaster)
      setAdditionalLayersRasterDesc([]) //reset state
      tmpArr.forEach(input => {
        let g = pathIdsRaster.filter(obj => {
          return obj.layerName === input; //tmpArr[l]
        });
        g = g[0]
        fetch('https://api.ellipsis-drive.com/v2/path/' + g.pathId, {
          method: "GET",
          headers: { "Authorization": `Bearer ` + user.token }
        }).then(res => res.json()).then(json => {
          setAdditionalLayersRasterDesc(oldArray => [...oldArray, { "name": json.raster?.layers[0].name, "description": json.raster?.timestamps[0].description }]);
        });
      })
    }
  }, [currentMap, user, additionalLayersRaster]);
  //additionalLayersRaster = additionalLayersRaster.map(v => ({ ...v, description: null }))

  try {
    additionalLayersRasterDesc.forEach(d => {
      additionalLayersRaster[additionalLayersRaster.findIndex((obj => obj.layer === d.name))].description = d.description
    })
  } catch (err) {
    //console.log(err)
  }

  let additionalLayersExternal = useMemo(
    () =>
      currentMap?.properties?.General[0].ExternalLayers !== undefined &&
      currentMap?.properties?.General[0].ExternalLayers?.flatMap((layer) =>
        !layer.name.toLowerCase().startsWith('data_') ? [layer] : []
      ),
    [currentMap]
  );
  additionalLayersExternal = currentMap?.properties?.General[0].ExternalLayers !== undefined && additionalLayersExternal.sort((a, b) => a.name.localeCompare(b.name))

  // console.log(additionalLayers) //name
  // console.log(additionalLayersRaster) //layer (label) + name
  // console.log(additionalLayersExternal) //layer (label) + name
  const handleAdditionalLayerChange = (e) => {
    dispatch({
      type: "SET_ACTIVE_LAYERS",
      payload: e.target.checked
        ? [...state.activeLayers, e.target.name]
        : state.activeLayers.filter((layer) => layer !== e.target.name),
    });
    if (!e.target.checked) {
      map.getSource(e.target.name).setData({
        type: "FeatureCollection",
        features: [],
      });
    }
  };
  const handleAdditionalLayerChangeRaster = (e) => {
    // console.log(e.target.name)
    // console.log(currentMap?.coupledLayers)
    let tLayer = ''
    for (var i = 0; i < currentMap?.coupledLayers?.length; i++) {
      //console.log(currentMap?.coupledLayers[i].name)
      if (currentMap?.coupledLayers[i].name === e.target.name) {
        tLayer = currentMap?.coupledLayers[i].layer
      }
    }
    dispatch({
      type: "SET_ACTIVE_LAYERS_RASTER",
      payload: e.target.checked
        ? [...state.activeLayersRaster, tLayer]
        : state.activeLayersRaster.filter((layer) => layer !== tLayer),
    });
    if (!e.target.checked) {
      map.removeLayer(e.target.name + '-raster');
      map.removeSource(e.target.name);
    } else {
      additionalLayersRaster.filter(n => n.name === e.target.name).map(filteredLayer => (
        map.addSource(e.target.name, {
          'type': 'raster',
          'tiles': [
            'https://api.ellipsis-drive.com/v2/path/' + filteredLayer.mapId + '/raster/timestamp/' + filteredLayer.timestamps[0].id + '/tile/{z}/{x}/{y}?layer=' + filteredLayer.layerId + '&token=' + user.token
          ],
          'tileSize': 128
        })
      ))
      addAdditionalLayer(
        map,
        dispatch,
        e.target.name,
        null,
        null,
        state.isUtrecht && e.target.name === "risico_totaal",
        'raster',
        []
      );
    }
  };

  const handleAdditionalLayerChangeExternal = (e) => {
    // console.log(e.target.name)
    // console.log(currentMap?.externalLayers)
    let tLayer = ''
    for (var i = 0; i < currentMap?.properties?.General[0].ExternalLayers?.length; i++) {
      //console.log(currentMap?.coupledLayers[i].name)
      if (currentMap?.properties?.General[0].ExternalLayers[i].name === e.target.name) {
        tLayer = currentMap?.properties?.General[0].ExternalLayers[i].layer
      }
    }
    dispatch({
      type: "SET_ACTIVE_LAYERS_EXTERNAL",
      payload: e.target.checked
        ? [...state.activeLayersExternal, tLayer]
        : state.activeLayersExternal.filter((layer) => layer !== tLayer),
    });
    if (!e.target.checked) {
      map.removeLayer(e.target.name + '-external');
      map.removeSource(e.target.name);
    } else {
      additionalLayersExternal.filter(n => n.name === e.target.name).map(filteredLayer => (
        map.addSource(e.target.name, {
          'type': 'raster',
          //'tiles': [filteredLayer.url + '?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=landelijke-fietsroutes&CRS=EPSG%3A3857&STYLES=&WIDTH=1750&HEIGHT=1158&BBOX={bbox-epsg-3857}'],
          'tiles': [filteredLayer.url],
          //'tileSize': 256
        })
      ))
      addAdditionalLayer(
        map,
        dispatch,
        e.target.name,
        null,
        null,
        state.isUtrecht && e.target.name === "risico_totaal",
        'external',
        []
      );
    }
  };

  const handleRisicoChange = (event) => {
    if (event.target.checked) {
      // add risk
      dispatch({
        type: "SET_UTRECHT_RISKS",
        payload: [...state.utrechtActiveRisks, event.target.name],
      });
      // get corresponding indicator(s) for risk
      let inds = []
      currentMap.properties.Whitebox.RiskProfile.forEach(function (risico) {
        if (risico["Risico_naam"] === event.target.name) {
          risico.Indicatoren.forEach(function (indicator) {
            inds.push(indicator.Indicator_naam)
          })
        }
      })
      // add indicators if not already in state
      let stateNew = [...state.utrechtActiveIndicators]
      inds.forEach(function (indicator) {
        if (!state.utrechtActiveIndicators.includes(indicator)) {
          stateNew.push(indicator)
        }
      })
      dispatch({
        type: "SET_UTRECHT_INDICATORS",
        payload: stateNew,
      });
    } else {
      // remove risk
      dispatch({
        type: "SET_UTRECHT_RISKS",
        payload: state.utrechtActiveRisks.filter(
          (risk) => risk !== event.target.name
        ),
      });
      // get corresponding indicator(s) for risk
      let inds = []
      currentMap.properties.Whitebox.RiskProfile.forEach(function (risico) {
        if (risico["Risico_naam"] === event.target.name) {
          risico.Indicatoren.forEach(function (indicator) {
            inds.push(indicator.Indicator_naam)
          })
        }
      })
      // remove indicator(s)
      dispatch({
        type: "SET_UTRECHT_INDICATORS",
        payload: state.utrechtActiveIndicators.filter(
          (indicators) => !inds.includes(indicators)
        ),
      });
    }
  };

  const handleIndicatorChange = (event) => {
    if (event.target.checked) {
      // add indicator
      dispatch({
        type: "SET_UTRECHT_INDICATORS",
        payload: [...state.utrechtActiveIndicators, event.target.name],
      });
      // get corresponding risk for indicator
      let r = ''
      currentMap.properties.Whitebox.RiskProfile.forEach(function (risico) {
        risico.Indicatoren.forEach(function (indicator) {
          //console.log(indicator.Indicator_naam);
          if (indicator.Indicator_naam === event.target.name) {
            r = risico.Risico_naam
          }
        })
      })
      // add risk if not already in state
      if (!state.utrechtActiveRisks.includes(r)) {
        dispatch({
          type: "SET_UTRECHT_RISKS",
          payload: [...state.utrechtActiveRisks, r],
        });
      }
    } else {
      // remove indicator
      dispatch({
        type: "SET_UTRECHT_INDICATORS",
        payload: state.utrechtActiveIndicators.filter(
          (risk) => risk !== event.target.name
        ),
      });
    }
  };

  // const handleTop10Change = (event) => {
  //   if (event.target.checked) {
  //     // set top10
  //     dispatch({
  //       type: "SET_UTRECHT_TOP10",
  //       payload: true,
  //     });
  //     document.getElementById("hideButtonLeftPanel").click();
  //   } else {
  //     // remove top10
  //     dispatch({
  //       type: "SET_UTRECHT_TOP10",
  //       payload: false,
  //     });
  //   }
  // };

  const handleWegtypeFilterChange = (event) => {
    if (
      event.target.value === "" &&
      state.utrechtFilters.find((filter) => filter.key === "wegtype")
    ) {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: state.utrechtFilters.filter(
          (filter) => filter.key !== "wegtype"
        ),
      });
    } else if (
      state.utrechtFilters.find((filter) => filter.key === "wegtype")
    ) {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: state.utrechtFilters.map((filter) =>
          filter.key === "wegtype"
            ? { key: "wegtype", value: event.target.value, operator: "=" }
            : filter
        ),
      });
    } else {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: [
          ...state.utrechtFilters,
          { key: "wegtype", value: event.target.value, operator: "=" },
        ],
      });
    }
  };
  const handleRoadManagerFilter = (event) => {
    if (
      event.target.value === "" &&
      state.utrechtFilters.find((filter) => filter.key === "wegbeheerder")
    ) {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: state.utrechtFilters.filter(
          (filter) => filter.key !== "wegbeheerder"
        ),
      });
    } else if (
      state.utrechtFilters.find((filter) => filter.key === "wegbeheerder")
    ) {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: state.utrechtFilters.map((filter) =>
          filter.key === "wegbeheerder"
            ? { key: "wegbeheerder", value: event.target.value, operator: "=" }
            : filter
        ),
      });
    } else {
      dispatch({
        type: "SET_UTRECHT_FILTERS",
        payload: [
          ...state.utrechtFilters,
          { key: "wegbeheerder", value: event.target.value, operator: "=" },
        ],
      });
    }
  };

  const risks = useMemo(
    () => currentMap.properties.Whitebox.RiskProfile,
    [currentMap]
  );
  const indicators = useMemo(
    () => {
      const ind = []
      currentMap.properties.Whitebox.RiskProfile.forEach(function (risico) {
        risico.Indicatoren.forEach(function (indicator) {
          //console.log(indicator.Indicator_naam);
          ind.push(indicator.Indicator_naam)
        })
      })
      return ind
    },
    [currentMap]
  );

  const isAllSelected = risks.length > 0 && state.utrechtActiveRisks.length === risks.length;
  const handleSelectAll = () => {
    dispatch({
      type: "SET_UTRECHT_RISKS",
      payload: isAllSelected ? [] : risks.map(r => r.Risico_naam),
    });
    dispatch({
      type: "SET_UTRECHT_INDICATORS",
      payload: isAllSelected ? [] : indicators,
    });
  }
  const road_types = Object.values(currentMap?.properties.General[0]?.FilterRoadType.wegtype).filter(t => !t.includes('jte - ')) || [];
  const junction_types = Object.values(currentMap?.properties.General[0]?.FilterRoadType.wegtype).filter(t => t.includes('jte - ')).map(t => t.replace('jte - ', '')) || [];
  const roadManagerFilter = currentMap?.properties.General[0]?.FilterRoadManager.wegbeheerder;

  // const clientName = currentMap?.properties.General[0].DashboardURL
  // const clientNamePrepped = clientName.split('client').pop().replace(/[^a-zA-Z_]/gi, '')
  // const selectVoorspelmodelOnly = clientNamePrepped === 'gem_rotterdam' ? true : false
  const selectVoorspelmodelOnly = currentMap?.properties.General[0].Risico_aan_onload && currentMap?.properties.General[0].Risico_aan_onload.length === 1 && currentMap.properties.General[0].Risico_aan_onload[0].toLowerCase().includes('voorspelmodel') ? true : false

  const index = risks.map(r => r.Risico_beschrijving).findIndex(element => {
    return element.toLowerCase().indexOf("voorspelmodel") !== -1;
  });
  const riskVoorspelmodel = selectVoorspelmodelOnly ? risks[index].Risico_naam : ''
  useEffect(() => {
    dispatch({
      type: "SET_UTRECHT_RISKS",
      payload: selectVoorspelmodelOnly ? [riskVoorspelmodel] : risks.map(r => r.Risico_naam),
    });
    dispatch({
      type: "SET_UTRECHT_INDICATORS",
      payload: indicators,
    });
  }, [selectVoorspelmodelOnly, riskVoorspelmodel, risks, indicators, dispatch]);

  // console.log(currentMap)
  // console.log(state.activeLayers)
  // console.log(state.activeLayersRaster)
  // console.log(state.utrechtFilters)
  // console.log(additionalLayers)
  // currentMap.properties.Whitebox.RiskProfile.forEach(function (risico) {
  //   console.log(risico.Risico_naam);
  //   risico.Indicatoren.forEach(function (indicator) {
  //     console.log(indicator.Indicator_naam);
  //   })
  // })

  const [accordionActiveIndex, setAccordionActiveIndex] = useState(null);
  const handleAccordionChange = index => {
    setAccordionActiveIndex(accordionActiveIndex === index ? null : index);
  };
  return (
    <div className={classes.container}>
      <FormControl component="fieldset" className={classes.filtersSection}>
        <FormLabel component="legend" className={classes.filtersSectionTitle} style={{ marginBottom: -10 }}>
          {t("Risico's")}
          <Tooltip
            interactive
            arrow
            title={t("Risico_uitleg")}
            classes={{ tooltip: classes.tooltip }}
          >
            <Help color="primary" className={classes.risicoHelp} />
          </Tooltip>
          <FormControlLabel style={{ 'whiteSpace': 'nowrap', 'paddingLeft': '35px' }}
            className={classes.checkbox}
            control={
              <Checkbox
                checked={isAllSelected}
                disabled={state.fetchingGeometries}
                value="select-all-checkbox"
                indeterminate={
                  state.utrechtActiveRisks.length > 0 && state.utrechtActiveRisks.length < risks.length
                }
                className={classes.radio}
                onChange={handleSelectAll}
                name={'select-all'}
              />
            }
            classes={{ label: classes.risicoLabel }}
            label={isAllSelected ? t("Deselect all") : t("Select all")}
          />
        </FormLabel>
        <FormGroup
          aria-label="risico-indicatoren"
          name="risicoIndicatoren"
          className={classes.checkboxGroup}
        >
          {currentMap.properties.Whitebox.RiskProfile.map((risico, index) => (
            <>
              <Accordion
                elevation={0}
                square
                expanded={accordionActiveIndex === index ? true : false}
                classes={{ root: classes.accordionRoot }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon
                    onClick={() => handleAccordionChange(index)}
                  />}
                  aria-controls={"panel-content" + risico.Risico_naam}
                  id={"panel-header" + risico.Risico_naam}
                >
                  < FormControlLabel
                    className={classes.checkbox}
                    key={risico.Risico_naam}
                    control={
                      < Checkbox
                        checked={state.utrechtActiveRisks.includes(risico.Risico_naam)}
                        disabled={state.fetchingGeometries}
                        className={classes.radio}
                        onChange={handleRisicoChange}
                        name={risico.Risico_naam}
                      />
                    }
                    classes={{ label: classes.risicoLabel }}
                    label={
                      <>
                        {risico.Risico_beschrijving}
                        {risico.Risico_help && (
                          <Tooltip
                            interactive
                            arrow
                            title={risico.Risico_help}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Help color="primary" className={classes.risicoHelp} />
                          </Tooltip>
                        )}
                      </>
                    }
                  />
                </AccordionSummary>

                {risico.Indicatoren.map((indicator) => (
                  < FormControlLabel
                    className={classes.checkbox_i}
                    key={indicator.Indicator_naam}
                    control={
                      < Checkbox
                        size="small"
                        checked={state.utrechtActiveIndicators.includes(indicator.Indicator_naam)}
                        disabled={state.fetchingGeometries}
                        className={classes.radio}
                        onChange={handleIndicatorChange}
                        name={indicator.Indicator_naam}
                      />
                    }
                    classes={{ label: classes.risicoLabel }}
                    label={
                      <>
                        {indicator.Indicator_beschrijving}
                        {indicator.Indicator_help && (
                          <Tooltip
                            interactive
                            arrow
                            title={indicator.Indicator_help}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Help color="primary" className={classes.risicoHelp} />
                          </Tooltip>
                        )}
                      </>
                    }
                  />
                ))
                }

              </Accordion>
            </>

          ))
          }
        </FormGroup>
      </FormControl>

      {
        (additionalLayers?.length > 0 || additionalLayersRaster?.length > 0 || additionalLayersExternal?.length > 0) && (
          <FormControl component="fieldset" className={classes.filtersSection}>
            <FormLabel component="legend" className={classes.filtersSectionTitle}>
              {t("Additionele lagen")}
              <Tooltip
                interactive
                arrow
                title={t("Additioneel_uitleg")}
                classes={{ tooltip: classes.tooltip }}
              >
                <Help color="primary" className={classes.risicoHelp} />
              </Tooltip>
            </FormLabel>
            <FormGroup className={classes.checkboxGroup}>
              {additionalLayers.map((layer) => (
                <FormControlLabel
                  className={classes.checkbox}
                  key={layer.id}
                  control={
                    < Checkbox
                      checked={state.activeLayers.indexOf(layer.name) >= 0}
                      disabled={state.fetchingGeometries}
                      onChange={handleAdditionalLayerChange}
                      name={layer.name}
                    />
                  }
                  classes={{ label: classes.risicoLabel }}
                  label={
                    <>
                      {layer.name}
                      {layer.description && (
                        <Tooltip
                          interactive
                          arrow
                          title={layer.description}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Help color="primary" className={classes.risicoHelp} />
                        </Tooltip>
                      )}
                    </>
                  }
                />
              ))}
              {currentMap?.coupledLayers !== undefined && additionalLayersRaster.map((layer) => (
                <FormControlLabel
                  className={classes.checkbox}
                  key={layer.name}
                  control={
                    <Checkbox
                      checked={state.activeLayersRaster.indexOf(layer.layer) >= 0}
                      disabled={state.fetchingGeometries}
                      onChange={handleAdditionalLayerChangeRaster}
                      name={layer.name}
                    />
                  }
                  classes={{ label: classes.risicoLabel }}
                  label={
                    <>
                      {layer.layer}
                      {layer.description && (
                        <Tooltip
                          interactive
                          arrow
                          title={layer.description}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Help color="primary" className={classes.risicoHelp} />
                        </Tooltip>
                      )}
                    </>
                  }
                />
              ))}
              {currentMap?.properties?.General[0].ExternalLayers !== undefined && additionalLayersExternal.map((layer) => (
                <FormControlLabel
                  className={classes.checkbox}
                  key={layer.name}
                  control={
                    <Checkbox
                      checked={state.activeLayersExternal.indexOf(layer.layer) >= 0}
                      disabled={state.fetchingGeometries}
                      onChange={handleAdditionalLayerChangeExternal}
                      name={layer.name}
                    />
                  }
                  classes={{ label: classes.risicoLabel }}
                  label={
                    <>
                      {layer.layer}
                      {layer.description && (
                        <Tooltip
                          interactive
                          arrow
                          title={layer.description}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Help color="primary" className={classes.risicoHelp} />
                        </Tooltip>
                      )}
                    </>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        )
      }

      <section className={classes.filtersSection}>
        <FormLabel component="legend" className={classes.filtersSectionTitle}>
          {t("Filters")}
          <Tooltip
            interactive
            arrow
            title={t("Filter_uitleg")}
            classes={{ tooltip: classes.tooltip }}
          >
            <Help color="primary" className={classes.risicoHelp} />
          </Tooltip>
        </FormLabel>
        <FormGroup className={classes.checkboxGroup}>
          {/* <FormControlLabel style={{ marginBottom: -10 }}
            // className={classes.checkbox}
            key='top_10'
            control={
              <Checkbox
                id='checkboxTop10'
                checked={state.utrechtTop10}
                disabled={state.fetchingGeometries}
                className={classes.radio}
                onChange={handleTop10Change}
                name='top_10_filter'
              />
            }
            classes={{ label: classes.risicoLabel }}
            label={
              <>
                {'Top 10'}
                <Tooltip
                  interactive
                  arrow
                  title={t("top10_help")}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Help color="primary" className={classes.risicoHelp} />
                </Tooltip>
              </>
            }
          /> */}

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="wegtype-input-label">
              {t("Wegtype")}
            </InputLabel>
            <Select
              labelId="wegtype-label"
              //multiple
              id="wegtype"
              //altijd disabled tenzij...
              disabled={
                (state.fetchingGeometries === false) && (
                  (state.utrechtFilters.length === 0) || //geen filters = enabled
                  (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") === 'undefined')) || //niet geselecteerd maar 1 andere wel = enabled
                  (state.utrechtFilters.length >= 1 && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) //(als enige) geselecteerd is combifilter = enabled
                )
                  ? false : true
              }
              value={
                state.utrechtFilters.find((filter) => filter.key === "wegtype")
                  ?.value ?? ""
              }
              onChange={handleWegtypeFilterChange}
              label={t("Wegtype")}
            >
              <MenuItem value="">
                <em>{t("No_filters")}</em>
              </MenuItem>
              <MenuItem value={t("None")}>
                <em>{t("None")}</em>
              </MenuItem>
              <ListSubheader>{t("Type wegen")}</ListSubheader>
              {road_types.map(
                (key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                )
              )}
              <ListSubheader>{junction_types.length >= 1 ? t("Type kruispunten") : ''}</ListSubheader>
              {junction_types.map(
                (key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl} style={{ marginBottom: 10 }}>
            <InputLabel id="wegbeheerder-input-label">
              {t("Wegbeheerder")}
            </InputLabel>
            <Select
              labelId="wegbeheerder-label"
              id="wegbeheerder"
              //altijd disabled tenzij...
              disabled={
                (state.fetchingGeometries === false) && (
                  (state.utrechtFilters.length === 0) || //geen filters = enabled
                  (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === "wegbeheerder") !== 'undefined')) || //als enige geselecteerd = enabled
                  (state.utrechtFilters.length === 2 && (typeof state.utrechtFilters.find((filter) => filter.key === "wegbeheerder") !== 'undefined') && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) || //met wegtype als enige geselecteerd = enabled
                  (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === "wegbeheerder") === 'undefined') && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) //niet geselecteerd maar wegtype wel als enige is combifilter = enabled
                )
                  ? false : true
              }
              value={state.utrechtFilters.find((filter) => filter.key === "wegbeheerder")?.value ?? ""}
              onChange={handleRoadManagerFilter}
              label={t("Wegbeheerder")}
            >
              <MenuItem value="">
                <em>{t("No_filters")}</em>
              </MenuItem>
              {Object.keys(roadManagerFilter).map(
                (key) => (
                  <MenuItem key={key} value={roadManagerFilter[key]}>
                    {key} - {roadManagerFilter[key]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          {filters.map(({ name, label, filterType, minValue, maxValue }) => (
            filterType === 'slider' ?
              <NumericalFilter
                className={classes.checkbox}
                key={name}
                //altijd disabled tenzij...
                disabled={
                  (state.fetchingGeometries === false) && (
                    (state.utrechtFilters.length === 0) || //geen filters = enabled
                    (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === name) !== 'undefined')) || //als enige geselecteerd = enabled
                    (state.utrechtFilters.length === 2 && (typeof state.utrechtFilters.find((filter) => filter.key === name) !== 'undefined') && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) || //met wegtype als enige geselecteerd = enabled
                    (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === name) === 'undefined') && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) //niet geselecteerd maar wegtype wel als enige is combifilter = enabled
                  )
                    ? false : true
                }
                isFilterOn={isFilterOn[name]}
                handleToggleFilter={(event) => {
                  handleToggleFilter(name, event.target.checked);
                }}
                filterValue={filterValue[name]}
                handleFilterValueSliderChange={(event, newValue) =>
                  handleFilterSliderChange(name, newValue)
                }
                handleFilterValueInputChange={(event) =>
                  handleFilterInputChange(name, event.target.value)
                }
                filterMin={minValue}
                filterMax={maxValue}
                classes={classes.risicoLabel}
                label={label}
              /> :
              <FormControlLabel
                className={classes.checkbox}
                key={name}
                control={
                  <Checkbox
                    checked={isFilterOn[name]}
                    //altijd disabled tenzij...
                    disabled={
                      (state.fetchingGeometries === false) && (
                        (state.utrechtFilters.length === 0) || //geen filters = enabled
                        (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === name) !== 'undefined')) || //als enige geselecteerd = enabled
                        (state.utrechtFilters.length === 2 && (typeof state.utrechtFilters.find((filter) => filter.key === name) !== 'undefined') && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) || //met wegtype als enige geselecteerd = enabled
                        (state.utrechtFilters.length === 1 && (typeof state.utrechtFilters.find((filter) => filter.key === name) === 'undefined') && (typeof state.utrechtFilters.find((filter) => filter.key === "wegtype") !== 'undefined')) //niet geselecteerd maar wegtype wel als enige is combifilter = enabled
                      )
                        ? false : true
                    }
                    onChange={(event) => {
                      handleToggleFilter(name, event.target.checked);
                    }}
                  />
                }
                classes={{ label: classes.risicoLabel }}
                label={label}
              />
          ))}
        </FormGroup>
      </section>
    </div >
  );
};

UtrechtFiltersTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  style: PropTypes.object,
  map: PropTypes.object,
  state: PropTypes.shape({
    activeLayers: PropTypes.arrayOf(PropTypes.string),
    activeLayersRaster: PropTypes.arrayOf(PropTypes.string),
    activeLayersExternal: PropTypes.arrayOf(PropTypes.string),
    utrechtActiveRisks: PropTypes.arrayOf(PropTypes.string).isRequired,
    utrechtActiveIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
    utrechtFilters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        operator: PropTypes.string,
      })
    ).isRequired,
  }),
};


/* ************************* TAB 1 ************************* */
// basically the same as the additional layers in the UtrechtFilterTab, except data layers are selected
const UtrechtDataTab = ({ style: currentStyle, dispatch, state, map }) => {
  const classes = useFiltersTabStyles();
  const { t } = useTranslation();
  const { currentMap } = useCurrentMap();
  const { user } = useAuth();

  const { geometryLayers } = currentMap;
  let additionalDataLayers = useMemo(
    () =>
      geometryLayers.flatMap((layer) =>
        (layer.name !== "risico_totaal") && (layer.name.toLowerCase().startsWith('data_')) ? [layer] : []
      ),
    [geometryLayers]
  );
  additionalDataLayers = additionalDataLayers.sort((a, b) => a.name.localeCompare(b.name))

  let additionalDataLayersRaster = useMemo(
    () =>
      currentMap?.coupledLayers !== undefined &&
      currentMap?.coupledLayers?.flatMap((layer) =>
        layer.layer.toLowerCase().startsWith('data_') ? [layer] : []
      ),
    [currentMap]
  );
  additionalDataLayersRaster = currentMap?.coupledLayers !== undefined && additionalDataLayersRaster.sort((a, b) => a.layer.localeCompare(b.layer))
  //data raster description API call
  const [additionalDataLayersRasterDesc, setAdditionalDataLayersRasterDesc] = useState([]);
  useEffect(() => {
    if (additionalDataLayersRaster.map(a => a.layer).length > 0) {
      let tmpArr = additionalDataLayersRaster.map(a => a.layer)
      //get pathIdsRaster of selected additional raster layers
      let pathIdsRaster = []
      for (var i = 0; i < currentMap?.coupledLayers?.length; i++) {
        if (tmpArr.includes(currentMap?.coupledLayers[i].layer)) {
          pathIdsRaster[i] = { 'layerName': currentMap?.coupledLayers[i].layer, 'pathId': currentMap?.coupledLayers[i].mapId }
        }
      }
      pathIdsRaster = pathIdsRaster.flat()
      // console.log(pathIdsRaster)
      setAdditionalDataLayersRasterDesc([]) //reset state
      tmpArr.forEach(input => {
        let g = pathIdsRaster.filter(obj => {
          return obj.layerName === input; //tmpArr[l]
        });
        g = g[0]
        fetch('https://api.ellipsis-drive.com/v2/path/' + g.pathId, {
          method: "GET",
          headers: { "Authorization": `Bearer ` + user.token }
        }).then(res => res.json()).then(json => {
          setAdditionalDataLayersRasterDesc(oldArray => [...oldArray, { "name": json.raster?.layers[0].name, "description": json.raster?.timestamps[0].description }]);
        });
      })
    }
  }, [currentMap, user, additionalDataLayersRaster]);
  //additionalLayersRaster = additionalLayersRaster.map(v => ({ ...v, description: null }))
  additionalDataLayersRasterDesc.forEach(d => {
    additionalDataLayersRaster[additionalDataLayersRaster.findIndex((obj => obj.layer === d.name))].description = d.description
  })

  let additionalDataLayersExternal = useMemo(
    () =>
      currentMap?.properties?.General[0].ExternalLayers !== undefined &&
      currentMap?.properties?.General[0].ExternalLayers?.flatMap((layer) =>
        layer.name.toLowerCase().startsWith('data_') ? [layer] : []
      ),
    [currentMap]
  );
  additionalDataLayersExternal = currentMap?.properties?.General[0].ExternalLayers !== undefined && additionalDataLayersExternal.sort((a, b) => a.name.localeCompare(b.name))
  // console.log(additionalDataLayers) //name (with data_)
  // console.log(additionalDataLayersRaster) //layer (label, with data_) + name
  // console.log(additionalDataLayersExternal) //layer (label) + name (with data_)
  const [count, setCount] = useState(0);
  const handleAdditionalDataLayerChange = (e) => {
    dispatch({
      type: "SET_ACTIVE_LAYERS",
      payload: e.target.checked
        ? [...state.activeLayers, e.target.name]
        : state.activeLayers.filter((layer) => layer !== e.target.name),
    });
    if (!e.target.checked) {
      map.getSource(e.target.name).setData({
        type: "FeatureCollection",
        features: [],
      });
    }
    setCount(count + 1)
  };
  //activeLayers reset once (bug fietsvoorzieningen laat risico_totaal grijs zien --> duplicate state.activeLayes inladen en daarna ontdubbelen gaat goed)
  useEffect(() => {
    if (count === 1) {
      let activeLayers = state.activeLayers.filter(function (x) { return x !== "risico_totaal"; })
      activeLayers.forEach((l) => {
        if (state.fetchingGeometriesDone === true) {
          setCount(count + 1)
          dispatch({
            type: "SET_ACTIVE_LAYERS",
            payload: state.activeLayers.filter((layer) => layer !== l),
          });
          dispatch({
            type: "SET_ACTIVE_LAYERS",
            payload: [...state.activeLayers, l, l], //double add
          });
        }
      });
    }
    if (count === 2) {
      let activeLayers = state.activeLayers.filter(function (x) { return x !== "risico_totaal"; })
      activeLayers.forEach((l) => {
        if (state.fetchingGeometriesDone === true) {
          setCount(count + 1)
          dispatch({
            type: "SET_ACTIVE_LAYERS",
            payload: [...new Set([...state.activeLayers])], //single remove duplicates
          });
        }
      });
    }
    //console.log(state.activeLayers)
  }, [dispatch, state.activeLayers, state.fetchingGeometriesDone, count]);

  const handleAdditionalDataLayerChangeRaster = (e) => {
    // console.log(e.target.name)
    // console.log(currentMap?.coupledLayers)
    let tLayer = ''
    for (var i = 0; i < currentMap?.coupledLayers?.length; i++) {
      //console.log(currentMap?.coupledLayers[i].name)
      if (currentMap?.coupledLayers[i].name === e.target.name) {
        tLayer = currentMap?.coupledLayers[i].layer
      }
    }
    dispatch({
      type: "SET_ACTIVE_LAYERS_RASTER",
      payload: e.target.checked
        ? [...state.activeLayersRaster, tLayer]
        : state.activeLayersRaster.filter((layer) => layer !== tLayer),
    });
    if (!e.target.checked) {
      map.removeLayer(e.target.name + '-raster');
      map.removeSource(e.target.name);
    } else {
      additionalDataLayersRaster.filter(n => n.name === e.target.name).map(filteredLayer => (
        map.addSource(e.target.name, {
          'type': 'raster',
          'tiles': [
            'https://api.ellipsis-drive.com/v2/path/' + filteredLayer.mapId + '/raster/timestamp/' + filteredLayer.timestamps[0].id + '/tile/{z}/{x}/{y}?layer=' + filteredLayer.layerId + '&token=' + user.token
          ],
          'tileSize': 128
        })
      ))
      addAdditionalLayer(
        map,
        dispatch,
        e.target.name,
        null,
        null,
        state.isUtrecht && e.target.name === "risico_totaal",
        'raster',
        []
      );
    }
    // reset to ensure rasterLayer is shown
    let activeLayers = state.activeLayers.filter(function (x) { return x !== "risico_totaal"; })
    activeLayers.forEach((l) => {
      if (state.fetchingGeometriesDone === true) {
        dispatch({
          type: "SET_ACTIVE_LAYERS",
          payload: [...new Set([...state.activeLayers])],
        });
      }
    });
  };

  const handleAdditionalDataLayerChangeExternal = (e) => {
    // console.log(e.target.name)
    // console.log(currentMap?.externalLayers)
    let tLayer = ''
    for (var i = 0; i < currentMap?.properties?.General[0].ExternalLayers?.length; i++) {
      //console.log(currentMap?.coupledLayers[i].name)
      if (currentMap?.properties?.General[0].ExternalLayers[i].name === e.target.name) {
        tLayer = currentMap?.properties?.General[0].ExternalLayers[i].layer
      }
    }
    dispatch({
      type: "SET_ACTIVE_LAYERS_EXTERNAL",
      payload: e.target.checked
        ? [...state.activeLayersExternal, tLayer]
        : state.activeLayersExternal.filter((layer) => layer !== tLayer),
    });
    if (!e.target.checked) {
      map.removeLayer(e.target.name + '-external');
      map.removeSource(e.target.name);
    } else {
      additionalDataLayersExternal.filter(n => n.name === e.target.name).map(filteredLayer => (
        map.addSource(e.target.name, {
          'type': 'raster',
          //'tiles': [filteredLayer.url + '?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&layers=landelijke-fietsroutes&CRS=EPSG%3A3857&STYLES=&WIDTH=1750&HEIGHT=1158&BBOX={bbox-epsg-3857}'],
          'tiles': [filteredLayer.url],
          //'tileSize': 256
        })
      ))
      addAdditionalLayer(
        map,
        dispatch,
        e.target.name,
        null,
        null,
        state.isUtrecht && e.target.name === "risico_totaal",
        'external',
        []
      );
    }
    // reset to ensure externalLayer is shown
    let activeLayers = state.activeLayers.filter(function (x) { return x !== "risico_totaal"; })
    activeLayers.forEach((l) => {
      if (state.fetchingGeometriesDone === true) {
        dispatch({
          type: "SET_ACTIVE_LAYERS",
          payload: [...new Set([...state.activeLayers])],
        });
      }
    });
  };

  const handleShowAll = (e) => {
    if (e.target.checked === true) {
      // opnieuw aanvinken na uitgezet = state.utrechtFiltersTabOne
      if (state.utrechtFilters.find((filter) => filter.key === "wegtype")) {
        // wegtype filter verwijderen uit filterlijst
        // dispatch({
        //   type: "SET_UTRECHT_FILTERS",
        //   payload: state.utrechtFilters.filter(
        //     (filter) => filter.key !== "wegtype"
        //   ),
        // });
        // filterlijst (incl wegtype) vervangen met state.utrechtFiltersTabOne
        dispatch({
          type: "SET_UTRECHT_FILTERS",
          payload: state.utrechtFiltersTabOne.map((filter) =>
            filter
          ),
        });
      }
    } else {
      if (state.utrechtFilters.find((filter) => filter.key === "wegtype")) {
        // wegtype filter vervangen in filterlijst (t("None") is alles verbergen want geen bestaand wegtype)
        dispatch({
          type: "SET_UTRECHT_FILTERS",
          payload: state.utrechtFilters.map((filter) =>
            filter.key === "wegtype"
              ? { key: "wegtype", value: t("None"), operator: "=" }
              : filter
          ),
        });
      } else {
        //wegtype filter toevoegen aan filterlijst (t("None") is alles verbergen want geen bestaand wegtype)
        dispatch({
          type: "SET_UTRECHT_FILTERS",
          payload: [
            ...state.utrechtFilters,
            { key: "wegtype", value: t("None"), operator: "=" },
          ],
        });
      }
    }
    if (e.target.checked === true) {
      dispatch({
        type: "SET_UTRECHT_SHOW_ALL",
        payload: true,
      });
    } else {
      dispatch({
        type: "SET_UTRECHT_SHOW_ALL",
        payload: false,
      });
    }
  }
  // console.log(currentMap)
  // console.log(state.activeLayers)
  // console.log(state.activeLayersRaster)
  // console.log(state.utrechtShowAll)
  // console.log(additionalDataLayers)
  return (
    <div className={classes.container}>
      <FormControl component="fieldset" className={classes.filtersSection} style={{ 'paddingBottom': '10px' }}>
        <FormLabel component="legend" className={classes.filtersSectionTitle} style={{ 'paddingTop': '0px' }}>
          {t("Datalagen")}
          <Tooltip
            interactive
            arrow
            title={t("Data_uitleg")}
            classes={{ tooltip: classes.tooltip }}
          >
            <Help color="primary" className={classes.risicoHelp} />
          </Tooltip>
          <FormControlLabel style={{ 'whiteSpace': 'nowrap', 'paddingLeft': '35px' }}
            className={classes.checkbox}
            control={
              <Switch
                checked={state.utrechtShowAll}
                disabled={state.fetchingGeometries}
                value="show-all-switch"
                className={classes.radio}
                onChange={handleShowAll}
                name={'show-all'}
              />
            }
            classes={{ label: classes.risicoLabel }}
            label={t("Show all")}
          />
        </FormLabel>
        {(additionalDataLayers?.length > 0 || additionalDataLayersRaster?.length > 0 || additionalDataLayersExternal?.length > 0) && (
          <FormGroup className={classes.checkboxGroup}>
            {additionalDataLayers.map((layer) => (
              <FormControlLabel
                className={classes.checkbox}
                key={layer.id}
                control={
                  <Checkbox
                    checked={state.activeLayers.indexOf(layer.name) >= 0}
                    disabled={state.fetchingGeometries}
                    onChange={handleAdditionalDataLayerChange}
                    name={layer.name}
                  />
                }
                classes={{ label: classes.risicoLabel }}
                label={
                  <>
                    {layer.name.slice(5)}
                    {layer.description && (
                      <Tooltip
                        interactive
                        arrow
                        title={layer.description}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Help color="primary" className={classes.risicoHelp} />
                      </Tooltip>
                    )}
                  </>
                }
              />
            ))}
            {currentMap?.coupledLayers !== undefined && additionalDataLayersRaster.map((layer) => (
              <FormControlLabel
                className={classes.checkbox}
                key={layer.name}
                control={
                  <Checkbox
                    checked={state.activeLayersRaster.indexOf(layer.layer) >= 0}
                    disabled={state.fetchingGeometries}
                    onChange={handleAdditionalDataLayerChangeRaster}
                    name={layer.name}
                  />
                }
                classes={{ label: classes.risicoLabel }}
                label={
                  <>
                    {layer.layer.slice(5)}
                    {layer.description && (
                      <Tooltip
                        interactive
                        arrow
                        title={layer.description}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Help color="primary" className={classes.risicoHelp} />
                      </Tooltip>
                    )}
                  </>
                }
              />
            ))}
            {currentMap?.properties?.General[0].ExternalLayers !== undefined && additionalDataLayersExternal.map((layer) => (
              <FormControlLabel
                className={classes.checkbox}
                key={layer.name}
                control={
                  <Checkbox
                    checked={state.activeLayersExternal.indexOf(layer.layer) >= 0}
                    disabled={state.fetchingGeometries}
                    onChange={handleAdditionalDataLayerChangeExternal}
                    name={layer.name}
                  />
                }
                classes={{ label: classes.risicoLabel }}
                label={
                  <>
                    {layer.layer}
                    {layer.description && (
                      <Tooltip
                        interactive
                        arrow
                        title={layer.description}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Help color="primary" className={classes.risicoHelp} />
                      </Tooltip>
                    )}
                  </>
                }
              />
            ))}
          </FormGroup>
        )}
      </FormControl>
    </div >
  );
};

UtrechtDataTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  style: PropTypes.object,
  map: PropTypes.object,
  state: PropTypes.shape({
    activeLayers: PropTypes.arrayOf(PropTypes.string),
    activeLayersRaster: PropTypes.arrayOf(PropTypes.string),
    activeLayersExternal: PropTypes.arrayOf(PropTypes.string),
  }),
};

export { UtrechtFiltersTab, UtrechtDataTab };
