import React, { useContext, createContext, useCallback } from "react";
// import { head } from 'lodash';
import PropTypes from "prop-types";

import ApiManager from "../ApiManager";
import useLocalStorage from "./useLocalStorage";
import { useAuth } from "./useAuth";
import { getCurrentID, getMapKeys } from '../hooks/utils';
import useInstanceData from "../hooks/useInstanceData";
const currentMapContext = createContext();

// Provider component that wraps your app and makes currentMap object ...
// ... available to any child component that calls useCurrentMap().
export function ProvideCurrentMap({ children }) {
  const currentMap = useProvideCurrentMap();

  return (
    <currentMapContext.Provider value={currentMap}>
      {children}
    </currentMapContext.Provider>
  );
}

ProvideCurrentMap.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook for child components to get the currentMap object ...
// ... and re-render when it changes.
export const useCurrentMap = () => {
  return useContext(currentMapContext);
};

// Provider hook that creates currentMap object and handles state
function useProvideCurrentMap() {
  const [currentMap, setCurrentMap] = useLocalStorage("currentMap", null);
  const { signout, user } = useAuth();
  const data = useInstanceData();
  //const delay = ms => new Promise(res => setTimeout(res, ms));

  const getCurrentMap = useCallback(
    async ({ userToken, callback, shape = null }) => {
      //check if currentMap.id is in instancedata to solve cache refresh problem if new mapid in instancedata is set and logged in user opens app
      let keys = getMapKeys(user.username, data, data.Instances)
      if (keys) {
        keys = keys.map(obj => obj.id)
        if (currentMap?.id !== undefined && keys.includes(currentMap?.id) === false) {
          console.log('refresh');
          //await delay(15000);
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload(true);
        }
      }

      const mapKey = currentMap?.id || getCurrentID(user.username, data, data.Instances);
      try {
        const shapeMetadata = await ApiManager.post(
          "/metadata",
          {
            mapId: mapKey,
            includeDeleted: false,
            stylesPerLayer: true
          },
          { token: userToken }
        );

        //add bounds extent data api call
        // const layerId = shapeMetadata.geometryLayers.filter(layer => layer.name === 'risico_totaal').map(layer => layer.id);
        // const boundsData = await ApiManager.post(
        //   "/settings/projects/bounds",
        //   {
        //     mapId: mapKey,
        //     layerId: head(layerId),
        //   },
        //   { token: userToken }
        // );
        // console.log(boundsData);
        // const extent = {
        //   xMin: boundsData?.bbox[0],
        //   xMax: boundsData?.bbox[2],
        //   yMin: boundsData?.bbox[1],
        //   yMax: boundsData?.bbox[3],
        // }
        const extent = shapeMetadata.geometryLayers.find(layer => layer.name === 'risico_totaal').extent

        setCurrentMap({ ...shape, ...shapeMetadata, extent, id: mapKey });
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
        signout();
        return err;
      }
    },
    [currentMap?.id, user?.username, setCurrentMap, signout, data]
  );

  // Return the currentMap object and currentMap methods
  return {
    currentMap,
    getCurrentMap,
  };
}
