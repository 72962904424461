import React, { forwardRef } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  childrenContainer: {
    opacity: (props) => (props.loading ? 0 : 1),
  },
  spinnerContainer: {
    opacity: (props) => (props.loading ? 1 : 0),
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-12.5px, -12.5px)",
  },
  spinner: {
    color: "inherit",
  },
});

const LoadingButton = forwardRef(
  ({ children, className = "", loading = false, ...buttonProps }, ref) => {
    const classes = useStyles({ loading });
    return (
      <Button
        {...buttonProps}
        className={clsx(classes.button, className)}
        ref={ref}
      >
        <div className={classes.childrenContainer}>{children}</div>
        <div className={classes.spinnerContainer}>
          <CircularProgress
            style={{ height: "25px", width: "25px" }}
            className={classes.spinner}
          />
        </div>
      </Button>
    );
  }
);

LoadingButton.displayName = "LoadingButton";

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default LoadingButton;
