import FileSaver from "file-saver";
const apiUrl = "https://api.ellipsis-drive.com/v1";
// const apiUrl = "https://dev.api.ellipsis-drive.com/v1";

const ApiManager = {
  adminUserName: "admin",
  apiUrl: apiUrl,

  accessLevels: {
    viewMap: 100,
    download: 150,
    aggregatedData: 200,
    timeSeries: 210,
    viewGeoMessages: 300,
    addGeoMessages: 400,
    addGeoMessageImage: 410,
    addSeries: 415,
    addPrivateGeoMessage: 420,
    addPolygons: 500,
    submitRasterData: 515,
    addRestrictedPolygons: 525,
    viewPrivateGeoMessages: 550,
    deleteGeomessages: 600,
    alterOrDeleteCustomPolygons: 700,
    forms: 750,
    customPolygonLayers: 800,
    rerun: 850,
    userManagement: 900,
    owner: 1000,

    mapPublicLevelOne: 300, // viewGeoMessages
    mapPublicLevelTwo: 500, // addPolygons

    min: 0,
    max: 1000,
  },

  get: (url, body, user) => {
    return apiManagerFetch("GET", url, body, user);
  },

  post: (url, body, user, isDownload) => {
    return apiManagerFetch("POST", url, body, user, isDownload);
  },

  fetch: (method, url, body, user) => {
    return apiManagerFetch(method, url, body, user);
  },

  upload: (url, file, user, events, body) => {
    let formData = new FormData();

    formData.append("upload", file);

    if (body) {
      let keys = Object.keys(body);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        formData.append(key, body[key]);
      }
    }

    let request = new XMLHttpRequest();

    if (events) {
      request.addEventListener("load", (e) => {
        let response = e.currentTarget.response;
        if (e.currentTarget.status === 200) {
          response = { status: 200, message: response };
        } else {
          response = JSON.parse(response);
        }

        if (events.load) {
          events.load(response);
        }
      });
      request.upload.addEventListener("progress", events.progress);
      request.addEventListener("error", (e) => {
        if (events.error) {
          events.error(e);
        }
      });
      request.addEventListener("abort", events.abort);
    }

    request.open("POST", `${apiUrl}${url}`, true);

    if (user) {
      request.setRequestHeader("Authorization", `Bearer ${user.token}`);
    }

    request.send(formData);

    return request;
  },
};

function CustomError(status, message) {
  var error = Error.call(this, message);

  this.name = "API Error";
  this.message = error.message;
  this.stack = error.stack;
  this.status = status;
}

CustomError.prototype = Object.create(Error.prototype);
CustomError.prototype.constructor = CustomError;

async function apiManagerFetch(method, url, body, user, isDownload) {
  let headers = {};
  let urlAddition = "";

  // headers["Access-Control-Allow-Origin"] = "*";
  headers["Content-Type"] = "application/json";

  if (user) {
    headers["Authorization"] = `Bearer ${user.token}`;
    if (user.mapId) {
      urlAddition = `?mapId=${user.mapId}`;
    }
  }

  url = `${apiUrl}${url}${urlAddition}`;
  let gottenResponse = null;
  let isText = false;
  let isJson = false;

  let options = {
    method: method,
    headers: headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return await fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 429) {
          alert(
            `You made too many calls to this map and won't be able to use it for another minute. Contact the owner of this map to give you more bandwidth.`
          );
        }
      }

      gottenResponse = response;

      let contentType = response.headers.get("Content-Type");

      if (contentType) {
        isText = contentType.includes("text");
        isJson = contentType.includes("application/json");
      } else {
        isText = true;
      }

      if (isJson) {
        return response.json();
      } else if (isText) {
        return response.text();
      } else {
        return response.blob();
      }
    })
    .then((result) => {
      if (gottenResponse.status === 200) {
        if (isDownload) {
          let contentDisposition = gottenResponse.headers.get(
            "Content-Disposition"
          );
          let matches =
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/.exec(
              contentDisposition
            );
          let fileName = matches[1];
          FileSaver.saveAs(result, fileName);
        } else {
          return result;
        }
      } else {
        if (!isText) {
          throw new CustomError(gottenResponse.status, result.message);
        } else {
          throw new CustomError(gottenResponse.status, result);
        }
      }
    });
}

export default ApiManager;
