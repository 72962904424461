import React from "react";

import { makeStyles, Typography, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

import OverlayWrapper from "./OverlayWrapper";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  overlay: {
    left: "460px",
    top: "23px",
    [theme.breakpoints.down("xs")]: {
      left: "20px",
      top: "170px",
    },
  },
  container: {
    backgroundColor: "rgba(0,0,0,0.6)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 10px",
    borderRadius: "6px",
  },
  text: {
    color: "white",
    maxWidth: "230px",
  },
  spinner: {
    color: "white",
    height: "20px !important",
    width: "20px !important",
    marginRight: "10px",
  },
}));

const GeometryNotice = ({ geometriesToShow, loadingGeometries, utrechtShowAll }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  return (
    <OverlayWrapper style={{ zIndex: 399 }} className={classes.overlay}>
      {loadingGeometries ? (
        <div className={classes.container}>
          <CircularProgress className={classes.spinner} />
          <Typography className={classes.text}>
            {t("Loading geometries")}...
          </Typography>
        </div>
      ) : (!geometriesToShow) && (utrechtShowAll === true) ? (
        <div className={classes.container}>
          <Typography className={classes.text}>
            {t("Geen wegvakken gevonden, zoom verder in of verander je filters")}
          </Typography>
        </div>
      ) : null}
    </OverlayWrapper>
  );
};

GeometryNotice.propTypes = {
  geometriesToShow: PropTypes.bool.isRequired,
  loadingGeometries: PropTypes.bool.isRequired,
  utrechtShowAll: PropTypes.bool.isRequired
};

export default GeometryNotice;
