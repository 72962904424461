import React, { useState } from "react";

import { Typography, Collapse, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, Help } from "@material-ui/icons";
import PropTypes from "prop-types";
import clsx from "clsx";

import {
  Button,
} from "@material-ui/core";

import CustomProgressBar from "./CustomProgressBar";
import { useTranslation } from 'react-i18next';
import ShapValsDialog from "../GeometryMetadataDrawer/ShapValsDialog";

const useStyles = makeStyles((theme) => ({
  riskTitleContainer: {
    cursor: "pointer",
    width: "100%",
    padding: "2px 5px",
    // paddingBottom: "0px",
    boxSizing: "border-box",
    borderRadius: "4px",
    display: "grid",
    gridTemplateColumns: "21px 1fr 100px",
    gap: "5px",
    alignItems: "center",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  arrow: {
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform"),
    "&$expanded": {
      transform: "rotate(0deg)",
    },
  },
  indicatorContainer: {
    marginLeft: "10px",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  indicatorTitleContainer: {
    cursor: "pointer",
    width: "100%",
    padding: "5px",
    boxSizing: "border-box",
    borderRadius: "4px",
    display: "grid",
    gridTemplateColumns: "21px 1fr 40px",
    gap: "5px",
    alignItems: "center",
    marginTop: "2px",
    marginLeft: "5px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  indicatorNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  indicatorHelp: {
    padding: 0,
    marginLeft: "5px",
  },
  indicatorValue: {
    textAlign: "right",
  },
  variableContainer: {
    marginLeft: "15px",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  variableTitleContainer: {
    width: "100%",
    padding: "5px",
    boxSizing: "border-box",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  variableValue: {
    marginLeft: "10px",
    maxWidth: "fit-content",
  },
  greyed: {
    color: theme.palette.text.disabled,
  },
  tooltip: {
    fontSize: "0.85rem",
  },
  expanded: {},
}));

const RiskLine = ({ risk, locationKey, clientName, mapId }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={classes.riskTitleContainer}
        onClick={() => setOpen((prev) => !prev)}
      >
        <KeyboardArrowDown
          className={clsx(open && classes.expanded, classes.arrow)}
        />
        <Typography>{risk.name}</Typography>
        <Tooltip
          interactive
          arrow
          title={`Risicoscore ${Number(
            risk.scoreTotal
          ).toFixed(2)} * weging ${Number(risk.weight).toFixed(2)} = bijdrage aan risicoprofiel ${Number(risk.scoreTotal * risk.weight).toFixed(2)}`}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <CustomProgressBar
            variant="determinate"
            value={100 * risk.scoreTotal}
          />
        </Tooltip>
      </div>
      <Collapse in={open}>
        <div className={classes.indicatorContainer}>
          {risk.indicators.map((indicator) => (
            <IndicatorLine indicator={indicator} btn_shap={risk.name.toLowerCase().includes('voorspelmodel') ? true : false} locationKey={locationKey} clientName={clientName} key={indicator.name} mapId={mapId} />
          ))}
        </div>
      </Collapse>
    </>
  );
};

RiskLine.propTypes = {
  risk: PropTypes.shape({
    name: PropTypes.string.isRequired,
    scoreTotal: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    indicators: PropTypes.array.isRequired,
  }).isRequired,
  locationKey: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  mapId: PropTypes.string.isRequired,
};

const IndicatorLine = ({ indicator, btn_shap, locationKey, clientName, mapId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openShapValsDialog, setOpenShapValsDialog] = useState(false);

  return (
    <>
      <div
        className={classes.indicatorTitleContainer}
        onClick={() => setOpen((prev) => !prev)}
      >
        <KeyboardArrowDown
          className={clsx(open && classes.expanded, classes.arrow)}
        />
        <div className={classes.indicatorNameContainer}>
          <Typography>{indicator.name}</Typography>
          {indicator.help && (
            <Tooltip
              interactive
              arrow
              title={indicator.help}
              classes={{ tooltip: classes.tooltip }}
            >
              <Help color="primary" className={classes.indicatorHelp} />
            </Tooltip>
          )}
        </div>
        <Tooltip
          interactive
          arrow
          title={`Indicatorscore ${Number(
            indicator.scoreTotal
          ).toFixed(2)} * weging ${Number(indicator.weight).toFixed(2)} = bijdrage aan risicoscore ${Number(indicator.scoreTotal * indicator.weight).toFixed(2)}`}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Typography color="textSecondary" className={classes.indicatorValue}>
            {Number(indicator.scoreTotal)?.toFixed(2)}
          </Typography>
        </Tooltip>
      </div>
      <Collapse in={open}>
        <div className={classes.variableContainer}>
          {indicator.variables.map((variable) => (
            <Variable key={variable.name} variable={variable} />
          ))}
          <div style={{ 'paddingLeft': '8px', 'paddingTop': '8px' }}>
            {
              btn_shap && (
                <div className={classes.indicatorNameContainer}>
                  <Button
                    className={classes.messageButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenShapValsDialog(true)}
                  >
                    {t("Bekijk grafiek")}
                  </Button>
                  <Tooltip
                    interactive
                    arrow
                    title={t("SHAP help")}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Help
                      color="disabled"
                      className={classes.variableHelp}
                    />
                  </Tooltip>
                </div>
              )
            }
          </div>
        </div>
      </Collapse>
      {
        btn_shap && (
          <ShapValsDialog
            open={openShapValsDialog}
            locationKey={locationKey} //'189269023_2020_wegvak'
            clientName={clientName} //{JSON.parse(message.text)?.Message}
            mapId={mapId}
            handleClose={() => setOpenShapValsDialog(false)}
          />
        )
      }
    </>
  );
};

IndicatorLine.propTypes = {
  indicator: PropTypes.shape({
    name: PropTypes.string.isRequired,
    variables: PropTypes.array.isRequired,
    scoreTotal: PropTypes.number.isRequired,
    help: PropTypes.string,
    mapId: PropTypes.string.isRequired,
  }).isRequired,
};

const Variable = ({ variable }) => {
  const classes = useStyles();

  return (
    <div className={classes.variableTitleContainer}>
      <div className={classes.indicatorNameContainer}>
        <Typography className={variable.italic ? classes.greyed : ""}>
          {variable.name}
        </Typography>
        {variable.help && (
          <Tooltip
            interactive
            arrow
            title={variable.help}
            classes={{ tooltip: classes.tooltip }}
          >
            <Help
              color={variable.italic ? "disabled" : "action"}
              className={classes.variableHelp}
            />
          </Tooltip>
        )}
      </div>
      <Typography
        className={clsx(
          variable.italic && classes.greyed,
          classes.variableValue
        )}
        color="textSecondary"
      >
        {variable.value}{" "}
        {variable.addition !== null && `(${variable.addition})`}
      </Typography>
    </div>
  );
};

Variable.propTypes = {
  variable: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addition: PropTypes.string,
    italic: PropTypes.bool,
    help: PropTypes.string,
  }).isRequired,
};

export default RiskLine;
