import React from "react";

import PropTypes from "prop-types";

const OverlayWrapper = ({
  children,
  className = "",
  style: userStyles = {},
  ...otherProps
}) => {
  const styles = { zIndex: 500, position: "fixed" };

  return (
    <div
      className={className}
      style={{ ...styles, ...userStyles }}
      {...otherProps}
    >
      {children}
    </div>
  );
};

OverlayWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default OverlayWrapper;
