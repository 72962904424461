import React, { useState } from 'react';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from 'react-i18next';

import PropTypes from "prop-types";

import { useWindowDimensions } from "../../../../hooks";
import Iframe from 'react-iframe';
import ClipLoader from "react-spinners/ClipLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ShapValsDialog = ({ open, handleClose, locationKey, clientName, mapId }) => {
  const { t } = useTranslation();

  /* eslint-disable no-unused-vars */
  const { height, width } = useWindowDimensions();
  /* eslint-enable no-unused-vars */

  /* TEMP OLD SHAP IFRAME SRC FOR ALL EXCEPT RDAM DEV FOR NOW */
  let iframeSrc = "https://shaps-api-dot-ongevalrisico.appspot.com/" + clientName + "/?key=" + locationKey
  if (mapId === "071a7fae-e641-416f-8742-5749684bdc9d") {
    // Rotterdam dev map is new limeplot url
    iframeSrc = "https://ongevallen-dot-ongevalrisico.appspot.com/limeplot/?key=" + locationKey + "&client=" + clientName;
  }

  const [loading, setLoading] = useState(true);
  const override = `
    position: absolute;
    z-index: 1000;
    top: 1rem;
    right: 1rem;
  `;

  // avoid loading iframe on rightPanel, iframe should be loaded on dialog btn click
  if (open === false) return (<></>);

  return (
    !!locationKey && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
      // fullScreen="true"
      >
        <DialogTitle id="alert-dialog-slide-title">{t("Verklarende indicatoren (SHAP values)") + locationKey + " " + clientName + ")"}</DialogTitle>
        <DialogContent>
          <ClipLoader color="#000000" loading={loading} css={override} size={35} />
          <Iframe
            src={iframeSrc}
            title={t("Verklarende indicatoren (SHAP values)")}
            height={Math.round(height * 0.8, 0)}
            width="100%"
            scrolling="yes"
            frameBorder={0}
            sandbox="" //insecure: "allow-scripts allow-same-origin"
            onLoad={() => setLoading(false)}
          />
          {/* <TableContainer>
            <Table
              size="small"
              aria-label="simple table"
              style={{ width: "min(100%, fit-content)" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("Melding")}</TableCell>
                  <TableCell align="left">{t("Info")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(content).map((message) => (
                  <TableRow key={message}>
                    <TableCell component="th" scope="row">
                      {message}
                    </TableCell>
                    <TableCell align="left">{content[message]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

ShapValsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  client: PropTypes.string,
  locationKey: PropTypes.string.isRequired,
};

export default ShapValsDialog;
