import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";

import PropertiesTable from "./PropertiesTable";
import StreetView from "./StreetView";
import CycloMedia from "./CycloMedia";
// import { useCurrentMap } from "../../../hooks";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function FullScreenDialogDetails({
  open,
  setOpen,
  metadata,
  coords,
  isUtrecht = false,
  message,
  layerId,
  getMessages,
  streetViewer,
}) {
  const classes = useStyles();
  // const { currentMap } = useCurrentMap();
  const { t } = useTranslation();
  const handleClose = () => {
    if (streetViewer === "cyclo") { //currentMap?.properties.PanoramaSupplier === "Cyclomedia" || isUtrecht
      window.StreetSmartApi.destroy({
        targetElement: document.getElementById("streetsmartApi"),
      });
    }
    setOpen(false);
  };

  const memoizedCoords = useMemo(() => {
    return coords;
  }, [coords]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('Details')}
            </Typography>
          </Toolbar>
        </AppBar>
        {open &&
          (streetViewer === "cyclo" ? (
            <CycloMedia
              coords={memoizedCoords}
              open={open}
              isUtrecht={isUtrecht}
            />
          ) : (
            <StreetView coords={memoizedCoords} />
          ))}
        <PropertiesTable
          metadata={metadata}
          message={message}
          layerId={layerId}
          getMessages={getMessages}
        />
      </Dialog>
    </div>
  );
}

FullScreenDialogDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  coords: PropTypes.arrayOf(PropTypes.number),
  isUtrecht: PropTypes.bool,
  message: PropTypes.shape({
    Message: PropTypes.object,
    comments: PropTypes.object,
  }),
  layerId: PropTypes.string,
  getMessages: PropTypes.func.isRequired,
  streetViewer: PropTypes.string.isRequired,
};

export default FullScreenDialogDetails;
