import { useState, useCallback } from "react";

const useInputState = (defaultValue = null) => {
  const [value, setValue] = useState(defaultValue);

  const handleValueChange = useCallback((e) => setValue(e.target.value), []);

  return [value, handleValueChange, setValue];
};

export default useInputState;
