import { useState, useEffect } from "react";
import instanceDataBackup from '../instanceDataBackup.json';

const useInstanceData = () => {
  const [iData, setIData] = useState({});
  useEffect(() => {
    getInstanceData();
  }, []);
  const getInstanceData = async () => {
    const url = "https://verkeersveiligheidsmeter.nl/road_safety_app/instanceData.php?token=34523081sadf124";
    const response = await fetch(url, { cache: "no-store" });
    const jsonData = await response.json();
    if (jsonData.hasOwnProperty('AdminUsernames')) {
      //console.log('instanceData from verkeersveiligheidsmeter.nl is used');
      setIData(jsonData);
    } else {
      console.log('instanceDataBackup is used instead of instanceData from verkeersveiligheidsmeter.nl');
      setIData(instanceDataBackup);
    }
  };
  return iData;
};

export default useInstanceData;