import React, { useEffect } from "react";

import { Helmet } from "react-helmet";

import { makeStyles } from "@material-ui/core";

import useMapViewerLogic from "./useMapViewerLogic";

import MapOverlay from "./MapOverlay";
import DisclaimerDialog from "./DisclaimerDialog";
import { useLocalStorage } from "../../hooks";
import { useAuth } from "../../hooks";
import { sendGtagUserData } from './utils'

import LoadingDiv from './LoadingDiv.js'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
}));

const Map = () => {
  const classes = useStyles();
  const { user } = useAuth();

  const [disclaimerDialogOpen, setDisclaimerDialogOpen] = useLocalStorage(
    "showDisclaimerDialog",
    true
  );

  const [mapContainerRef, map, state, dispatch, currentMap] =
    useMapViewerLogic();

  const disclaimerText = currentMap?.properties?.General[0].DisclaimerText

  useEffect(() => {
    sendGtagUserData(user)
  }, [user])

  return (
    <>
      <Helmet>
        {/* title + favicon from client config currentMap */}
        <title>{currentMap?.properties.General[0].Title}</title>
        <meta name="description" content={currentMap?.properties.General[0].Title} />
        {currentMap?.properties.General[0].Title === "Ongevalrisico" ? <link rel="shortcut icon" href="/favicon.ico" /> : <link rel="shortcut icon" href="/favicon_verkeersveiligheidsmeter.ico" />}
      </Helmet>
      <div>
        <div className={classes.mapContainer} ref={mapContainerRef} />
        {currentMap && <MapOverlay map={map} state={state} dispatch={dispatch} />}
        <DisclaimerDialog
          open={disclaimerDialogOpen}
          handleClose={() => setDisclaimerDialogOpen(false)}
          disclaimerText={disclaimerText}
        />
        <LoadingDiv />
      </div>
    </>
  );
};

export default Map;
