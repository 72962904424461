import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Loader } from "google-maps";

const options = {
  /* todo */
};
const loader = new Loader(process.env.REACT_APP_GOOGLE_API_KEY, options);

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "min(900px, 80vh)",
    minHeight: "min(900px, 80vh)",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  streetView: {
    width: "100%",
    height: "30%",
  },
  pano: {
    width: "100%",
    height: "70%",
  },
}));

const StreetView = ({ coords }) => {
  const classes = useStyles();
  useEffect(() => {
    const initializeStreetView = async () => {
      const google = await loader.load();

      const center = { lat: coords[0], lng: coords[1] };
      const map = new google.maps.Map(
        document.getElementById("streetViewMap"),
        {
          center,
          zoom: 14,
        }
      );
      const panorama = new google.maps.StreetViewPanorama(
        document.getElementById("streetViewPano"),
        {
          position: center,
          pov: {
            heading: 34,
            pitch: 10,
          },
        }
      );
      map.setStreetView(panorama);
    };
    initializeStreetView();
  }, [coords]);

  return (
    <div className={classes.container}>
      <div id="streetViewMap" className={classes.streetView} />

      <div id="streetViewPano" className={classes.pano} />
    </div>
  );
};

StreetView.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default StreetView;
