import React from "react";

import { makeStyles, Button } from "@material-ui/core";
import PropTypes from "prop-types";

import OverlayWrapper from "../OverlayWrapper";
import { useAuth, useCurrentMap } from "../../../../hooks";
import useInstanceData from "../../../../hooks/useInstanceData";
import {
  useUrlLocationSearch,
  //getMapPrefix,
  getPreselectFilters,
  getIdFromUrl,
  removePreselectFilters,
} from "../../utils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  overlay: {
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    transition: theme.transitions.create(["right"], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("xs")]: {
      transform: "none",
      right: "50px",
      left: "auto",
      bottom: "60px",
    },
  },
  button: {
    width: "fit-content",
  },
  icon: {
    height: "32px",
    width: "32px",
  },
}));

const Dashboard = ({ state }) => {
  const url = useUrlLocationSearch();
  const { user } = useAuth();
  const { t } = useTranslation();
  // const [currentMap] = useLocalStorage("currentMap");
  const { currentMap } = useCurrentMap();
  const classes = useStyles({
    shifted: state.metadataPanel.open,
  });

  const instances = useInstanceData()?.Instances;
  const clientname = instances && Object.keys(instances)
    .filter(i => instances[i].mapID === currentMap?.id)
    .reduce((obj, key) => {
      //obj[key] = instances[key];
      return instances[key];
    }, {}).clientname;
  let dashboard_streamlit = 1
  // if ((clientname === 'prov_friesland') && (process.env.REACT_APP_NODE_ENV === 'development')) {
  //   dashboard_streamlit = 1
  // }
  // if (clientname === 'prov_utrecht') {
  //   dashboard_streamlit = 1
  // }
  // if (clientname === 'prov_gelderland') {
  //   dashboard_streamlit = 1
  // }
  // if (clientname === 'gem_denhaag') {
  //   dashboard_streamlit = 1
  // }
  return (
    <OverlayWrapper className={classes.overlay} style={{ zIndex: 399 }}>
      <div className='seleniumDashboard'>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() =>
            //niet dashboard_streamlit:
            //no geom_id in url is use preselect filters from currentMap.properties.General[0].DashboardURL
            //geom_id in url is opened from top100 dashboard link with preselect filters is 1) remove preselect filters from currentMap.properties.General[0].DashboardURL and 2) use preselect filters from url
            window.open(
              (dashboard_streamlit === 1) ? (currentMap?.properties.General[0].DashboardURL + `&token=` + user.token) :
                ((getIdFromUrl(url) == null) ?
                  currentMap?.properties.General[0].DashboardURL + `,%22345%22:{%22client%22:[%22` + clientname + `%22]}}&token=` + user.token :
                  removePreselectFilters(currentMap?.properties.General[0].DashboardURL) + `${url ? url.replace("?", "&") : ""}${"&preselect_filters=" + getPreselectFilters(url) + `&token=` + user.token}`)
              ,
              (dashboard_streamlit === 1) ? "_blank" : "_self"
            )
          }
        >
          {t("Open dashboard")}
        </Button>
      </div>
    </OverlayWrapper >
  );
};

Dashboard.propTypes = {
  state: PropTypes.shape({
    metadataPanel: PropTypes.shape({
      open: PropTypes.bool.isRequired,
    }),
    isUtrecht: PropTypes.bool.isRequired,
    selectedMapKey: PropTypes.string,
  }).isRequired,
};

export default Dashboard;
