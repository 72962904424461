import _ from "lodash";

export const computeRiskScore = (metadata, model, activeRisks, activeIndicators) => {
  // console.log(activeIndicators)
  if (_.isEmpty(metadata) || !metadata.wegtype || _.isEmpty(model["RiskWeights"][metadata.wegtype])) return {};

  const riskTotalObject = { scoreTotal: 0, risks: [] };

  const activeRisksSum = Object.keys(model["RiskWeights"][metadata.wegtype])
    .map((key) =>
      activeRisks.includes(key)
        ? model["RiskWeights"][metadata.wegtype][key]
        : 0
    )
    .reduce((acc, curr) => acc + curr, 0);
  const riskWeights = model["RiskWeights"][metadata.wegtype];

  if (
    _.isEmpty(riskWeights) ||
    Object.values(riskWeights).every((score) => score === 0)
  )
    return {};

  model["RiskProfile"].forEach((risk) => {
    if (riskWeights[risk["Risico_naam"]] === 0) {
      return;
    }

    const riskObject = {
      name: risk.Risico_beschrijving,
      scoreTotal: 0,
      weightTotal: 0,
      indicators: [],
    };

    // calc sum_indicator_weight of selected indicators of risk
    risk.Indicatoren.forEach((indicator) => {
      // only add to riskObject if indicator is selected
      if (activeIndicators.includes(indicator["Indicator_naam"])) {
        riskObject["weightTotal"] += indicator.Indicator_weging
      }
    })

    risk.Indicatoren.forEach((indicator) => {
      const indicatorObject = {
        name: indicator.Indicator_beschrijving,
        scoreTotal: 0,
        variables: [],
        weight: (1 / riskObject["weightTotal"]) * indicator.Indicator_weging //indicator.Indicator_weging,
      };

      indicator.Variabelen.forEach((variable) => {
        indicatorObject.variables.push({
          name: variable.Variabele_beschrijving,
          value: metadata[variable.Variabele_naam],
          help: variable.Variabele_help,
          addition:
            variable.Variabele_schaling === 1
              ? metadata[`${variable.Variabele_naam}_label`]
              : null,
          italic: variable.Variabele_terinfo === 1,
        });
      });

      indicatorObject["variables"].sort((a, b) => (a.italic ? 1 : -1));

      indicatorObject["scoreTotal"] += metadata[indicator.Indicator_naam];

      if (indicator["Indicator_help"] !== null) {
        indicatorObject["help"] = indicator["Indicator_help"];
      }

      // only add to riskObject if indicator is selected
      if (activeIndicators.includes(indicator["Indicator_naam"])) {
        riskObject["scoreTotal"] +=
          (1 / riskObject["weightTotal"]) * indicator.Indicator_weging * metadata[indicator.Indicator_naam];
        riskObject["indicators"].push(indicatorObject);
      }
    });

    riskObject["indicators"].sort((a, b) => b.scoreTotal - a.scoreTotal);
    riskObject["weight"] =
      ((activeRisks.includes(risk.Risico_naam) ? 1 : 0) *
        riskWeights[risk["Risico_naam"]]) /
      activeRisksSum;

    riskTotalObject["risks"].push(riskObject);
    riskTotalObject["scoreTotal"] +=
      ((activeRisks.includes(risk.Risico_naam) ? 1 : 0) *
        (riskObject["scoreTotal"] * riskWeights[risk["Risico_naam"]])) /
      activeRisksSum;
  });

  riskTotalObject["risks"].sort(
    (a, b) => b.scoreTotal * b.weight - a.scoreTotal * a.weight
  );

  // console.log(riskTotalObject);

  return riskTotalObject;
};
