import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from 'react-i18next';

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    minWidth: 0,
    textTransform: "none",
    fontSize: "0.9rem",
  },
}));

const CustomTabs = ({ value, handleChange, isUtrecht }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant="fullWidth"
      >
        <Tab
          className={classes.tab}
          value="one"
          label={isUtrecht ? t("Risicoprofiel") : t("Filters")}
          wrapped
          {...a11yProps("one")}
        />
        <Tab
          className={classes.tab}
          value="two"
          label={isUtrecht ? "Data" : "Top 10"}
          {...a11yProps("two")}
        />
      </Tabs>
    </div>
  );
};

CustomTabs.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isUtrecht: PropTypes.bool.isRequired,
};

export default CustomTabs;
