import React, { useState, useMemo, memo, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Collapse,
  Drawer,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  ChevronRight,
  ChevronLeft,
  Help,
} from "@material-ui/icons";
import { useTranslation } from 'react-i18next';

import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";

import FullScreenDialogDetails from "../../FullScreenDialogDetails";
import { computeRiskScore } from "../utils";
import { useAuth, useCurrentMap } from "../../../../hooks";
import useInstanceData from "../../../../hooks/useInstanceData";
import RiskLine from "./RiskLine";
import RiskMeter from "./RiskMeter";
import { getMessagesById } from "../../useMapViewerLogic/apiCalls";
import MessageDialog from "../GeometryMetadataDrawer/MessageDialog";
import AccidentsDialog from "../GeometryMetadataDrawer/AccidentsDialog";
import V85Dialog from "../GeometryMetadataDrawer/V85Dialog";

export const drawerWidth = 380;

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    zIndex: "200 !important",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowX: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    justifyContent: "flex-start",
    minHeight: "48px !important",
    position: "sticky",
    top: 0,
    backgroundColor: "white",
  },
  divider: {
    position: "sticky",
    top: "48px",
  },
  drawerContent: {
    padding: "10px",
  },
  title: {
    fontWeight: 600,
  },
  section: {
    marginBottom: "15px",
  },
  detailsButton: {
    textTransform: "none",
    color: theme.palette.primary.main,
  },
  messageButton: {
    textTransform: "none",
    marginTop: "6px",
  },
  unorderedList: {
    marginTop: 0,
    maxWidth: "100%",
    overflowWrap: "break-word",
  },
  showTop25Title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "fit-content",
    cursor: "pointer",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  keyboardDown: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform"),
    "&$expanded": {
      transform: "rotate(180deg)",
    },
  },
  arrow: {
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform"),
    "&$expanded": {
      transform: "rotate(0deg)",
    },
  },
  titleContainer: {
    cursor: "pointer",
    width: "100%",
    padding: "5px",
    paddingLeft: 0,
    marginLeft: "-10px",
    boxSizing: "border-box",
    borderRadius: "4px",
    display: "grid",
    gridTemplateColumns: "21px 1fr 40px",
    gap: "1px",
    alignItems: "center",
    marginTop: "2px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tooltip: {
    fontSize: "0.85rem",
  },
  expanded: {},
}));

export default function GeometryMetadataDrawer({ state, dispatch }) {
  const classes = useStyles();
  const theme = useTheme();
  const { currentMap } = useCurrentMap();
  const { user } = useAuth();
  const { t } = useTranslation()
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [showV85, setShowV85] = useState(false);
  const [showOngevallen, setShowOngevallen] = useState(false);
  const [showMeldingen, setShowMeldingen] = useState(false);
  const [message, setMessage] = useState(null);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [openV85Dialog, setOpenV85Dialog] = useState(false);
  const [openAccidentsDialog, setOpenAccidentsDialog] = useState(false);

  const instances = useInstanceData()?.Instances;
  const clientname = instances && Object.keys(instances)
    .filter(i => instances[i].mapID === currentMap?.id)
    .reduce((obj, key) => {
      //obj[key] = instances[key];
      return instances[key];
    }, {}).clientname;

  const [cycloApiUser, setCycloApiUser] = useState('');

  useEffect(() => {
    instances &&
      Object.keys(instances).forEach(function (prop) {
        // `prop` is the property name
        // `data[prop]` is the property value
        let c = instances[prop]?.mapID === currentMap?.id && prop.replace("vm_", "").replace("_prod", "").replace("_dev", "");
        c.length >= 1 && setCycloApiUser(c);
      });
  }, [instances, currentMap]);
  //console.log(cycloApiUser.toUpperCase() + "_");
  const streetViewer = (state.isUtrecht && process.env['REACT_APP_' + cycloApiUser.toUpperCase() + '_CYCLOMEDIA_API_KEY']) ? "cyclo" : "google"

  const handleDrawerClose = () => {
    // dispatch({ type: "CLOSE_GEOMETRY_METADATA" });
    dispatch({ type: "CLOSE_METADATA_PANEL" });
  };

  const metadata = state.metadataPanel.metadata;

  const coords = state.metadataPanel.properties?.coords
    ?.flat()
    ?.slice(0, 2)
    ?.reverse();

  const layerId = useMemo(
    () =>
      currentMap.geometryLayers.find(
        (layer) => layer.name === state.metadataPanel.properties.layerName
      )?.id,
    [currentMap.geometryLayers, state.metadataPanel.properties.layerName]
  );

  const getMessages = useCallback(
    async (mapId, layerId, geometryId) => {
      const messages = await getMessagesById({
        mapId,
        layerId,
        token: user.token,
        geometryIds: [geometryId],
      });
      setMessage(messages.result[0]);
    },
    [user.token]
  );

  useEffect(() => {
    const geometryId = metadata.id;
    if (currentMap.id && layerId && geometryId) {
      getMessages(currentMap.id, layerId, geometryId);
    }
  }, [
    currentMap.geometryLayers,
    currentMap.id,
    getMessages,
    metadata.id,
    state.metadataPanel.properties.layerName,
    layerId,
  ]);

  const riskTotalObject = useMemo(
    () =>
      computeRiskScore(
        metadata,
        currentMap.properties.Whitebox,
        state.utrechtActiveRisks,
        state.utrechtActiveIndicators
      ),
    [metadata, currentMap.properties.Whitebox, state.utrechtActiveRisks, state.utrechtActiveIndicators]
  );
  // console.log('currentMap.properties.Whitebox')
  // console.log(currentMap.properties.Whitebox)
  // console.log('state.utrechtActiveRisks')
  // console.log(state.utrechtActiveRisks)
  // console.log('state.utrechtActiveIndicators')
  // console.log(state.utrechtActiveIndicators)

  const allowedRiskNames = useMemo(() => {
    return currentMap.properties.Whitebox.RiskProfile.flatMap((risk) =>
      state.utrechtActiveRisks.includes(risk.Risico_naam)
        ? [risk.Risico_beschrijving]
        : []
    );
  }, [currentMap.properties.Whitebox.RiskProfile, state.utrechtActiveRisks]);

  // console.log("metadata")
  // console.log(metadata)
  // console.log("coords")
  // console.log(coords)
  // console.log("riskTotalObject")
  // console.log(riskTotalObject)
  // console.log("allowedRiskNames")
  // console.log(allowedRiskNames)

  return (
    <>
      <Drawer
        className={classes.drawer}
        transitionDuration={theme.transitions.duration.shortest}
        variant="persistent"
        anchor="right"
        open={state.metadataPanel.open}
        classes={{
          paper: classes.drawerPaper,
          root: classes.drawerRoot,
        }}
        BackdropProps={{ style: { visibility: "none" } }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider className={classes.divider} />
        {_.isEmpty(metadata) ? (
          <CircularProgress style={{ margin: " 20px auto" }} />
        ) : (
          <div className={classes.drawerContent}>
            {(streetViewer === "cyclo") ? (
              <CycloMediaImage
                coords={coords}
                setOpenFullScreenDialog={setOpenFullScreenDialog}
              />
            ) : (
              <GoogleImage
                coords={coords}
                setOpenFullScreenDialog={setOpenFullScreenDialog}
              />
            )}
            <section className={classes.section}>
              <Typography className={classes.title}>
                {metadata.straatnaam}
              </Typography>
              <Typography>{t("Plaats")}: {metadata.plaats}</Typography>
              <Button
                onClick={() => setOpenFullScreenDialog(true)}
                className={classes.detailsButton}
              >
                {t("Detailweergave")}
              </Button>
            </section>

            <section className={classes.section}>
              <Typography className={classes.title}>
                {t("Verkeersintensiteit")}
              </Typography>
              <Typography>{metadata.intensiteit}</Typography>
              <Typography>{metadata.intensiteit_fiets}</Typography>
            </section>

            <section
              className={classes.section}
              style={{ marginBottom: "25px" }}
            >
              <Typography className={classes.title}>{t("Wegtype")}</Typography>
              <Typography>{metadata.wegtype_label}</Typography>
            </section>

            <section className={classes.section}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  className={classes.title}
                  style={{ width: "fit-content" }}
                >
                  {t("Risicoscore")}:
                </Typography>
                <RiskMeter value={Number(riskTotalObject.scoreTotal?.toFixed(3))} />
              </div>
              {riskTotalObject.risks
                ?.filter((risk) => allowedRiskNames.includes(risk.name))
                .map((risk) => (
                  <RiskLine risk={risk} locationKey={metadata.key} clientName={clientname} key={risk.name} mapId={currentMap?.id} />
                ))}
            </section>
            <section className={classes.section}>
              <div
                className={classes.titleContainer}
                onClick={() => setShowV85((prev) => !prev)}
              >
                <KeyboardArrowDown
                  className={clsx(
                    showV85 && classes.expanded,
                    classes.arrow
                  )}
                />
                <Typography className={classes.title}>{t("Snelheid")}</Typography>
                <Tooltip
                  interactive
                  arrow
                  title={t("V85 help")}
                  classes={{ tooltip: classes.tooltip }}
                  style={{ 'paddingLeft': '0.5rem' }}
                >
                  <Help color="primary" className={classes.risicoHelp} />
                </Tooltip>
              </div>
              <Collapse in={showV85}>
                {/* <Typography style={{ marginBottom: '1rem' }}>
                  {t("V85 werkelijk gereden snelheid")}: {metadata.werkelijk_gereden_snelheid}
                </Typography> */}
                <div style={{ 'paddingLeft': '0px', 'paddingTop': '3px', 'paddingBottom': '8px' }}>
                  <div className={classes.indicatorNameContainer}>
                    <Button
                      style={{ marginTop: '-8px', marginRight: '5px' }}
                      className={classes.messageButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenV85Dialog(true)}
                    >
                      {t("Bekijk V85")}
                    </Button>
                    <Tooltip
                      interactive
                      arrow
                      title={t("V85 additional help")}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Help color="primary" className={classes.risicoHelp} />
                    </Tooltip>
                  </div>
                </div>
              </Collapse>
              <V85Dialog
                open={openV85Dialog}
                locationKey={metadata.key} //'189269023_2020_wegvak'
                straat={metadata.straatnaam}
                plaats={metadata.plaats}
                clientName={clientname}
                handleClose={() => setOpenV85Dialog(false)}
              />

              <div
                className={classes.titleContainer}
                onClick={() => setShowOngevallen((prev) => !prev)}
              >
                <KeyboardArrowDown
                  className={clsx(
                    showOngevallen && classes.expanded,
                    classes.arrow
                  )}
                />
                <Typography className={classes.title}>{t("Ongevallen")}</Typography>
                <Tooltip
                  interactive
                  arrow
                  title={t("Ongevallen help")}
                  classes={{ tooltip: classes.tooltip }}
                  style={{ 'paddingLeft': '0.5rem' }}
                >
                  <Help color="primary" className={classes.risicoHelp} />
                </Tooltip>
              </div>
              <Collapse in={showOngevallen}>
                <Typography>
                  {t("Ongevallen 2014-2020")}: {metadata.aantal_ongevallen_alle_jaren}
                </Typography>
                <Typography>{t("Afloop")}:</Typography>
                <ul className={classes.unorderedList}>
                  <li>
                    <Typography>
                      {t("Aantal verkeersgewonden")}:{" "}
                      {metadata.aantal_ongevallen_ernst__let}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {t("Aantal verkeersdoden")}:{" "}
                      {metadata.aantal_ongevallen_ernst__dod}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {t("Aantal uitsluitend materiele schade")}:{" "}
                      {metadata.aantal_ongevallen_ernst__ums}
                    </Typography>
                  </li>
                </ul>
                <div style={{ 'paddingLeft': '0px', 'paddingTop': '3px', 'paddingBottom': '8px' }}>
                  <div className={classes.indicatorNameContainer}>
                    <Button
                      style={{ marginTop: '-8px', marginRight: '5px' }}
                      className={classes.messageButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenAccidentsDialog(true)}
                    >
                      {t("Bekijk ongevallen")}
                    </Button>
                    <Tooltip
                      interactive
                      arrow
                      title={t("Ongevallen additional help")}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Help color="primary" className={classes.risicoHelp} />
                    </Tooltip>
                  </div>
                </div>
              </Collapse>
              <AccidentsDialog
                open={openAccidentsDialog}
                locationKey={metadata.key} //'189269023_2020_wegvak'
                straat={metadata.straatnaam}
                plaats={metadata.plaats}
                handleClose={() => setOpenAccidentsDialog(false)}
              />

              <div
                className={classes.titleContainer}
                onClick={() => setShowMeldingen((prev) => !prev)}
              >
                <KeyboardArrowDown
                  className={clsx(
                    showMeldingen && classes.expanded,
                    classes.arrow
                  )}
                />
                <Typography className={classes.title}>
                  {t("Subjectieve meldingen")}
                </Typography>
                <Tooltip
                  interactive
                  arrow
                  title={t("Meldingen help")}
                  classes={{ tooltip: classes.tooltip }}
                  style={{ 'paddingLeft': '0.5rem' }}
                >
                  <Help color="primary" className={classes.risicoHelp} />
                </Tooltip>
              </div>
              <Collapse in={showMeldingen}>
                <Typography>
                  {t("Aantal meldingen")}: {metadata.aantal_subjectieve_meldingen}
                </Typography>
                {Number(metadata.aantal_subjectieve_meldingen) > 0 &&
                  message?.text ? (
                  <Button
                    className={classes.messageButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenMessageDialog(true)}
                  >
                    {t("Bekijk meldingen")}
                  </Button>
                ) : null}
              </Collapse>
            </section>
          </div>
        )}
      </Drawer>
      <FullScreenDialogDetails
        open={openFullScreenDialog}
        setOpen={setOpenFullScreenDialog}
        metadata={metadata}
        coords={coords}
        isUtrecht={state.isUtrecht}
        message={message}
        layerId={layerId}
        getMessages={getMessages}
        streetViewer={streetViewer}
      />
      {
        message?.text ? (
          <MessageDialog
            open={openMessageDialog}
            content={JSON.parse(message.text)?.Message}
            handleClose={() => setOpenMessageDialog(false)}
          />
        ) : null
      }
    </>
  );
}

const GoogleImage = memo(({ coords = [], setOpenFullScreenDialog }) => {
  return (
    <img
      style={{ width: "347", height: "186px", cursor: "pointer" }}
      src={`https://maps.googleapis.com/maps/api/streetview?size=347x186&location=${coords[0]},${coords[1]}
&fov=80&heading=70&pitch=0
&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
      alt="Google Street view"
      onClick={() => setOpenFullScreenDialog(true)}
    />
  );
});

GoogleImage.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number),
  setOpenFullScreenDialog: PropTypes.func.isRequired,
};

GoogleImage.displayName = "GoogleImage";

const CycloMediaImage = memo(({ coords = [], setOpenFullScreenDialog }) => {
  const [image, setImage] = useState(null);

  const { currentMap } = useCurrentMap();
  const instances = useInstanceData()?.Instances;
  const [cycloApiUser, setCycloApiUser] = useState('');

  useEffect(() => {
    instances &&
      Object.keys(instances).forEach(function (prop) {
        // `prop` is the property name
        // `data[prop]` is the property value
        let c = instances[prop]?.mapID === currentMap?.id && prop.replace("vm_", "").replace("_prod", "").replace("_dev", "");
        c.length >= 1 && setCycloApiUser(c);
      });
  }, [instances, currentMap]);
  //console.log(cycloApiUser.toUpperCase() + "_");

  useEffect(() => {
    const fetchImage = async () => {
      // const username = process.env.REACT_APP_UTRECHT_CYCLOMEDIA_USERNAME;
      // const password = process.env.REACT_APP_UTRECHT_CYCLOMEDIA_PW;
      const username = process.env['REACT_APP_' + cycloApiUser.toUpperCase() + '_CYCLOMEDIA_USERNAME'];
      const password = process.env['REACT_APP_' + cycloApiUser.toUpperCase() + '_CYCLOMEDIA_PW'];

      const token = Buffer.from(`${username}:${password}`, "utf8").toString(
        "base64"
      );

      const url = `https://atlas.cyclomedia.com/PanoramaRendering/RenderByLocation2D/4326/${coords[1]}/${coords[0]}/?width=347&height=186&hfov=80&apiKey=${process.env['REACT_APP_' + cycloApiUser.toUpperCase() + '_CYCLOMEDIA_API_KEY']}`;

      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "image/jpeg",
            Authorization: `Basic ${token}`,
          },
        });

        const img = URL.createObjectURL(await res.blob());

        setImage(img);
      } catch (error) { }
    };

    fetchImage();
  }, [coords, cycloApiUser]);

  return image ? (
    <img
      style={{ width: "347", height: "186px", cursor: "pointer" }}
      src={image}
      alt="Cyclomedia view"
      onClick={() => setOpenFullScreenDialog(true)}
    />
  ) : (
    <div
      style={{
        width: "347px",
        height: "186px",
        backgroundColor: "lightGrey",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
});

CycloMediaImage.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired,
  setOpenFullScreenDialog: PropTypes.func.isRequired,
};

CycloMediaImage.displayName = "CycloMediaImage";

GeometryMetadataDrawer.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
