import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";
import Login from "./components/Login";
import CacheBuster from "./CacheBuster";
import MapViewer from "./components/MapViewer";
import { ProvideAuth, useAuth } from "./hooks";
import { ProvideCurrentMap } from "./hooks";
import theme from "./theme";
import "./i18n";

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <ThemeProvider theme={theme}>
            <ProvideAuth>
              <ProvideCurrentMap>
                <Router>
                  <Switch>
                    <PublicRoute exact path="/login">
                      <Login />
                    </PublicRoute>
                    <PrivateRoute exact path="/map">
                      <MapViewer />
                    </PrivateRoute>
                    <Route path="*">
                      <Redirect to="/login" />
                    </Route>
                  </Switch>
                </Router>
              </ProvideCurrentMap>
            </ProvideAuth>
          </ThemeProvider>
        );
      }}
    </CacheBuster>
  );
}

// A wrapper for <Route> that redirects to /login page
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

// A wrapper for <Route> that redirects to  /map
// screen if you're authenticated.
function PublicRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/map",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
