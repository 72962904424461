// const baseZoom = 15;
// 
// const widthExpression = (baseWidth) => [
//   "interpolate",
//   ["exponential", 1.9],
//   ["zoom"],
//   0,
//   baseWidth * Math.pow(2, 12 - baseZoom),
//   24,
//   baseWidth * Math.pow(2, 24 - baseZoom),
// ];
// 
// export const addCircleLayer = (
//   map,
//   dispatch,
//   source,
//   hoveredStateId,
//   popup,
//   id,
//   circleRadius = 7,
//   circleColor = ["slice", ["get", "color"], 0, 7],
//   circleOpacity = [
//     "case",
//     [
//       "any",
//       ["boolean", ["feature-state", "highlight"], false],
//       ["boolean", ["feature-state", "hover"], false],
//     ],
//     1,
//     0.5,
//   ]
// ) => {
//   map.addLayer({
//     id,
//     type: "circle",
//     source: source,
//     paint: {
//       "circle-radius": widthExpression(circleRadius),
//       // get the color from the Feature property and take out the opacity at the end of the string
//       "circle-color": circleColor,
//       "circle-opacity": circleOpacity,
//     },
//     filter: ["==", "$type", "Point"],
//   });

//   addCursorPointer(map, id, popup, dispatch);
//   addHoverState(map, id, source, hoveredStateId);
//   addOnClick(map, id, dispatch);
// };

// export const addLineLayer = (
//   map,
//   dispatch,
//   source,
//   hoveredStateId,
//   popup,
//   id,
//   lineWidth = 6,
//   lineColor = ["slice", ["get", "color"], 0, 7],
//   lineOpacity = [
//     "case",
//     [
//       "any",
//       ["boolean", ["feature-state", "highlight"], false],
//       ["boolean", ["feature-state", "hover"], false],
//     ],
//     1,
//     0.5,
//   ]
// ) => {
// 
//   map.addLayer({
//     id,
//     type: "line",
//     source: source,
//     layout: {
//       "line-join": "round",
//       "line-cap": "round",
//     },
//     paint: {
//       "line-width": widthExpression(lineWidth),
//       // get the color from the Feature property and take out the opacity at the end of the string
//       "line-color": lineColor,
//       // "line-color": ["slice", ["get", "color"], 0, 7],
//       "line-opacity": lineOpacity,
//     },
//     filter: ["==", "$type", "LineString"],
//   });

//   addCursorPointer(map, id, popup, dispatch);
//   addHoverState(map, id, source, hoveredStateId);
//   addOnClick(map, id, dispatch);
// };

export const addAdditionalLayer = (
  map,
  dispatch,
  source,
  hoveredStateId,
  popup,
  isUtrecht,
  mapType,
  geomStyles
) => {
  if (mapType === 'vector') {
    let color = ["slice", ["get", "color"], 0, 7];
    let opacity = [
      "case",
      [
        "any",
        ["boolean", ["feature-state", "highlight"], false],
        ["boolean", ["feature-state", "hover"], false],
      ],
      1,
      0.5,
    ];

    let lineWidth = []
    let circleRadius = []
    if (geomStyles[0].name === 'risico_totaal') {
      lineWidth = [
        "case",
        [
          "any",
          ["boolean", ["feature-state", "highlight"], false],
          ["boolean", ["feature-state", "hover"], false],
        ],
        10,
        //top 10
        ["==", ["length", ["feature-state", "dataTop10"]], 2],
        10,
        //hide
        ["==", ["length", ["feature-state", "dataColor"]], 4],
        0,
        5
      ]
      circleRadius = [
        "case",
        [
          "any",
          ["boolean", ["feature-state", "highlight"], false],
          ["boolean", ["feature-state", "hover"], false],
        ],
        (geomStyles[0].radius * 2) + 2,
        //top 10
        ["==", ["length", ["feature-state", "dataTop10"]], 2],
        (geomStyles[0].radius * 2) + 2,
        //hide
        ["==", ["length", ["feature-state", "dataColor"]], 4],
        0,
        (geomStyles[0].radius * 2)
      ]
    } else {
      lineWidth = [
        "case",
        [
          "any",
          ["boolean", ["feature-state", "highlight"], false],
          ["boolean", ["feature-state", "hover"], false],
        ],
        geomStyles[0].width + 2,
        geomStyles[0].width
      ]
      circleRadius = [
        "case",
        [
          "any",
          ["boolean", ["feature-state", "highlight"], false],
          ["boolean", ["feature-state", "hover"], false],
        ],
        geomStyles[0].radius + 2,
        geomStyles[0].radius
      ]
    }

    if (isUtrecht) {
      // color is white when styling is still loading
      color = [
        "coalesce",
        // ["==", ["length", ["feature-state", "dataColor"]], 7],
        ["feature-state", "dataColor"],
        "#ffffff",
      ];

      opacity = [
        "case",
        //highlight (no hover url id get param?)
        [
          "all",
          ["==", ["length", ["feature-state", "dataColor"]], 7],
          [
            "any",
            ["boolean", ["feature-state", "highlight"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
        ],
        1,
        // if risk color is set opacity is .05
        ["==", ["length", ["feature-state", "dataColor"]], 7],
        0.5,
        // default opacity is 0
        0,
      ];
    }

    // lines
    map.addLayer({
      id: `${source}-line`,
      type: "line",
      source: source,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-width": lineWidth,
        // get the color from the Feature property and take out the opacity at the end of the string
        "line-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "highlight"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          '#0d6efd',
          color,
        ],
        // "line-color": color,
        "line-opacity": opacity,
      },
      filter: ["==", "$type", "LineString"],
    });

    // polys
    //eerst tweede laag type line voor poly's met dikkere lijnen voor top 10 (webgl ding poly's border width moet via losse line layer)
    map.addLayer({
      id: `${source}-poly-border`,
      type: "line",
      source: source, // reference the data source
      layout: {},
      paint: {
        "line-width": lineWidth,
        // get the color from the Feature property and take out the opacity at the end of the string
        "line-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "highlight"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          '#0d6efd',
          color,
        ],
        // "line-color": color,
        "line-opacity": opacity,
      },
      filter: ["==", "$type", "Polygon"],
    });
    map.addLayer({
      id: `${source}-poly`,
      type: "fill",
      source: source, // reference the data source
      layout: {},
      paint: {
        "fill-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "highlight"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          '#0d6efd',
          color,
        ],
        "fill-opacity": opacity,
      },
      filter: ["==", "$type", "Polygon"],
    });

    // circles
    map.addLayer({
      id: `${source}-circle`,
      type: "circle",
      source: source,
      paint: {
        "circle-radius": circleRadius,
        // get the color from the Feature property and take out the opacity at the end of the string 
        "circle-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "highlight"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          '#0d6efd',
          color,
        ],
        "circle-opacity": opacity,
      },
      filter: ["==", "$type", "Point"],
    });

    addCursorPointer(map, `${source}-line`, popup, dispatch);
    addCursorPointer(map, `${source}-poly`, popup, dispatch);
    addCursorPointer(map, `${source}-circle`, popup, dispatch);
    addHoverState(map, `${source}-line`, source, hoveredStateId);
    addHoverState(map, `${source}-poly`, source, hoveredStateId);
    addHoverState(map, `${source}-circle`, source, hoveredStateId);

    if (isUtrecht) {
      addOnClick(map, `${source}-line`, dispatch);
      addOnClick(map, `${source}-poly`, dispatch);
      addOnClick(map, `${source}-circle`, dispatch);
    }
  } else if (mapType === 'raster') {
    // Add a new layer to visualize the raster.
    map.addLayer(
      {
        'id': `${source}-raster`,
        'type': 'raster',
        'source': source,
        'paint': {}
      }
    );
  } else { //mapType === 'external'
    // Add a new layer to visualize the external (wfs) layer.
    map.addLayer(
      {
        'id': `${source}-external`,
        'type': 'raster',
        'source': source,
        'paint': {}
      }
    );
  }
};

const addCursorPointer = (map, layerId, popup, dispatch) => {
  // Change the cursor to a pointer when the mouse is over the layerId layer.
  map.on("mouseenter", layerId, function (e) {
    map.getCanvas().style.cursor = "pointer";

    // var geometryCoords = e.features[0].geometry.coordinates.slice();
    var properties = e.features[0].properties;

    dispatch &&
      dispatch({
        type: "OPEN_POPUP",
        payload: { ...properties, layerName: e.features[0].source },
      });

    popup.setLngLat(e.lngLat).setHTML("<p>loading...</p>").addTo(map);
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", layerId, function () {
    map.getCanvas().style.cursor = "";
    dispatch &&
      dispatch({
        type: "CLOSE_POPUP",
      });
    popup.remove();
  });
};

const addHoverState = (map, layerId, sourceId, hoveredStateId) => {
  // When the user moves their mouse over the state-fill layer, we'll update the
  // feature state for the feature under the mouse.
  map.on("mousemove", layerId, function (e) {
    if (e.features.length > 0) {
      if (hoveredStateId) {
        map.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = e.features[0].id;
      map.setFeatureState(
        { source: sourceId, id: hoveredStateId },
        { hover: true }
      );
    }
  });

  // When the mouse leaves the state-fill layer, update the feature state of the
  // previously hovered feature.
  map.on("mouseleave", layerId, function () {
    if (hoveredStateId) {
      map.setFeatureState(
        { source: sourceId, id: hoveredStateId },
        { hover: false }
      );
    }
    hoveredStateId = null;
  });
};

const addOnClick = (map, layerId, dispatch) => {
  // When a click event occurs on a feature in the layerId layer
  map.on("click", layerId, function (e) {
    // Ok so there's a little thing to be aware of here. For now I'm taking coords,
    // which is where the user clicked on the map. BUT when we are too zoomed out
    // that could be too inaccurate, maybe it should be better to take geometryCoords
    // which is the coords of the actual geometry.
    // The best of the best would probably be to project coords into the line of the road
    // for roads, and just take the circle for intersections.
    // This is only relevant if I'm using this for something that requires high precision,
    // like for example google street view, which I probably will

    var geometryCoords = e.features[0].geometry.coordinates.slice();
    var coords = e.lngLat;
    var properties = e.features[0].properties;

    dispatch &&
      dispatch({
        type: "OPEN_METADATA_PANEL",
        payload: {
          ...properties,
          layerName: e.features[0].source,
          coords: (geometryCoords.length > 2) ? findClosestPoint(geometryCoords, [coords.lng, coords.lat]) : [coords.lng, coords.lat],
        },
      });
  });
};

const distanceBetweenPoints = (point1, point2) => {
  return Math.sqrt((point2[0] - point1[0]) ** 2 + (point2[1] - point1[1]) ** 2);
};

const findClosestPoint = (points, point) => {
  return points.reduce(
    (acc, curr) =>
      distanceBetweenPoints(acc, point) > distanceBetweenPoints(curr, point)
        ? curr
        : acc,
    points[0]
  );
};
