import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBV4FGs8DIyQFg1KRlqhrjKUsJBNxdqiRw",
    authDomain: "roadsafety-dd8e9.firebaseapp.com",
    projectId: "roadsafety-dd8e9",
    storageBucket: "roadsafety-dd8e9.appspot.com",
    messagingSenderId: "348363878202",
    appId: "1:348363878202:web:3482d95317fd492d616a03",
    measurementId: "G-77LD64MJ3Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(typeof analytics);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
