import { useLocation } from "react-router-dom";

const DELTA_BOUNDS = 0.001;

// used to get a slightly larger bounding box and get all geometries
const makeBoundsABitBigger = (bounds, delta = DELTA_BOUNDS) => {
  if (bounds[0]?.length !== 2 && bounds[1]?.length !== 2) return bounds;
  // latitude is multiplied by two to get a square(ish) instead of rectangle as you would otherwise
  return [
    [bounds[0][0] - delta * 2, bounds[0][1] - delta],
    [bounds[1][0] + delta * 2, bounds[1][1] + delta],
  ];
};

const useUrlLocationSearch = () => {
  const location = useLocation();
  return location?.search || null;
};

const getCoordsFromUrl = (url) => {
  const id = "geom_centroid";
  const query = new URLSearchParams(url);
  return query.has(id)
    ? query
      .get(id)
      .replace("POINT(", "")
      .replace(")", "")
      .split(" ")
      .map((c) => +c)
    : null;
};
const getIdFromUrl = (url) => {
  const id = "geom_id";
  const query = new URLSearchParams(url);
  return query.has(id) ? query.get(id) : null;
};

const removePreselectFilters = (url) => {
  //const id = "preselect_filters";
  //const query = new URLSearchParams(url);
  const parameter = "preselect_filters";
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);
    for (var i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    var res = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    return res;
  }
};

const getPreselectFilters = (url) => {
  const id = "preselect_filters";
  const query = new URLSearchParams(url);
  return query.has(id) ? query.get(id) : null;
};

const sendGtagUserData = (user) => {
  if (process.env.REACT_APP_NODE_ENV === 'production' && user.username && !window.location.href.includes("-uat")) {
    window.gtag('js', new Date());
    window.gtag("config", process.env.REACT_APP_GTAG_ID, { 'anonymize_ip': true });
    window.gtag("event", "pageOpen", { "username": user.username })
  }
}

const getMapPrefix = (id, instances) => {
  return instances && Object.keys(instances).filter(i => instances[i].mapID === id)[0]
}


export {
  makeBoundsABitBigger,
  useUrlLocationSearch,
  getIdFromUrl,
  removePreselectFilters,
  getCoordsFromUrl,
  sendGtagUserData,
  getMapPrefix,
  getPreselectFilters
};
