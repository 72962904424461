import React from "react";

import { makeStyles, Button, Slide } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const usePanelLayoutStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    boxSizing: "border-box",
    position: "fixed",
    top: "0",
    left: "0",
    width: "329px",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
    backgroundColor: "#fafafa",
    paddingTop: "80px",
    zIndex: "450",
  },
  closeButton: {
    position: "fixed",
    bottom: "10px",
    width: "88px",
    height: "34px",
    borderRadius: "17px",
    left: "226px",
    textTransform: "none",
    transition: theme.transitions.create("transform"),
    "&:hover": {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      transform: "translateY(-4px)",
    },
    [theme.breakpoints.down("xs")]: {
      left: "calc((100vw - 88px) / 2)",
    },
  },
  expandIcon: {
    transform: "rotate(180deg)",
  },
}));

const PanelLayout = ({ expanded, setExpanded, children }) => {
  const { t } = useTranslation()
  const classes = usePanelLayoutStyles();
  return (
    <Slide in={expanded} direction={"down"}>
      <div className={classes.container}>
        {children}
        <Button
          variant="outlined"
          className={classes.closeButton}
          id='hideButtonLeftPanel'
          onClick={() => setExpanded(false)}
          startIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        >
          {t("Verberg")}
        </Button>
      </div>
    </Slide>
  );
};

PanelLayout.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PanelLayout;
